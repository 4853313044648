const sensorStatusRecord = {
  1: { en: "Open the door", vi: "Mở cửa" },
  2: { en: "Close the door", vi: "Đóng cửa" },
  3: {
    en: "Enter the door sensor alarm detection status",
    vi: "Vào trạng thái phát hiện cảnh báo cảm biển cửa",
  },
  4: {
    en: "Exit door sensor alarm detection status",
    vi: "Thoát trạng thái phát hiện cảnh báo cảm biển cửa",
  },
  5: { en: "Door not close completely", vi: "Cửa không đóng kín" },
  6: { en: "Use the button to open the door", vi: "Mở cửa bằng nút bấm" },
  7: {
    en: "The door is locked when the button opens",
    vi: "Cửa đóng khi nút mở",
  },
  8: {
    en: "The controller has expired when the button opens the door",
    vi: "Bộ điều khiển hết hạn khi mở cửa bằng nút",
  },
};

export default sensorStatusRecord;
