import React from "react";

export default function useAnimationFrame(callback: (deltaTime: number) => void) {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestIdRef = React.useRef<number>();
  const previousTimeRef = React.useRef<number>();

  const animate: FrameRequestCallback = (time) => {
    if (previousTimeRef.current != undefined) {
      const deltaTime = time - previousTimeRef.current;
      callback(deltaTime);
    }
    previousTimeRef.current = time;
    requestIdRef.current = requestAnimationFrame(animate);
  };

  React.useEffect(() => {
    requestIdRef.current = requestAnimationFrame(animate);
    return () => {
      if (requestIdRef.current != null) {
        cancelAnimationFrame(requestIdRef.current);
      }
    };
  }, []); // Make sure the effect runs only once
}
