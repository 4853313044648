import {
  Button,
  Checkbox,
  Container,
  LoadingOverlay,
  Modal,
  Pagination,
  Popover,
  ScrollArea,
  Table,
} from "@mantine/core";
import { IconEdit, IconPlus, IconTrashXFilled } from "@tabler/icons-react";
import React, { Dispatch, memo, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "src/services/redux/ReduxStore";
import {
  nullToastUpdateOptions,
  toastCommonError,
  toastDeviceResponseReport,
} from "../../utils/ToastUtils";
import { useDisclosure, useListState, useSet } from "@mantine/hooks";
import { Navigate } from "react-router-dom";
import { inAppPaths } from "src/App";
import { delay } from "src/utils/Utils";
import AddManagedUser from "./AddManagedUser";
import managedUserService from "src/services/core/managed-user/ManagedUser.service";
import deviceService, {
  getDisplayPassword,
} from "src/services/core/device/Device.service";
import ManagedUser from "src/services/core/managed-user/models/ManagedUser";
import EditManagedUser from "./PatchManagedUser";
import { useTranslation } from "react-i18next";
import useIsMobile from "src/pages/utils/isMobileHook";
import useRefCallback, {
  PropertyWrapper,
} from "src/pages/utils/useRefCallbackHook";
import classNames from "classnames";
import Popup from "src/pages/components/Popup";
import DeviceDropDown from "src/pages/components/DeviceDropDown";

const pageSize = 15;
const collapsedClass =
  // "w-[0px] h-[0px] overflow-hidden text-[0px] !p-0 bg-gray-300";
  "hidden";

type ColumnKey =
  | keyof Omit<ManagedUser, "id" | "imageUrl" | "groupId">
  | "actions";
const colKeyMap: Record<
  ColumnKey,
  { translationKey: string; defaultCollapsed: boolean }
> = {
  deviceId: { translationKey: "deviceId", defaultCollapsed: false },
  internalId: { translationKey: "internalId", defaultCollapsed: true },
  name: { translationKey: "name", defaultCollapsed: false },
  isAdmin: { translationKey: "role", defaultCollapsed: false },
  password: { translationKey: "password", defaultCollapsed: true },
  cardNumber: { translationKey: "cardNumber", defaultCollapsed: true },
  actions: { translationKey: "actions", defaultCollapsed: false },
};

const ManagedUserList: () => JSX.Element = () => {
  const [isLoading, { close: turnOffLoading }] = useDisclosure(true);
  const managedUserList = useAppSelector(
    (state) => state.activeManagedUserList
  );
  const [atleastOneMarked, setAtLeastOneMarked] = useState(false);
  const markedManagedUserIds = useRefCallback<number[]>([], (_, v) => {
    if (v.length > 0) {
      setAtLeastOneMarked(true);
    } else {
      setAtLeastOneMarked(false);
    }
  });
  const [errorMessage, setErrorMessage] = useState<string>();
  const [addModalOpened, { open: openAddModal, close: closeAddModal }] =
    useDisclosure(false);
  const [editModalOpened, { open: openEditModal, close: closeEditModal }] =
    useDisclosure(false);
  const [
    deleteModalOpened,
    { open: openDeleteModal, close: closeDeleteModal },
  ] = useDisclosure(false);
  const [noSelectedGroup, { open: setNoSelectedGroupTrue }] =
    useDisclosure(false);
  const devices = useAppSelector((state) => state.deviceList);
  const [addEditSns, addEditSnsHandler] = useListState<string>([]);
  const [deleteSns, deleteSnsHandler] = useListState<string>([]);
  const [editedManagedUser, setEditedManagedUser] = useState<ManagedUser>(
    null!
  );

  const { t } = useTranslation(undefined, {
    keyPrefix: "inApp.managedUserList",
  });
  const [isMobile] = useIsMobile();
  const [page, setPage] = useState(1);
  const collapsedColumnsHandler = useSet<ColumnKey>(
    (Object.keys(colKeyMap) as ColumnKey[]).flatMap((k) =>
      colKeyMap[k].defaultCollapsed ? k : []
    )
  );

  useEffect(() => {
    (async () => {
      const delayPromise = delay(300);
      const userListPromise = managedUserService.loadManagedUserListInitial();
      const deviceListPromise = deviceService.loadDeviceListInitial();
      const result = await userListPromise;
      const deviceResult = await deviceListPromise;

      await delayPromise;
      if (!result.success) {
        if (result.code == "no-selected-groups") {
          setNoSelectedGroupTrue();
        } else {
          toastCommonError(result.code);
        }
      }

      if (!deviceResult.success) {
        if (deviceResult.code == "no-selected-groups") {
          setNoSelectedGroupTrue();
        } else {
          toastCommonError(deviceResult.code);
        }
      } else {
        const sns = deviceResult.value.map((d) => d.serialNumber);
        addEditSnsHandler.setState(sns);
        deleteSnsHandler.setState(sns);
      }

      turnOffLoading();
    })();
  }, []);

  const deleteManagedUsers = useCallback(async () => {
    const deletePromise = managedUserService.deleteManagedUsers(
      markedManagedUserIds.get(),
      deleteSns
    );
    const deleteToastId = toast.loading(t("deleting"), {
      closeButton: true,
      closeOnClick: true,
    });

    const result = await deletePromise;
    if (result.success) {
      toastDeviceResponseReport(devices ?? [], result.value);
      toast.update(deleteToastId, {
        ...nullToastUpdateOptions,
        render: t("deleteSuccess"),
        type: "success",
      });
      markedManagedUserIds.set([]);
    } else {
      toastCommonError(result.code, deleteToastId);
    }
    closeDeleteModal();
  }, [markedManagedUserIds, addEditSns]);

  // let no = 0;

  const onEditClick = useCallback((managedUser: ManagedUser) => {
    setEditedManagedUser(managedUser);
    openEditModal();
  }, []);

  const rows = managedUserList
    ?.slice((page - 1) * pageSize, page * pageSize)
    .map((managedUser) => (
      <Row
        key={managedUser.id}
        managedUser={managedUser}
        markedIds={markedManagedUserIds}
        onEditClick={onEditClick}
        collapsedColumnChecker={collapsedColumnsHandler.has.bind(
          collapsedColumnsHandler
        )}
      />
    ));

  const buttonClassName = "p-0 mr-4 mb-2";

  return noSelectedGroup ? (
    <Navigate to={inAppPaths.groups} />
  ) : (
    <ScrollArea className="w-full h-full">
      <Container size="md" my={40}>
        {errorMessage != null ? (
          errorMessage
        ) : isLoading ? (
          <div className="h-svh w-full">
            <LoadingOverlay
              className=""
              visible={true}
              zIndex={1000}
              // overlayProps={{ radius: "sm", blur: 2 }}
            />
          </div>
        ) : (
          <>
            <div className="my-5 flex flex-col md:flex-row items-start md:justify-between">
              <div className="flex flex-wrap">
                <Button
                  color="green"
                  leftSection={<IconPlus size={20} />}
                  className={buttonClassName}
                  onClick={openAddModal}
                >
                  {t("addManagedUser")}
                </Button>
                <Button
                  disabled={!atleastOneMarked}
                  color="red"
                  leftSection={<IconTrashXFilled size={20} />}
                  className={buttonClassName}
                  onClick={openDeleteModal}
                >
                  {t("deleteManagedUser")}
                </Button>
              </div>
              <div className="flex flex-col md:flex-row md:items-start">
                {/* <InputLabel className="md:mr-2 md:mt-2">
                  {t("defaultDevices")}
                </InputLabel> */}
                {/* <MultiSelect
                className="w-60"
                data={devices?.map((d) => ({
                  value: d.serialNumber,
                  label: getDisplayName(d),
                }))}
                styles={{
                  dropdown: { maxHeight: 400, overflowY: "auto" },
                }}
                comboboxProps={{
                  children: "hi",
                }}
                clearable
                value={defaultDevices}
                onChange={setDefaultDevices}
              /> */}
                {/* <DeviceDropDown
                  className="w-60"
                  selected={defaultSnList}
                  setSelected={setDefaultSnList}
                  deviceList={devices ?? []}
                  displayCount
                /> */}
                <Popover
                  width={300}
                  trapFocus
                  position="bottom"
                  withArrow
                  shadow="md"
                >
                  <Popover.Target>
                    <Button variant="light">
                      {t("showHideColumns", {
                        count: collapsedColumnsHandler.size,
                      })}
                    </Button>
                  </Popover.Target>
                  <Popover.Dropdown>
                    {(Object.keys(colKeyMap) as ColumnKey[]).map((key) => (
                      <Checkbox
                        className="my-2"
                        key={key}
                        label={t(colKeyMap[key].translationKey)}
                        onChange={(e) => {
                          if (e.currentTarget.checked) {
                            collapsedColumnsHandler.delete(key);
                          } else {
                            collapsedColumnsHandler.add(key);
                          }
                        }}
                        checked={!collapsedColumnsHandler.has(key)}
                      />
                    ))}
                  </Popover.Dropdown>
                </Popover>
              </div>
            </div>
            {managedUserList == null ? (
              <div className="flex justify-center mt-10 text-gray-500">
                {t("getManagedUsersFailed")}
              </div>
            ) : managedUserList.length <= 0 ? (
              <div className="flex justify-center mt-10 text-gray-500">
                {t("noManagedUsers")}
              </div>
            ) : (
              <>
                <Table
                  striped
                  highlightOnHover
                  withTableBorder
                  withColumnBorders
                >
                  <Table.Thead>
                    <Table.Tr>
                      {(Object.keys(colKeyMap) as ColumnKey[]).map((key) => (
                        <Table.Th
                          key={key}
                          className={
                            collapsedColumnsHandler.has(key)
                              ? collapsedClass
                              : ""
                          }
                        >
                          {t(colKeyMap[key].translationKey)}
                        </Table.Th>
                      ))}
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>{rows}</Table.Tbody>
                </Table>
                {managedUserList.length > pageSize && (
                  <div className="flex justify-center mt-10">
                    <Pagination
                      total={Math.ceil(managedUserList.length / pageSize)}
                      value={page}
                      onChange={setPage}
                      size={isMobile ? "sm" : "md"}
                    />
                  </div>
                )}
              </>
            )}
            <Modal
              opened={addModalOpened}
              onClose={closeAddModal}
              fullScreen={isMobile}
            >
              <AddManagedUser
                snList={addEditSns}
                setSnList={addEditSnsHandler.setState.bind(addEditSnsHandler)}
                onSuccess={closeAddModal}
                deviceList={devices ?? []}
                existingIdList={managedUserList?.map((u) => u.deviceId) ?? []}
              />
            </Modal>
            <Modal
              opened={editModalOpened}
              onClose={closeEditModal}
              fullScreen={isMobile}
            >
              <EditManagedUser
                managedUser={editedManagedUser}
                snList={addEditSns}
                setSnList={addEditSnsHandler.setState.bind(addEditSnsHandler)}
                onSuccess={closeEditModal}
                deviceList={devices ?? []}
              />
            </Modal>
            <Popup
              opened={deleteModalOpened}
              onClose={closeDeleteModal}
              prepare={undefined}
              acceptColor="red"
              titleFactory={() => t("deleteTitle")}
              onMainAction={deleteManagedUsers}
              childrenFactory={() => {
                return (
                  <>
                    <div>
                      {t("deleteWarning", {
                        count: markedManagedUserIds.get().length,
                      })}
                    </div>
                    <DeviceDropDown
                      className="!mt-3"
                      selected={deleteSns}
                      setSelected={deleteSnsHandler.setState.bind(
                        deleteSnsHandler
                      )}
                      deviceList={devices ?? []}
                      displayCount
                      comboboxProps={{}}
                    />
                  </>
                );
              }}
              acceptNode={t("deleteManagedUser")}
            />
          </>
        )}
      </Container>
    </ScrollArea>
  );
};

const Row = memo(
  ({
    managedUser,
    markedIds,
    onEditClick,
    collapsedColumnChecker,
  }: {
    managedUser: ManagedUser;
    markedIds: PropertyWrapper<number[]>;
    onEditClick: Dispatch<ManagedUser>;
    collapsedColumnChecker: (value: ColumnKey) => boolean;
  }): JSX.Element => {
    const { t } = useTranslation(undefined, {
      keyPrefix: "inApp.managedUserList",
    });

    const getCollapsedClass = (key: ColumnKey) =>
      collapsedColumnChecker(key) ? collapsedClass : "";
    return (
      <Table.Tr key={managedUser.id}>
        {/* <Table.Td  className={getCollapsedClass()}>{no++}</Table.Td>
      <Table.Td  className={getCollapsedClass()}>{managedUser.imageUrl}</Table.Td> */}
        <Table.Td className={getCollapsedClass("deviceId")}>
          {managedUser.deviceId}
        </Table.Td>
        <Table.Td className={getCollapsedClass("internalId")}>
          {managedUser.internalId}
        </Table.Td>
        <Table.Td className={getCollapsedClass("name")}>
          {managedUser.name}
        </Table.Td>
        <Table.Td className={getCollapsedClass("isAdmin")}>
          {managedUser.isAdmin ? (
            <span
              className={classNames({
                "text-[var(--mantine-color-red-7)]":
                  !collapsedColumnChecker("isAdmin"),
              })}
            >
              {t("admin")}
            </span>
          ) : (
            t("member")
          )}
        </Table.Td>
        <Table.Td className={getCollapsedClass("password")}>
          {getDisplayPassword(managedUser.password)}
        </Table.Td>
        <Table.Td className={getCollapsedClass("cardNumber")}>
          {managedUser.cardNumber == 0 ? "" : managedUser.cardNumber}
        </Table.Td>
        <Table.Td className={getCollapsedClass("actions")}>
          {!collapsedColumnChecker("actions") && (
            <div className=" flex flex-wrap">
              <Checkbox
                className="m-[2px]"
                size="md"
                onChange={(event) => {
                  if (event.currentTarget.checked) {
                    markedIds.set([...markedIds.get(), managedUser.id]);
                  } else {
                    markedIds.set(
                      markedIds.get().filter((d) => d != managedUser.id)
                    );
                  }
                }}
              />
              <Button
                onClick={() => {
                  onEditClick(managedUser);
                }}
                color="yellow"
                className="!h-6 !w-6 !p-0 flex justify-center items-center m-[2px]"
              >
                <IconEdit size={18} />
              </Button>
            </div>
          )}
        </Table.Td>
      </Table.Tr>
    );
  }
);
Row.displayName = "Row";

export default ManagedUserList;
