import axios, { AxiosError } from "axios";
import FailableResponse, {
  FailedResponse,
  createFailedResponse,
  createSuccessResponse,
} from "../model/FailableResponse";
import { globalAxiosAction } from "./Init.api";
import { getBrearerTokenHeader } from "./Utils/Utils.api";
import { BASE_URL } from "./Common.api";
import { isNumberArray } from "src/utils/Utils";
import {
  GeneralApiResponse,
  handleGeneralApiError,
} from "./Utils/ResponseUtility";
import { createDefaultAxios } from "./Utils/AxiosUtility";

const axiosInstance = createDefaultAxios("/api/group/");
globalAxiosAction.subscribe(axiosInstance);

export const groupApi = {
  async getGroups(): Promise<GeneralApiResponse<Group[]>> {
    try {
      const response = await axiosInstance.get<Group[]>(
        "getGroups",
        getBrearerTokenHeader()
      );
      return createSuccessResponse(response.data);
    } catch (e) {
      return handleGeneralApiError(e);
    }
  },

  async addGroup(name: string): Promise<GeneralApiResponse<Group>> {
    try {
      const response = await axiosInstance.post<Group>(
        "addGroup",
        { name },
        getBrearerTokenHeader()
      );
      return createSuccessResponse(response.data);
    } catch (e) {
      return handleGeneralApiError(e);
    }
  },

  async deleteGroups(
    groupIdList: number[]
  ): Promise<GeneralApiResponse | FailedResponse<"group-not-found", number[]>> {
    try {
      const response = await axiosInstance.delete<null>("deleteGroups", {
        params: { groupIdList },
        ...getBrearerTokenHeader(),
      });

      return createSuccessResponse(response.data);
    } catch (e) {
      return handleGeneralApiError(e, (_error, status, ids) => {
        if (status == 404 && isNumberArray(ids)) {
          return createFailedResponse("group-not-found" as const, ids);
        }
        return null;
      });
    }
  },
  async patchGroup(
    group: Group
  ): Promise<GeneralApiResponse | FailableResponse<null, "group-not-found">> {
    try {
      const response = await axiosInstance.patch<null>(
        "patchgroup",
        { ...group, id: undefined },
        {
          params: { groupId: group.id },
          ...getBrearerTokenHeader(),
        }
      );
      return createSuccessResponse(response.data);
    } catch (e) {
      if (e instanceof AxiosError) {
        switch (e.response?.status) {
          case 500:
            return createFailedResponse("server-error");
          case 404:
            return createFailedResponse(
              "group-not-found"
              // e.response?.data.groupsNotFound[0]
            );
        }
      }

      return createFailedResponse("error");
    }
  },
};

type TD = AxiosError<{ existingDevices: string[] }>;
