const systemStatusRecord = {
  1: { en: "Software unlocked", vi: "Phần mềm mở khóa" },
  2: { en: "Software locked", vi: "Phần mềm khóa" },
  3: { en: "Software normally open", vi: "Phần mềm mở bình thường" },
  4: {
    en: "Controller automatically enters into normally open",
    vi: "Bộ điều khiển mở cửa",
  },
  5: {
    en: "Controller automatically close the door",
    vi: "Bộ điều khiển đóng cửa",
  },
  6: { en: "Long press door switch normally open", vi: "" },
  7: { en: "Long press door switch normally close", vi: "" },
  8: { en: "Software locked", vi: "Phần mềm khóa" },
  9: { en: "Software unlocked", vi: "Phần mềm mở khóa" },
  10: { en: "Controller timing locked--automatically locked on time", vi: "Cửa khóa tự động" },
  11: {
    en: "Controller timing locked--automatically unlocked on time",
    vi: "Cửa mở khóa tự động",
  },
  12: { en: "Alarm--Locked", vi: "" },
  13: { en: "Alarm--Unlocked", vi: "" },
  14: { en: "Illegal verification alarm", vi: "Cảnh báo xác thực sai" },
  15: { en: "Door sensor alarm", vi: "Cảnh báo cảm biến cửa" },
  16: { en: "Duress alarm", vi: "Cảnh báo khẩn cấp" },
  17: { en: "Door timeout alarm", vi: "Cảnh báo quá thời gian" },
  18: { en: "Blacklist alarm", vi: "Cảnh báo danh sách đen" },
  19: { en: "Fire alarm", vi: "Chuông báng lửa" },
  20: { en: "Tamper alarm", vi: "Cảnh báo can thiệp" },
  21: { en: "Remove illegal verification alarm", vi: "Tắt cảnh báo xác thực sai" },
  22: { en: "Remove door sensor alarm", vi: "Tắt cảnh báo cảm biến cửa" },
  23: { en: "Remove duress alarm", vi: "Tắt cảnh báo khẩn cấp" },
  24: { en: "Remove door timeout alarm", vi: "Tắt cảnh báo thời gian" },
  25: { en: "Remove blacklist alarm", vi: "Tắt cảnh báo danh sách đen" },
  26: { en: "Remove fire alarm", vi: "Tắt chuông báo cháy" },
  27: { en: "Remove tamper alarm", vi: "Tắt cảnh báo can thiệp" },
  28: { en: "System is powered", vi: "Hệ thống bật" },
  29: { en: "System error reset（watchdog）", vi: "Hệ thống khởi động lại do lỗi (watchdog)" },
  30: { en: "Device formatting records", vi: "Thiết bị xóa dữ liệu" },
  31: { en: "Card reader is connected backwards.", vi: "Máy đọc thẻ kết nối ngược" },
  32: { en: "Card reader is not connected properly.", vi: "Máy đọc thẻ kết nối không đúng" },
  33: { en: "Unrecognized card reader", vi: "Không nhận ra máy đọc thẻ" },
  34: { en: "Network cable is disconnected", vi: "Ngắt kết nối dây mạng" },
  35: { en: "Network cable has been inserted", vi: "Kết nối dây mạng" },
  36: { en: "WIFI is connected", vi: "Kết nối WIFI" },
  37: { en: "WIFI is disconnected", vi: "Ngắt kết nối WIFI" },
};

export default systemStatusRecord;
