import {
  DetailedHTMLProps,
  HTMLAttributes,
  SetStateAction,
  useState,
} from "react";
import React from "react";
import {
  PillsInput,
  Pill,
  Combobox,
  CheckIcon,
  Group,
  useCombobox,
  ComboboxProps,
} from "@mantine/core";
import Device from "src/services/core/device/models/Device";
import { useTranslation } from "react-i18next";

function DeviceDropDown(
  props: {
    selected: string[];
    setSelected: (setState: SetStateAction<string[]>) => void;
    deviceList: Device[];
    displayCount?: boolean | undefined;
    comboboxProps?: ComboboxProps | undefined;
  } & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
) {
  const { t } = useTranslation(undefined, {
    keyPrefix: "inApp.component.deviceDropDown",
  });

  const {
    selected: selectedSns,
    setSelected: setSelectedSns,
    deviceList: preTransformDeviceList,
    displayCount: _displayCount,
    comboboxProps,
    ...divProps
  } = props;
  const displayCount = props.displayCount ?? false;

  const deviceList = preTransformDeviceList.map((d) => ({
    name: d.name != "" ? d.name : d.serialNumber,
    sn: d.serialNumber,
  }));
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  });

  const [search, setSearch] = useState("");

  const handleValueSelect = (val: string) =>
    setSelectedSns((current) =>
      current.includes(val)
        ? current.filter((v) => v !== val)
        : [...current, val]
    );

  const handleValueRemove = (val: string) =>
    setSelectedSns((current) => current.filter((v) => v !== val));

  const values = selectedSns.flatMap((sn) => {
    const device = deviceList.find((d) => d.sn == sn);
    return device != null ? (
      <Pill key={sn} withRemoveButton onRemove={() => handleValueRemove(sn)}>
        {device.name}
      </Pill>
    ) : null;
  });

  const options = deviceList
    .filter((device) =>
      device.name.toLowerCase().includes(search.trim().toLowerCase())
    )
    .map((device) => (
      <Combobox.Option
        value={device.sn}
        key={device.sn}
        active={selectedSns.includes(device.sn)}
      >
        <Group gap="sm">
          {selectedSns.includes(device.sn) ? <CheckIcon size={12} /> : null}
          <span>{device.name}</span>
        </Group>
      </Combobox.Option>
    ));

  return (
    <div {...divProps}>
      <Combobox
        store={combobox}
        onOptionSubmit={handleValueSelect}
        {...comboboxProps}
      >
        <Combobox.DropdownTarget>
          <PillsInput onClick={() => combobox.openDropdown()}>
            <Pill.Group>
              {displayCount ? (
                <Pill>{t("device", { count: selectedSns.length })}</Pill>
              ) : (
                values
              )}

              <Combobox.EventsTarget>
                <PillsInput.Field
                  onFocus={() => combobox.openDropdown()}
                  onBlur={() => combobox.closeDropdown()}
                  value={search}
                  placeholder="Search values"
                  onChange={(event) => {
                    combobox.updateSelectedOptionIndex();
                    setSearch(event.currentTarget.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Backspace" && search.length === 0) {
                      event.preventDefault();
                      handleValueRemove(selectedSns[selectedSns.length - 1]);
                    }
                  }}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>

        <Combobox.Dropdown>
          <Combobox.Options>
            {options.length > 0 ? (
              options
            ) : (
              <Combobox.Empty>{t("noDevices")}</Combobox.Empty>
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </div>
  );
}

export default DeviceDropDown;
