import {
  Button,
  Container,
  SimpleGrid,
  LoadingOverlay,
  UnstyledButton,
  Modal,
  ScrollArea,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { inAppPaths } from "src/App";
import {
  reduxStore,
  useAppDispatch,
  useAppSelector,
} from "src/services/redux/ReduxStore";
import {
  IconLockOpen,
  IconPlus,
  IconTrashXFilled,
  IconEdit,
  IconEditOff,
} from "@tabler/icons-react";
import {
  nullToastUpdateOptions,
  toastCommonError,
} from "src/pages/utils/ToastUtils";
import AddGroup from "./AddGroup";
import EditGroup from "./EditGroup";
import groupService from "src/services/core/group/Group.service";
import { useTranslation } from "react-i18next";
import Popup from "src/pages/components/Popup";

const GroupList: () => JSX.Element = () => {
  const [isLoading, { open: turnOnLoading, close: turnOffLoading }] =
    useDisclosure(false);
  const navigate = useNavigate();
  const currentGroup = useAppSelector((s) => s.group);
  const groups = useAppSelector((s) => s.groupList);
  const [isEditDeleteEnabled, { toggle: toggleEditDelete }] =
    useDisclosure(false);
  const [addModalOpened, { open: openAddModal, close: closeAddModal }] =
    useDisclosure(false);
  const [editModalOpened, { open: openEditModal, close: closeEditModal }] =
    useDisclosure(false);
  const [toBeEditedGroup, setToBeEditedGroup] = useState<Group>({
    id: -1,
    name: "",
  });
  const { t } = useTranslation(undefined, {
    keyPrefix: "inApp.groupList",
  });
  const [
    deleteModalOpened,
    { open: openDeleteModal, close: closeDeleteModal },
  ] = useDisclosure(false);
  const toBeDeleted = useRef<Group>({ id: -1, name: "stub-group" });

  const deleteGroup = useCallback(async () => {
    const promise = groupService.deleteGroup([toBeDeleted.current.id]);
    const toastId = toast.loading(t("deleting"), {
      closeButton: true,
      closeOnClick: true,
    });

    const result = await promise;
    if (result.success) {
      toast.update(toastId, {
        ...nullToastUpdateOptions,
        render: t("groupDeleted", { name: toBeDeleted.current.name }),
        type: "success",
      });
      closeDeleteModal();
      return;
    }

    toastCommonError(result.code, toastId);
  }, []);

  useEffect(() => {
    const groupList = reduxStore.getState().groupList;
    if (groupList == null) {
      turnOnLoading();
      groupService
        .getGroupList()
        .then((result) => {
          if (!result.success) {
            toastCommonError(result.code);
          }
        })
        .finally(turnOffLoading);
    }
  }, []);

  const cards = groups?.map((group) => (
    <UnstyledButton
      component="div"
      key={group.id}
      style={{
        border:
          "2px solid" +
          (currentGroup?.id == group.id
            ? " #60a5fa"
            : " var(--mantine-color-default-border)"),
      }}
      tabIndex={0}
      // active:translate-y-[1px]
      className={
        "flex !h-32 !p-0 rounded-md hover:!bg-slate-300 cursor-pointer overflow-hidden" +
        (currentGroup?.id == group.id ? "!border-blue-400" : "")
      }
      onClick={() => {
        groupService.selectGroup(group);
        navigate(inAppPaths.devices);
      }}
    >
      {/* <div className="flex w-full h-full flex-none p-1 justify-end  bg-blue-300"></div> */}
      <div className="flex flex-col h-full w-full relative">
        {isEditDeleteEnabled ? (
          <div className="flex top-0 right-0 absolute h-0 pt-1 pr-1 justify-end">
            <Button
              onClick={(e) => {
                setToBeEditedGroup(group);
                openEditModal();
                e.stopPropagation();
              }}
              color="yellow"
              className="!p-1 !h-6 !rounded-md mr-1"
            >
              <IconEdit size={15} />
            </Button>
            <Button
              onClick={(e) => {
                toBeDeleted.current = group;
                openDeleteModal();
                e.stopPropagation();
              }}
              color="red"
              className="!p-1 !h-6 !rounded-md"
            >
              <IconTrashXFilled size={15} />
            </Button>
          </div>
        ) : (
          ""
        )}
        <div className="flex flex-1 items-center font-medium text-base text-wrap text-center p-2 overflow-hidden">
          <span className="w-full text-center overflow-hidden break-words">
            {group.name}
          </span>
        </div>
      </div>
    </UnstyledButton>
  ));

  return (
    <ScrollArea className="w-full h-full">
      <Container size="md" my={40}>
        {isLoading ? (
          <div className="h-svh w-full">
            <LoadingOverlay
              visible={true}
              zIndex={1000}
              overlayProps={{ radius: "sm", blur: 2 }}
            />
          </div>
        ) : (
          <>
            <div className="flex justify-between items-end text-lg">
              <div>{t("selectGroup")}</div>
              <Button
                variant={isEditDeleteEnabled ? "filled" : "gradient"}
                color="gray"
                gradient={{ from: "yellow", to: "red" }}
                leftSection={
                  isEditDeleteEnabled ? (
                    <IconEditOff size={20} />
                  ) : (
                    <IconEdit size={20} />
                  )
                }
                className={"p-0"}
                onClick={toggleEditDelete}
              >
                {isEditDeleteEnabled ? t("disableEdit") : t("enableEdit")}
              </Button>
            </div>
            <SimpleGrid cols={{ base: 1, sm: 3, lg: 4 }} className="mt-6">
              {cards}
              <Button
                variant="default"
                className={
                  "flex !h-32 p-3 justify-center items-center !border-2 hover:!bg-slate-300 cursor-pointer "
                }
                //  "flex !h-32 p-3 justify-center items-center border-2 rounded-lg border-slate-300 hover:bg-slate-200 cursor-pointer"
                onClick={openAddModal}
              >
                <IconPlus
                  className="bg-slate-300 p-2 group-hover:!bg-transparent rounded-full"
                  color="white"
                  style={{ width: 48, height: 48 }}
                  stroke={4}
                />
              </Button>
            </SimpleGrid>
          </>
        )}
        <Modal opened={addModalOpened} onClose={closeAddModal}>
          <AddGroup onFormSubmit={closeAddModal} />
        </Modal>
        <Modal opened={editModalOpened} onClose={closeEditModal}>
          <EditGroup group={toBeEditedGroup} onFormSubmit={closeEditModal} />
        </Modal>
        <Popup
          opened={deleteModalOpened}
          onClose={closeDeleteModal}
          prepare={undefined}
          acceptColor="red"
          titleFactory={() => t("deleteTitle")}
          onMainAction={deleteGroup}
          childrenFactory={() => {
            return (
              <div>
                {t("deleteWarning", {
                  name: toBeDeleted.current.name,
                })}
              </div>
            );
          }}
          acceptNode={t("delete")}
        />
      </Container>
    </ScrollArea>
  );
};

export default GroupList;
