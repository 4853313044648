import { GeneralApiResponse } from "src/repositories/api/Utils/ResponseUtility";
import { ActivityLog, LogType, SensorLog, SystemLog } from "./models/Log";
import logApi from "src/repositories/api/Log.api";
import groupService from "../group/Group.service";
import {
  createFailedResponse,
  createSuccessResponse,
  FailedResponse,
} from "src/repositories/model/FailableResponse";
import deviceService from "../device/Device.service";
import { handleGeneralErrorResponse } from "src/services/utils/Utils.service";
import Device, { getDeviceDisplayName } from "../device/models/Device";
import managedUserService from "../managed-user/ManagedUser.service";

async function getGroupAndDeviceList(): Promise<
  | GeneralApiResponse<[group: Group, deviceList: Device[]]>
  | FailedResponse<"no-selected-groups">
> {
  const groupResult = await groupService.getSelectedGroup(true, true);
  if (!groupResult.success) {
    handleGeneralErrorResponse(groupResult);
    return groupResult;
  }
  if (groupResult.value == null) {
    return createFailedResponse("no-selected-groups");
  }

  const deviceListResult = await deviceService.getDevices(
    groupResult.value.id,
    true
  );
  if (!deviceListResult.success) {
    handleGeneralErrorResponse(deviceListResult);
    return deviceListResult;
  }

  return createSuccessResponse([groupResult.value, deviceListResult.value]);
}

const innerJoin = <XT, YT, ZT>(
  xs: XT[],
  primary: (x: XT) => any,
  ys: YT[],
  foreign: (y: YT) => any,
  sel: (x?: XT, y?: YT) => ZT | undefined
): ZT[] => {
  const xIndex = xs.reduce(
    (ix, x) => ix.set(primary(x), x),
    new Map<any, XT>()
  );
  return ys.flatMap((y) => {
    const key = foreign(y);
    const x = xIndex.get(key);
    return sel(x, y) ?? [];
  });
};

async function getActivityLogs(
  from: Date | null,
  to: Date | null
): Promise<
  GeneralApiResponse<ActivityLog[]> | FailedResponse<"no-selected-groups">
> {
  const gadlResult = await getGroupAndDeviceList();
  if (!gadlResult.success) {
    return gadlResult;
  }
  const [group, deviceList] = gadlResult.value;

  const managedUserListResult = await managedUserService.getManagedUserList(
    group.id,
    true,
    true,
    null
  );

  if (!managedUserListResult.success) {
    handleGeneralErrorResponse(managedUserListResult);
    return managedUserListResult;
  }

  const logResult = await logApi.getActivityLogs(group.id, from, to);
  if (!logResult.success) {
    handleGeneralErrorResponse(logResult);
    return logResult;
  }

  const intermediate = innerJoin(
    deviceList,
    (d) => d.id,
    logResult.value,
    (l) => l.deviceId,
    (d, l) => (l == undefined ? undefined : { device: d, log: l })
  );

  const logs = innerJoin(
    managedUserListResult.value,
    (m) => m.id,
    intermediate,
    (t) => t.log.managedUserId,
    (m, t) =>
      t == undefined
        ? undefined
        : {
            time: new Date(t.log.time),
            deviceName:
              t.device == undefined
                ? "undefined device"
                : getDeviceDisplayName(t.device),
            status: t.log.status,
            managedUserName: m?.name ?? "undefined user",
            isEnter: t.log.isEnter,
          }
  );
  return createSuccessResponse(logs);
}

async function getSensorLogs(
  from: Date | null,
  to: Date | null
): Promise<
  GeneralApiResponse<SensorLog[]> | FailedResponse<"no-selected-groups">
> {
  const gadlResult = await getGroupAndDeviceList();
  if (!gadlResult.success) {
    return gadlResult;
  }
  const [group, deviceList] = gadlResult.value;

  const logResult = await logApi.getSensorLogs(group.id, from, to);
  if (!logResult.success) {
    handleGeneralErrorResponse(logResult);
    return logResult;
  }

  const logs = innerJoin(
    deviceList,
    (d) => d.id,
    logResult.value,
    (l) => l.deviceId,
    (d, l) =>
      l == null
        ? undefined
        : {
            time: new Date(l.time),
            deviceName:
              d == undefined ? "undefined device" : getDeviceDisplayName(d),
            status: l.status,
          }
  );

  return createSuccessResponse(logs);
}

async function getSystemLogs(
  from: Date | null,
  to: Date | null
): Promise<
  GeneralApiResponse<SensorLog[]> | FailedResponse<"no-selected-groups">
> {
  const gadlResult = await getGroupAndDeviceList();
  if (!gadlResult.success) {
    return gadlResult;
  }
  const [group, deviceList] = gadlResult.value;

  const logResult = await logApi.getSystemLogs(group.id, from, to);
  if (!logResult.success) {
    handleGeneralErrorResponse(logResult);
    return logResult;
  }

  const logs = innerJoin(
    deviceList,
    (d) => d.id,
    logResult.value,
    (l) => l.deviceId,
    (d, l) =>
      l == null
        ? undefined
        : {
            time: new Date(l.time),
            deviceName:
              d == undefined ? "undefined device" : getDeviceDisplayName(d),
            status: l.status,
          }
  );

  return createSuccessResponse(logs);
}

const logService = {
  getActivityLogs,
  getSensorLogs,
  getSystemLogs,
};

export default logService;
