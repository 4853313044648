import {
  FailedResponse,
  createFailedResponse,
  createSuccessResponse,
} from "../../model/FailableResponse";
import { globalAxiosAction } from "../Init.api";
import { getBrearerTokenHeader } from "../Utils/Utils.api";
import {
  GeneralApiResponse,
  handleGeneralApiError,
} from "../Utils/ResponseUtility";
import { isStringArray } from "src/utils/Utils";
import Device from "src/services/core/device/models/Device";
import { DeviceResponseReport } from "src/services/core/device/models/DeviceResponseReport";
import { createDefaultAxios } from "../Utils/AxiosUtility";
import ManagedUserOnDevice from "src/services/core/device/models/ManagedUserOnDevice";
import { AddUserToDeviceDto } from "./dto/AddUserToDeviceDto";

const axiosInstance = createDefaultAxios("/api/physicalDevice/");
globalAxiosAction.subscribe(axiosInstance);

async function openDoors(
  deviceSnList: string[]
): Promise<GeneralApiResponse<DeviceResponseReport | null>> {
  try {
    const response = await axiosInstance.post<DeviceResponseReport | null>(
      "openDoors",
      deviceSnList,
      getBrearerTokenHeader()
    );

    return createSuccessResponse(response.data);
  } catch (e) {
    return handleGeneralApiError(e);
  }
}

async function lockDoors(
  deviceSnList: string[]
): Promise<GeneralApiResponse<DeviceResponseReport | null>> {
  try {
    const response = await axiosInstance.post<DeviceResponseReport | null>(
      "lockDoors",
      deviceSnList,
      getBrearerTokenHeader()
    );

    return createSuccessResponse(response.data);
  } catch (e) {
    return handleGeneralApiError(e);
  }
}

async function getUsersOnDevice(deviceSn: string): Promise<
  GeneralApiResponse<{
    managedUsers: ManagedUserOnDevice[];
    report: DeviceResponseReport;
  }>
> {
  try {
    const response = await axiosInstance.get<{
      managedUsers: ManagedUserOnDevice[];
      report: DeviceResponseReport;
    }>(`getUsersOnDevice/${deviceSn}`, getBrearerTokenHeader());

    return createSuccessResponse(response.data);
  } catch (e) {
    return handleGeneralApiError(e);
  }
}

async function addUserToDevices(
  deviceSn: string,
  dtoList: AddUserToDeviceDto[]
): Promise<GeneralApiResponse<DeviceResponseReport>> {
  try {
    const response = await axiosInstance.post<DeviceResponseReport>(
      `addUsersToDevice/${deviceSn}`,
      dtoList,
      getBrearerTokenHeader()
    );

    return createSuccessResponse(response.data);
  } catch (e) {
    return handleGeneralApiError(e);
  }
}

async function deleteUsersFromDevice(
  deviceSn: string,
  ids: number[]
): Promise<GeneralApiResponse<DeviceResponseReport>> {
  try {
    const response = await axiosInstance.delete<DeviceResponseReport>(
      `deleteUsersFromDevice/${deviceSn}`,
      {
        params: { ids },
        ...getBrearerTokenHeader(),
      }
    );

    return createSuccessResponse(response.data);
  } catch (e) {
    return handleGeneralApiError(e);
  }
}

export type DownloadUserToDeviceReport = {
  idOnDeviceList: number[];
  deviceResponseReport: DeviceResponseReport;
};

async function addAllUsersToDevice(
  deviceSn: string,
  skippedIdList?: number[]
): Promise<GeneralApiResponse<DownloadUserToDeviceReport[]>> {
  try {
    const response = await axiosInstance.post<DownloadUserToDeviceReport[]>(
      `addAllUsersToDevice/${deviceSn}`,
      skippedIdList,
      {
        ...getBrearerTokenHeader(),
        timeout: 20000,
      }
    );

    return createSuccessResponse(response.data);
  } catch (e) {
    return handleGeneralApiError(e);
  }
}

const physicalDeviceApi = {
  openDoors,
  lockDoors,
  getUsersOnDevice,
  addUserToDevices,
  deleteUsersFromDevice,
  addAllUsersToDevice,
};

export default physicalDeviceApi;
