import {
  Button,
  Container,
  LoadingOverlay,
  ScrollArea,
  Table,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { inAppPaths } from "src/App";
import { toastCommonError } from "src/pages/utils/ToastUtils";
import logService from "src/services/core/log/Log.service";
import { delay } from "src/utils/Utils";
import LogRow from "./components/LogRow";
import DatePicker from "../components/DatePicker";
import { SensorLog } from "src/services/core/log/models/Log";

const SensorLogs: () => JSX.Element = () => {
  const [isLoading, { open: turnOnLoading, close: turnOffLoading }] =
    useDisclosure(true);
  const [logs, setLogs] = useState<SensorLog[]>();
  const [noSelectedGroup, { open: setNoSelectedGroupTrue }] =
    useDisclosure(false);
  const { t } = useTranslation(undefined, { keyPrefix: "inApp.logs.sensor" });
  const { t: ts } = useTranslation(undefined, {
    keyPrefix: "inApp.logs.shared",
  });

  const filter = async (from: Date | null, to: Date | null) => {
    turnOnLoading();
    const logsResult = await logService.getSensorLogs(from, to);
    if (!logsResult.success) {
      if (logsResult.code == "no-selected-groups") {
        setNoSelectedGroupTrue();
      } else {
        toastCommonError(logsResult.code);
      }
    } else {
      setLogs(logsResult.value);
    }
    turnOffLoading();
  };

  useEffect(() => {
    (async () => {
      const delayPromise = delay(300);
      const from = new Date();
      const to = new Date();
      from.setHours(0, 0, 0);
      to.setHours(0, 0, 0);
      to.setDate(to.getDate() + 1);
      const result = await logService.getSensorLogs(from, to);
      await delayPromise;
      if (!result.success) {
        if (result.code == "no-selected-groups") {
          setNoSelectedGroupTrue();
        } else {
          toastCommonError(result.code);
        }
      } else {
        setLogs(result.value);
      }
      turnOffLoading();
    })();
  }, []);

  return noSelectedGroup ? (
    <Navigate to={inAppPaths.groups} />
  ) : (
    <ScrollArea className="w-full h-full">
      <Container size="md" my={40}>
        <div className="mb-10">
          {/* <FromToPicker onSubmit={filter} /> */}
          <DatePicker
            defaultDate={new Date()}
            onSelect={(date) => {
              const from = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              );
              const to = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                23,
                59,
                59,
                999
              );
              filter(from, to);
            }}
          />
        </div>
        {isLoading ? (
          <div className="h-svh w-full">
            <LoadingOverlay
              className=""
              visible={true}
              zIndex={1000}
              // overlayProps={{ radius: "sm", blur: 2 }}
            />
          </div>
        ) : (
          <>
            {logs == null ? (
              <div className="flex justify-center mt-10 text-gray-500">
                {ts("getLogFail")}
              </div>
            ) : logs.length <= 0 ? (
              <div className="flex justify-center mt-10 text-gray-500">
                {ts("noLogs")}
              </div>
            ) : (
              <Table striped highlightOnHover withTableBorder withColumnBorders>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>{t("table.time")}</Table.Th>
                    <Table.Th>{t("table.deviceName")}</Table.Th>
                    <Table.Th>{t("table.status")}</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {logs.map((log) => (
                    <LogRow
                      key={log.time + log.deviceName + log.status}
                      log={log}
                    />
                  ))}
                </Table.Tbody>
              </Table>
            )}
          </>
        )}
      </Container>
    </ScrollArea>
  );
};

export default SensorLogs;
