import { configureStore } from "@reduxjs/toolkit";
import tokenSlice from "./slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import deviceListSlice from "./slices/DeviceListSlice";
import { groupListSlice, selectedGroupSlice } from "./slices/GroupSlice";
import {
  activeManagedUserSlice,
  inactiveManagedUserSlice,
} from "./slices/ManagedUserListSlice";
import tokenUnauthorizedSlice from "./slices/miscellaneous/TokenUnauthorizedSlice";

export const reduxStore = configureStore({
  reducer: {
    token: tokenSlice.reducer,
    deviceList: deviceListSlice.reducer,
    groupList: groupListSlice.reducer,
    group: selectedGroupSlice.reducer,
    activeManagedUserList: activeManagedUserSlice.reducer,
    inactiveManagedUserList: inactiveManagedUserSlice.reducer,
    tokenUnauthorizedSlice: tokenUnauthorizedSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof reduxStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof reduxStore.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
