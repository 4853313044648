import {
  Button,
  Container,
  NumberInput,
  Paper,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  nullToastUpdateOptions,
  toastCommonError,
} from "src/pages/utils/ToastUtils";
import groupService from "src/services/core/group/Group.service";

const EditGroup: (props: {
  group: Group;
  onFormSubmit?: () => void;
}) => JSX.Element = (props) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "inApp.groupList.editGroup",
  });

  const form = useForm({
    initialValues: {
      name: props.group.name,
    },
    validate: {
      name: (value) =>
        value.trim().length > 0 ? null : t("noEmptyName"),
    },
  });

  const formSubmit = useCallback(
    async (values: typeof form.values) => {
      props.onFormSubmit?.();

      const editPromise = groupService.editGroup(props.group, {
        name: values.name,
      });

      const toastId = toast.loading(t("editing"), {
        closeButton: true,
        closeOnClick: true,
      });

      const result = await editPromise;

      if (result.success) {
        toast.update(toastId, {
          ...nullToastUpdateOptions,
          render: t("editSuccess"),
          type: "success",
        });
        return;
      }

      if (!result.success && result.code == "no-change") {
        toast.update(toastId, {
          ...nullToastUpdateOptions,
          render: t("editSuccess"),
          type: "warning",
        });
        return;
      }

      toastCommonError(result.code, toastId);
    },
    [props.onFormSubmit]
  );

  return (
    <Container className="p-10 pt-0">
      <Title ta="center">{t("title")}</Title>

      <Paper p={10} mt={10} radius="md">
        <form onSubmit={form.onSubmit((values) => formSubmit(values))}>
          <TextInput
            label={t("groupName")}
            placeholder={t("groupNamePlaceholder")}
            required
            mt="md"
            {...form.getInputProps("name")}
          />
          <Button fullWidth mt="xl" type="submit">
            {t("edit")}
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default EditGroup;
