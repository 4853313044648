/* eslint-disable quotes */
import React, { ReactNode } from "react";
import "./css/user-policy.css";

const PrivacyPolicy = (): ReactNode => {
  return (
    <>
      <h1>KT Lock Privacy Policy</h1>
      <div className="content">Effective date: March 11, 2024</div>
      <div className="content">
        KT Lock And its Affiliates {`(`}as hereinafter defined{`)`} {`(`}“we”,
        “us”, “our”, or “KT Lock”{`)`} are committed to protecting your privacy.
        The KT Lock Privacy Policy {`(`}this “Policy”{`)`} describes our
        practices in connection with information privacy on Personal Data {`(`}
        as hereinafter defined{`)`} we process through your use of our products
        and services{`(`}collectively, the “Services”{`)`}, for instance the KT
        Lock Mobile Application {`(`}the “App”{`)`} and its connected Smart
        Devices.
      </div>
      <div className="content">
        Before you start using the Services, please carefully read this Policy
        which details our purposes for collecting and processing your Personal
        Data, as well as how we use, store, share and transfer your Personal
        Data. In this Policy you will also find ways to exercise your rights of
        access, update, delete or protect your Personal Data.
      </div>
      <div className="content">
        If you have any question regarding this Policy, please do not hesitate
        to contact us via:
      </div>
      <div className="content">KT Lock Customer Service Department:</div>
      <div className="content">+842439912929 or contact.ktlock@gmail.com</div>
      <div className="content">KT Lock Privacy Office: contact.ktlock@gmail.com</div>
      <div className="content">
        You are not obliged to provide to us your Personal Data {`(`}as defined
        below{`)`}. However, we may be unable to provide you with certain
        products and/or Services if you decline to provide such data.
      </div>
      <div className="content">
        If you are a child under the age of 16 {`(`}or such other age provided
        by applicable law in your country/region of residence{`)`}, please read
        this agreement and the “KT Lock Children’s Privacy Statement” with your
        legal guardian or parent and make sure that you and your guardian or
        parent have clear understanding about our privacy protection
        practices.For details, please refer to KT Lock Children’s Privacy
        Statement.{" "}
      </div>
      <div className="content">Definitions</div>
      <div className="content">In this Policy:</div>
      <div className="content">
        Affiliate means any company, firm or legal entity that: {`(`}1{`)`} is
        directly or indirectly controlled by KT Lock; or {`(`}2{`)`} directly or
        indirectly controls KT Lock; or {`(`}3{`)`} jointly with KT Lock,
        controls the same company; or {`(`}4{`)`} is, directly or indirectly,
        under common control of the same company with KT Lock. Affiliates shall
        include, without limitation, KT Lock’s parent companies, subsidiaries,
        and such subsidiaries under common control of the same parent company as
        KT Lock.
      </div>
      <div className="content">
        Personal Data means information generated, collected, recorded and/or
        stored, electronically or otherwise, that can be used to identify an
        individual or reflect the activity of an individual, either from that
        information alone, or from that information and other information we
        have access to about that individual.{" "}
      </div>
      <div className="content">
        Personal Sensitive Data includes personal biometric information,
        communication records and contents, health
      </div>
      <div className="content">
        Information, transaction information, and precise location information,
        etc., according to various data protection laws and regulations. When we
        collect Personal Sensitive Data from you, we will generate an explicit
        notification for your consent before we collection personal sensitive
        data about you.
      </div>
      <div className="content">
        Smart Devices refers to those computing devices produced or manufactured
        by hardware manufacturers, with
      </div>
      <div className="content">
        Human-machine interface and the ability to transmit data that connect
        wirelessly to a network, including: smart home appliances, smart
        wearable devices, smart air cleaning devices, etc.
      </div>
      <div className="content">What Personal Data Do We Collect?</div>
      <div className="content">
        In order to provide the Services to you, we will ask you to provide
        necessary Personal Data that is required to provide those Services.
        Please note that such Personal Data are necessary for carrying out
        relevant Services that you may require and you are obliged to provide
        such Personal Data in order to use our Services. If you do not provide
        your Personal Data, we may not be able to provide you with the Services.
      </div>
      <h2>1{`)`} Information You Voluntarily Provide to Us</h2>
      <div className="content">
        Registered Account Data: When you register an account with us, we may
        collect your account name and contact details, such as your email
        address, phone number, user name, and login credentials. During your
        interaction with the Services, we may further collect your nickname,
        country code, language preference or time zone information into your
        account.
      </div>
      <div className="content">
        If you authorize login to the Services with a third-party account, we
        will obtain from such third party your account information {`(`}such as
        portrait, nickname, region etc.{`)`} which may be bound with your KT
        Lock account for quick login. We will ensure compliance with applicable
        data protection laws and regulations, as well as agreements, policies or
        documentations agreed with such third party regarding sharing personal
        information, in processing your Personal Data.
      </div>
      <div className="content">
        However, if the Services you request or purchase are based on your
        account, please go to the registration/login page for guidance.
      </div>
      <div className="content">
        Feedback: When using feedback and suggestion features in the Services,
        we will collect your email address, mobile phone number and your
        feedback content to address your problems and solve device failures on a
        timely basis.
      </div>
      <div className="content">Information based on additional functions:</div>
      <div className="content">
        In order to offer you with more convenient and higher-quality Services
        with optimized user experiences, we may collect and use certain
        information if you consent to use of additional functions in the App.
        Please note, if you do not provide such information, you may continue to
        use basic Services of the App and connected Smart Devices, but certain
        features based on these additional functions may not be available. These
        additional functions may include:
      </div>
      <div className="content">Additional functions based on location information:</div>
      <div className="content">
        When you enable the location-based functions through permission settings
        on your mobile device, we will collect and process your location
        information to enable these functions, such as pairing with your Smart
        Devices. Also, we may collect information about your: a{`)`} real-time
        and precise location, for instance when you choose to use the automation
        scenarios for controlling your Smart Devices, or b{`)`} non-precise
        geo-location when you use certain Smart Devices or the Services, such as
        robot cleaner and weather service.
      </div>
      <div className="content">
        Based on your consent, when you enable the geo-fence feature, your
        location information will be generated and shared with Google Maps
        services. Please note that Google has corresponding data protection
        measures, which you may refer to Google’s Data Processing and Security
        Terms for more details. You may disable the collection and use of your
        location information by managing the device level settings, upon which
        we will cease to collect and use your location information.{" "}
      </div>
      <div className="content">Additional services based on camera:</div>
      <div className="content">
        You may use the camera to scan the code by turning on the camera
        permission to pair with a Smart Device, take video, etc. Please be aware
        that even if you have agreed to enable the camera permission, we will
        only obtain information when you actively use the camera for scanning
        codes, video recording, etc.{" "}
      </div>
      <h3>
        Additional services for accessing and uploading pictures/videos based on
        photo albums {`(`}picture library/video library{`)`}:
      </h3>
      <div className="content">
        You can use this function to upload your photos/pictures/videos after
        agree the photo album permission, so as to realize functions such as
        changing the avatar, reporting device usage problems by providing photo
        proofs, etc. When you use the photos and other functions, we will not
        recognize this information; but when you report a device usage problem,
        we may use the photos/pictures you upload to locate your problem.
      </div>
      <h3>Additional services related to microphone-based service:</h3>
      <div className="content">
        You can use the microphone to send voice information after turning on
        the microphone permission, such as shooting videos, waking up the voice
        assistant, etc. For these functions, we will collect your voice
        information to recognize your command. Please be aware that even if you
        have agreed to enable the microphone permission, we will only obtain
        voice information through the microphone when you voluntarily activate
        the microphone in the App.
      </div>
      <h3>
        Additional services based on storage permission {`(`}Android{`)`}:
      </h3>
      <div className="content">
        The purpose is to ensure stable operation of the App by utilizing the
        storage permission. After you give or indicate the permission to
        read/write your mobile device’s storage, we will access pictures, files,
        crash log information and other necessary information from your mobile
        device’s storage to provide you with functions such as information
        publications, or recording the crash log information locally.
      </div>
      <h3>Additional services based on Notification permission:</h3>
      <div className="content">
        The reason why we ask you for the permission is to send you
        notifications about using the products and services, especially if you
        have purchased security services and you require an alert or message so
        that you can capture the real-time status.
      </div>
      <h3>Additional services based on Alert Window permission：</h3>
      <div className="content">
        You may choose to bind a camera in the App and require the App to
        display the real-time image of the camera in a separate window.
      </div>
      <h3>Additional services based on Bluetooth permission:</h3>
      <div className="content">
        You can enable Bluetooth functions after turning on the permission,
        including controlling the Smart Devices, acquiring device status, and
        device network configuration. In these functions, we will communicate
        with terminal devices via Bluetooth. Please be aware that even if you
        have agreed to enable the Bluetooth permission, we will only use
        Bluetooth for communication in these scenarios: display device status on
        the home page and device panel; perform device control on the home page
        and device panel; we will use it on the home page and the add device
        page, for discovering the devices via distribution network.
      </div>
      <h3>
        Additional services based on HomeKit permission {`(`}iOS{`)`}:
      </h3>
      <div className="content">
        You can enable related functions after enabling HomeKit permissions,
        including discovering Smart Devices, enabling Smart Device network
        configuration, controlling Smart Devices, and checking device status.
        Among these functions, we will process data with the “Home“ App that
        comes with the iOS system through HomeKit. Please be aware that even if
        you have agreed to enable the HomeKit permission, we will only use it in
        these scenarios: on the home page, to discover HomeKit devices, HomeKit
        device network configuration; in “Settings – HomeKit” for discovering
        HomeKit devices, HomeKit device network configuration.
      </div>
      <h3>
        Additional services based on HealthKit {`(`}iOS{`)`}:
      </h3>
      <div className="content">
        You can proactively enable related functions after enabling HealthKit
        permission, including statistics on weight, height, running, and
        swimming. In these functions, we will exchange data with the
        health-related functionalities that comes with the iOS system through
        HealthKit. Please be aware that even if you have agreed to enable
        HealthKit permission, we will only use them in these scenarios: when you
        use the health-related Smart Device, such as body fat scales, bracelets,
        watch and consent to use the HealthKit, the data reported by the Smart
        Device will be transferred to HealthKit.
      </div>
      <div className="content">
        Please note that if you agree any permission, you authorize us to
        collect and use relevant personal information to provide you with
        corresponding Services. Once you cancellation any permission, we will no
        longer continue to collect Personal Data based on the corresponding
        permissions, and the related functions may be terminated. However, your
        decision to cancellation the permission will not affect previous
        collection and use of information based on your authorization.
      </div>
      <h2>2{`)`} Information We Collect Automatically</h2>
      <div className="content">
        Mobile Device Information: When you interact with our Services, in order
        to provide and maintain the common operation of our Services, to improve
        and optimize our Services, and to protect your account security as well,
        we automatically collect mobile device information, such as mobile
        device model number, IP address, wireless connection information, the
        type and version of the operating system, application version number,
        push notification identifier, log files, and mobile network information.
        Meanwhile, we will collect your software version number. In order to
        ensure the security of the operating environment or to provide services,
        we will collect information about the installed mobile applications and
        other software you use.
      </div>
      <div className="content">
        Usage Data: During your interaction with our websites and Services, we
        automatically collect usage data relating to visits, clicks, downloads,
        messages sent/received, and other usage of our websites and Services.
      </div>
      <div className="content">
        Log Information: When you use the App, in order to improve your user
        experience, the system and exception log may be uploaded, including your
        IP address, language preference setting, operating system version, date
        or time of access, so that we can facilitate and accurately identify
        problems and help you solve them in timely manner.
      </div>
      <div className="content">
        Please note that one cannot identify a specific individual by using
        device information or log information alone. However, as these types of
        information, combined with other information, may be used to identify a
        specific individual, such information will be treated as Personal Data.
        Unless we have obtained your consent or unless otherwise provided by
        data protection laws and regulations, we will aggregate or desensitize
        such information. Additionally, please
      </div>
      <div className="content">
        Refer to section titled “Data Retention” of this Policy for data
        retention periods of such Personal Data.
      </div>
      <h2>3{`)`} Smart Devices Related Information:</h2>
      <div className="content">
        When you use a Smart Device, we will collect some basic and pre-embedded
        information of the Smart Device and the information generated during
        your use of the Smart Device.
      </div>
      <div className="content">
        Basic Information of Smart Devices: When you connect your Smart Devices
        with the Services, we may collect basic information about your Smart
        Devices such as device name, device ID, online status, activation time,
        firmware version, and upgrade information.{" "}
      </div>
      <div className="content">
        Information collected during the process of connecting to a Smart
        Device: Based on the type of Smart Device you need to connect, whether
        the Smart Device is connected via Wi-Fi, via Wi-Fi after establishing a
        local connection via Bluetooth, we will collect the Mac address of the
        smart device.
      </div>
      <div className="content">
        Information Reported by Smart Devices: Depending on the different Smart
        Devices you elect to connect with the Services, we may collect different
        information reported by your Smart Devices. The following information
        reported by the Smart Device only applies when you use them:
      </div>
      <div className="content">
        When you voluntarily use a smart camera {`(`}IPC service{`)`} and
        connect to the KT Lock platform: When you connect a doorbell camera, a
        door lock, or a smart surveillance camera through KT Lock service to
        monitor the security of your residence, during the process, the device
        may capture the images if it recognizes someone or an object is moving
        around, and then provide you a playback of related surveillance content.
        At this time, we will automatically encrypt the captured image and save
        it locally on the device. When you actively choose and successfully
        purchase cloud storage service, the smart camera will upload the
        pictures or video files you have taken to the cloud for further storage
        so that you can review them in the App. We will keep your information
        based on the cloud storage service you purchase. When you choose to
        delete the picture in advance in the KT Lock App, you can delete it in
        the message center and if you need to delete a video on the App, the
        cloud will delete immediately the video files you stored in the cloud
        service.
      </div>
      <div className="content">Purposes and Legal Basis for Processing Personal Data</div>
      <div className="content">
        The purpose for which we may process information about you are as
        follows:
      </div>
      <div className="content">
        Provide You with Our Services: We process your account data, mobile
        device information, usage data, location information, and Smart Device
        related information to provide you with the Services that you have
        requested. The legal basis for this processing is to perform our
        contract with you according to our User Agreement.
      </div>
      <div className="content">
        Safety of Our Services: We process your mobile device information, usage
        data, location information and Smart Device related information to
        ensure the functions, and authentication, integrity, security and safety
        of accounts, activity, products and the Services, to develop and upgrade
        the Services, to study in-depth the efficiency of our operations and to
        prevent and trace fraudulent or inappropriate usage. The legal basis for
        this processing is as necessary for our {`(`}or others’{`)`} legitimate
        interests.
      </div>
      <div className="content">
        Non-marketing Communication: We process your Personal Data to send you
        important information regarding the Services, changes to our terms,
        conditions, and policies and/or other administrative information. At the
        same time, we will also send you notifications related to the services
        you have purchased, such as alert services. When you decide not to
        enable the Notifications function, we will no longer process your
        information for such purpose. The legal basis for this processing is to
        perform our contract with you according to our User Agreement.
      </div>
      <div className="content">
        Data Analysis: In order to analyze the usage of the products we provide
        and improve your user experience, we may analyze the data you provide
        us, a{`)`} we need to check your problems when you encounter any
        malfunctions during the usage of the product, under such circumstance,
        you may not able to opt-out because it is highly related to
        functionalities and quality of using our product and service, and b{`)`}{" "}
        analyze data about how you interface with the product or under
        particular scenarios so that you can better enjoy the convenience
        brought by our Services. Your consent is required prior to processing
        under such circumstances; The legal basis for such processing is based
        on your consent.
      </div>
      <div className="content">
        Marketing Communication and Personalization: We may process your account
        data, usage data, device information to personalize product design and
        to provide you with services tailored for you, such as recommending and
        displaying information and advertisements regarding products suited to
        you, and to invite you to participate in surveys relating to your use of
        the Services. The legal basis for this processing is your consent. You
        may give consent to such processing by proactively electing to turn on
        the Personalization option in the App, and we will not process your
        Personal Data for such purpose unless your consent has been given to us.
        The legal basis for this processing is your consent. Please note: as of
        the date of latest update of this Policy, we do not use automated
        decision-making in our Services to process your Personal Data.
      </div>
      <div className="content">
        Legal Compliance. We disclose information if we are legally required to
        do so, or if we have a good faith belief that such use is reasonably
        necessary to:
      </div>
      <div className="content">Comply with a legal obligation, process or request;</div>
      <div className="content">
        Enforce our User Agreement and other agreements, policies, and
        standards, including investigation of any potential violation thereof;
      </div>
      <div className="content">
        Protect the rights, property or safety of us, our users, a third party
        or the public as required or permitted by law; or
      </div>
      <div className="content">
        Detect, prevent or otherwise address security, fraud or technical
        issues.
      </div>
      <div className="content">
        If there is any change in the purposes for processing your Personal
        Data, we will inform such change to you with a prominent notice on our
        website of such changes of purposes, and choices you may have regarding
        your Personal Data.
      </div>
      <div className="content">Who do We Share Personal Data with?</div>
      <div className="content">
        At KT Lock, we only share Personal Data in ways that we tell you about.
        Without your consent, we will not disclose your Personal Data to
        third-party companies, organizations, or individuals except in the
        following cases:
      </div>
      <div className="content">
        To our third-party service providers who perform certain
        business-related functions for us, such as website hosting, data
        analysis, payment and credit card processing, infrastructure provision,
        IT services, customer support service, e-mail delivery services, and
        other similar services to enable them to provide services to us.
      </div>
      <div className="content">
        To our customers and other business partners who provide you, directly
        or indirectly, with your Smart Devices, and/or networks and systems
        through which you access and use our websites and Services.
      </div>
      <div className="content">
        To subsidiaries or affiliates within our corporate family for purpose of
        regular business activities based on our instructions and in compliance
        with applicable law, this Policy and other appropriate confidentiality
        and security measures.
      </div>
      <div className="content">
        To an affiliate or other third party in the event of any reorganization,
        merger, sale, joint venture, assignment, transfer or other disposition
        of all or any portion of our business, assets or stock {`(`}including
        without limitation in connection with any bankruptcy or similar
        proceedings{`)`}. In such an event, you will be notified via email
        and/or a prominent notice on our website of any change in ownership, and
        choices you may have regarding your Personal Data.
      </div>
      <div className="content">
        As we believe in good faith that access to, or use, preservation, or
        disclosure of the information is reasonably necessary or appropriate to:
      </div>
      <div className="content">
        {`(`}a{`)`}Comply with applicable law, regulation, legal process, or
        lawful governmental request;
      </div>
      <div className="content">
        {`(`}b{`)`}Enforce our User Agreement and other agreements, policies,
        and standards, including investigation of any potential violation
        thereof;
      </div>
      <div className="content">
        {`(`}c{`)`} Protect our operation and business systems;
      </div>
      <div className="content">
        {`(`}d{`)`}Protect the rights, property or safety of us, our users, a
        third party or the public as required or permitted by law; or
      </div>
      <div className="content">
        {`(`}e{`)`}Perform risk management, screening and checks for unlawful,
        fraudulent, deceptive or malicious activities.
      </div>
      <div className="content">
        Except for the third parties mentioned above, we only disclose your
        Personal Data to other third parties with your consent.
      </div>
      <div className="content">
        When you use services provided by third parties, we will make sure that
        sharing of your Personal Data with such third parties are based on their
        obtaining your authorization and/or consent, or otherwise in conformity
        with applicable laws and regulations. We will also make our efforts to
        manage sharing of any information with these third parties in order to
        preserve the security of your relevant personal information. For details
        on such third parties information sharing, please see “List of
        Third-party Information Sharing”. We encourage you to carefully read the
        privacy policy of any third party with which you interact.{" "}
      </div>
      <div className="content">
        In addition, to ensure operations of the App or to enable certain
        independent functions, we may embed third-party SDKs in the App. Please
        note: the types of Personal Data that these third-party SDKs may process
        are subject to change at any time due to upgrade, updates or policy
        changes, and you should rely on relevant latest information on the
        official websites or otherwise made available by such third parties. For
        details of these SDKs, please see “List of Third-party SDK Services”.{" "}
      </div>
      <h3>Data Transfer</h3>
      <div className="content">
        KT Lock operates globally, and Personal Data may be transferred, stored
        and processed outside of the country or region where it was initially
        collected. Also, the applicable laws in the countries and regions where
        we operate may differ from the laws applicable to your country of
        residence {`(`}please kindly check KT Lock Global Data Center
        accordingly{`)`}. Under the Personal Data protection framework and in
        order to facilitate our operation, we may transfer, store and process
        your Personal Data in jurisdictions other than where you live.
      </div>
      <div className="content">
        We protect Personal Data in accordance with this Policy wherever it is
        processed and take appropriate contractual or other steps to protect it
        under applicable laws.
      </div>
      <div className="content">
        The European Commission has determined that certain countries outside of
        the European Economic Area {`(`}EEA{`)`}, the UK or Switzerland can
        provide adequate protection of Personal Data. Where Personal Data of
        users in the EEA, Switzerland, or the UK is being transferred to a
        recipient located in a country outside the EEA, Switzerland, or the UK
        which has not been recognized as having an adequate level of data
        protection, we ensure that the transfer is governed by the European
        Commission’s standard contractual clauses. You can review the agreement
        on the basis of approved EU standard contractual clauses per GDPR Art.
        46.{" "}
      </div>
      <div className="content">
        If you would like further details on the safeguards we have in place
        under the data transfer, you can contact us
      </div>
      <h3>Directly as described in this Policy.</h3>
      <h3>Data Subject Rights</h3>
      <div className="content">
        We respect your rights and control over your Personal Data. You may
        exercise any of the following rights: By emailing us at
        contact.ktlock@gmail.com
      </div>
      <div className="content">
        You do not have to pay any fee for executing your personal rights.
        Subject to applicable data protection laws in relevant jurisdictions,
        your request of personal rights will be fulfilled within 15 business
        days, or within 30 calendar days due to different response requirement.
      </div>
      <div className="content">
        If you decide to email us, in your request, please make clear what
        information you would like to have changed, whether you would like to
        have your Personal Data deleted from our database or otherwise let us
        know what limitations you would like to put on our use of your Personal
        Data. Please note that we may ask you to verify your identity before
        taking further action on your request, for security purposes.
      </div>
      <h3>You may:</h3>
      <div className="content">
        Request access to the Personal Data that we process about you:
        “My-Setting-Account Settings”;
      </div>
      <div className="content">
        Request that we correct inaccurate or incomplete Personal Data about
        you: 1{`)`} Modify your account number ; 2{`)`} Modify the nickname{" "}
        {`(`}email address or phone number{`)`}: “My-Setting Account”;{" "}
      </div>
      <div className="content">
        Request deletion of Personal Data about you: “My-Setting-Account and
        Security-Delete Account”, when you confirm the deletion of your account,
        your Personal Data will be deleted accordingly.
      </div>
      <div className="content">
        Request transfer of Personal Data to you or a third party where we
        process the data based on your consent or a contract with you, and where
        our processing is automated: Please send over your request the email
        request to contact.ktlock@gmail.com
      </div>
      <div className="content">
        Right of Data Portability: Request to have Personal Data provided to you
        so that you can provide or “port” them to another provider, by sending
        the request via email to contact.ktlock@gmail.com
      </div>
      <h3>Withdrawal of Consent</h3>
      <div className="content">
        You have the right to withdraw or object to our use and processing of
        your Personal Data, where such use and processing is based on your
        consent or our legitimate interests.{" "}
      </div>
      <div className="content">
        If you previously agreed to associate KT Lock account with a third-party
        service, such as a health platform, please unbind it on the third-party
        platform.
      </div>
      <div className="content">
        When you withdraw your consent or authorization, we may not be able to
        continue to provide you with certain products or services
        correspondingly. However, your withdrawal of your consent or
        authorization will not affect the processing of personal information
        based on your consent before the withdrawal.
      </div>
      <div className="content">
        Deletion of the Account: You can find the Delete function through “Me{" "}
        {`>`} Settings {`>`} Account and Security {`>`} Delete Account” {`(`}
        Deactivate Account{`)`}.
      </div>
      <div className="content">
        If you have more questions, please do not hesitate to contact
        contact.ktlock@gmail.com
      </div>
      <h3>Security Measures</h3>
      <div className="content">
        We use commercially reasonable physical, administrative, and technical
        safeguards to preserve the integrity and security of your Personal
        Data.Security Measures
      </div>
      <div className="content">
        We use commercially reasonable physical, administrative, and technical
        safeguards to preserve the integrity and security of your Personal Data.
        KT Lock provides various security strategies to effectively ensure data
        security of user and device.
      </div>
      <div className="content">
        As for device access, KT Lock proprietary algorithms are employed to
        ensure data isolation, access authentication,
      </div>
      <h3>Applying for authorization.</h3>
      <div className="content">
        As for data communication, communication using security algorithms and
        transmission encryption protocols and commercial level information
        encryption transmission based on dynamic keys are supported.
      </div>
      <div className="content">
        As for data processing, strict data filtering and validation and
        complete data audit are applied. As for data storage, all confidential
        information of users will be safely encrypted for storage. If you have
        reason to believe that your interaction with us is no longer secure{" "}
        {`(`}for example, if you feel that the security of any account you might
        have with us has been compromised{`)`}, you could immediately notify us
        of the problem by emailing contact.ktlock@gmail.com
      </div>
      <h3>Data Retention</h3>
      <div className="content">
        We process your Personal Data for the minimum period necessary for the
        purposes set out in this Policy, unless there is a specific legal
        requirement for us to keep the data for a longer retention period. We
        determine the appropriate retention period based on the amount, nature,
        and sensitivity of your Personal Data, and after the retention period
        ends, we will destruct your Personal Data.
      </div>
      <div className="content">
        In detail, the following factors are considered when determining our
        retention periods of Personal Data:
      </div>
      <div className="content">
        The period of time during which an ongoing relationship with you is
        retained and Smart Devices and/or our Services are provided to you {`(`}
        also see the User Agreement{`)`}. For instance, your Personal Data is
        retained for as long as your account with us remains valid or you keep
        using the Smart Devices and/or our Services;
      </div>
      <div className="content">
        Whether we have a legal obligation to keep your Personal Data; or
      </div>
      <div className="content">
        Whether retention is advisable in light of our legal position {`(`}such
        as in regard to the enforcement of our agreements, the resolution of
        disputes, and applicable statutes of limitations, litigation, or
        regulatory investigation{`)`}.
      </div>
      <div className="content">
        If the legal basis is your consent, we will delete your data immediately
        after you withdraw your consent.{" "}
      </div>
      <div className="content">
        If the legal basis is our legitimate interest, we will delete your data
        as quickly as possible if there are no overriding legitimate grounds for
        processing, but in any case in the event of direct advertising. Personal
        Data will no longer be retained when you request to remove your Personal
        Data or withdraw your consent, and we will accordingly complete the
        task.
      </div>
      <div className="content">
        When we are unable to do so for technical reasons, we will ensure that
        appropriate measures are put in place to prevent any further such use of
        your Personal Data.
      </div>
      <h3>Children’s Privacy</h3>
      <div className="content">
        Protecting the privacy of young children is especially important to us.
        The Services are not directed to individuals under the age of sixteen{" "}
        {`(`}16{`)`} {`(`}or such other age provided by applicable law in your
        country/region of residence{`)`}, and we request that these individuals
        do not provide any Personal Data to us. We do not knowingly collect
        Personal Data from any child unless we first obtain permission from that
        child’s parent or legal guardian. If we become aware that we have
        collected Personal Data from any child without permission from that
        child’s parent or legal guardian, we will take steps to remove that
        information.
      </div>
      <h3>Your California Privacy Rights</h3>
      <div className="content">
        California Civil Code Section 1798.83 permits users of the Software that
        are California residents to request certain information regarding our
        disclosure of Personal Data to third parties for their direct marketing
        purposes. To make such a request, please contact us in accordance with
        the “Contact Us” section below. We do not disclose Personal Data to
        third parties for their direct marketing purposes without your consent.
        Visit our Statement on California Privacy Notice page for more
        information.
      </div>
      <h3>Changes to this Policy</h3>
      <div className="content">
        We may update this Policy to reflect changes to our information
        practices, at least on an annual basis. If we make any material changes
        we will notify you by email {`(`}send to the e-mail address specified in
        your account{`)`} or by means of a notice in the mobile applications
        prior to the change becoming effective. We encourage you to periodically
        review this page for the latest information on our privacy practices.
      </div>
      <h3>Contact Us</h3>
      <div className="content">
        If you have any questions about our practices or this Policy, please
        contact us as follows:
      </div>
      <div className="content">
        VKEN.,JSC {`(`} KT Lock {`)`}
      </div>
      <div className="content">
        Address: No. 22A Alley 62/24 Van Phu Street, Group 2, Phu La Ward, Ha
        Dong District, Hanoi City, Vietnam
      </div>
      <div className="content">Email: contact.ktlock@gmail.com</div>
    </>
  );
};

export default PrivacyPolicy;
