import React, { ReactNode } from "react";
import { Navigate, Outlet, Route, To } from "react-router-dom";
import { useAppSelector } from "src/services/redux/ReduxStore";

const TokenFilter = (props: {
  allowActivated?: boolean;
  allowUnactivated?: boolean;
  allowNull?: boolean;
  to: To;
  replace?: boolean | undefined;
}): JSX.Element => {
  const { allowActivated, allowUnactivated, allowNull, to, replace } = props;
  const tokenActivationState = useAppSelector((state) => state.token)?.payload
    .isAccountActivated;

  if (
    (tokenActivationState == true && allowActivated == true) ||
    (tokenActivationState == false && allowUnactivated == true) ||
    (tokenActivationState == null && allowNull == true)
  ) {
    return <Outlet />;
  }

  return <Navigate to={to} replace={replace ?? true} />;
};

export default TokenFilter;
