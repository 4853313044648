import { Button, rem, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconAlertTriangleFilled } from "@tabler/icons-react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { toastCommonError } from "src/pages/utils/ToastUtils";
import authService from "src/services/core/auth/Auth.service";
import { reduxStore, useAppSelector } from "src/services/redux/ReduxStore";

const DeleteAccount = (): JSX.Element => {
  const tokenPayload = useAppSelector((s) => s.token)?.payload;
  const [deleteInput, setDeleteInput] = useState("");
  const { t } = useTranslation(undefined, {
    keyPrefix: "nav.headerRoot.deleteAccountPage",
  });

  if (tokenPayload == null) {
    return <></>;
  }

  const deleteAccount = useCallback(async () => {
    const result = await authService.deleteAccount();
    if (result.success) {
      toast.info("Account deleted successfully", {
        autoClose: 7000,
      });
      return;
    }

    toastCommonError(result.code);
  }, [t]);

  return (
    <div>
      <div className="flex ">
        <div className="flex items-center justify-center">
          <IconAlertTriangleFilled
            size={70}
            color="var(--mantine-color-red-filled)"
            stroke={1.5}
          />
        </div>
        <div className="ml-5">
          <div>{t("warning")}</div>
          <div>{t("irreversible")}</div>
        </div>
      </div>
      <div className="mt-5" onCopy={(e) => e.preventDefault()}>
        {t("enterEmail1")}
        <strong>{tokenPayload.email}</strong>
        {t("enterEmail2")}
      </div>

      <TextInput
        onPaste={(e) => e.preventDefault()}
        autoCapitalize="off"
        autoComplete="off"
        mt="md"
        onChange={(event) => setDeleteInput(event.currentTarget.value)}
      />
      <div className="flex justify-end">
        <Button
          mt="xl"
          type="submit"
          color="red"
          onClick={deleteAccount}
          disabled={deleteInput != "Delete " + tokenPayload.email + " account"}
        >
          {t("deleteAccount")}
        </Button>
      </div>
    </div>
  );
};

export default DeleteAccount;
