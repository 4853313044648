import {
  Button,
  Checkbox,
  Container,
  InputLabel,
  NumberInput,
  Paper,
  Switch,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { isAction } from "@reduxjs/toolkit";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DeviceDropDown from "src/pages/components/DeviceDropDown";
import {
  nullToastUpdateOptions,
  toastCommonError,
  toastDeviceResponseReport,
} from "src/pages/utils/ToastUtils";
import deviceService, {
  getActualPassword,
  getDisplayPassword,
  getDisplayPasswordNumber,
} from "src/services/core/device/Device.service";
import Device, {
  getDeviceDisplayName,
} from "src/services/core/device/models/Device";
import managedUserService, {
  getSmallestUnusedNumber,
} from "src/services/core/managed-user/ManagedUser.service";
import ManagedUser from "src/services/core/managed-user/models/ManagedUser";
import { AddManagedUserDto } from "src/services/core/managed-user/models/ManagedUserDto";

type FormType = Omit<AddManagedUserDto, "password" | "cardNumber"> & {
  password: string | null;
  cardNumber: string | null;
};

const AddManagedUser: (props: {
  onSuccess?: (dto: AddManagedUserDto) => void;
  snList?: string[];
  setSnList?: Dispatch<SetStateAction<string[]>>;
  deviceList?: Device[];
  managedUser?: Partial<ManagedUser>;
  groupId?: number;
  existingIdList: number[];
}) => JSX.Element = (props) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "inApp.managedUserList.addManagedUserPage",
  });

  const form = useForm<FormType>({
    initialValues: {
      deviceId:
        props.managedUser?.deviceId ??
        getSmallestUnusedNumber(props.existingIdList),
      internalId: props.managedUser?.internalId ?? "",
      name: props.managedUser?.name ?? ("" as string),
      imageUrl: props.managedUser?.imageUrl ?? "",
      isAdmin: props.managedUser?.isAdmin ?? false,
      password: getDisplayPassword(props.managedUser?.password ?? null),
      cardNumber:
        props.managedUser?.cardNumber == 0
          ? null
          : props.managedUser?.cardNumber?.toString() ?? null,
    },
  });
  type InputProps = keyof FormType;

  const formSubmit = useCallback(
    async (values: typeof form.values, deviceSnList: string[]) => {
      const dto = {
        ...values,
        password: getActualPassword(values.password) ?? 0xffff_ffff,
        cardNumber:
          values.cardNumber == null || values.cardNumber == ""
            ? 0
            : parseInt(values.cardNumber.toString()),
      };
      const addPromise = managedUserService.addManagedUsers(
        [dto],
        deviceSnList,
        props.groupId
      );
      const addToastId = toast.loading(t("adding"), {
        closeButton: true,
        closeOnClick: true,
      });
      const result = await addPromise;

      if (!result.success) {
        if (result.code == "id-conflict") {
          form.setFieldError("deviceId", t("deviceIdConflict"));
          toast.update(addToastId, {
            ...nullToastUpdateOptions,
            autoClose: 7000,
            render: t("deviceIdConflictToast"),
            type: "error",
          });
          return;
        }
        toastCommonError(result.code, addToastId);
        return;
      }

      toast.update(addToastId, {
        ...nullToastUpdateOptions,
        render: t("addSuccess"),
        type: "success",
      });
      toastDeviceResponseReport(
        props.deviceList ?? [],
        result.value.deviceResponseReport
      );
      props.onSuccess?.(dto);
    },
    [props.onSuccess]
  );

  return (
    <Container className="p-10 pt-0">
      <Title ta="center">{t("title")}</Title>

      <Paper p={10} mt={10} radius="md">
        <form
          onSubmit={form.onSubmit((values) =>
            formSubmit(values, props.snList ?? [])
          )}
        >
          <NumberInput
            min={1}
            label={t("deviceIdLabel")}
            required
            mt="md"
            {...form.getInputProps<InputProps>("deviceId")}
          />
          <TextInput
            label={t("nameLabel")}
            required
            mt="md"
            {...form.getInputProps<InputProps>("name")}
          />
          <TextInput
            label={t("internalIdLabel")}
            description={t("internalIdDescription")}
            placeholder={t("internalIdPlaceholder")}
            mt="md"
            {...form.getInputProps<InputProps>("internalId")}
          />
          {/* <TextInput
            label="Image url"
            placeholder=""
            mt="md"
            {...form.getInputProps<InputProps>("imageUrl")}
          /> */}
          <Switch
            mt="lg"
            label={t("isAdminLabel")}
            {...form.getInputProps<InputProps>("isAdmin", { type: "checkbox" })}
          />
          <NumberInput
            allowLeadingZeros
            isAllowed={(values) => {
              return values.value.length <= 8;
            }}
            label={
              <span>
                {t("passwordLablel")}{" "}
                <span className="text-[#999999]">{t("blankIfNoPassword")}</span>
              </span>
            }
            placeholder="XXXXXXXX"
            mt="md"
            allowDecimal={false}
            min={0}
            max={99999999}
            {...form.getInputProps<InputProps>("password")}
            onChange={undefined}
            onValueChange={(values) => {
              form.values.password = values.value;
            }}
          />
          <NumberInput
            allowLeadingZeros
            label={
              <span>
                {t("cardNumberLablel")}{" "}
                <span className="text-[#999999]">
                  {t("blankIfNoCardNumber")}
                </span>
              </span>
            }
            placeholder="XXXXXXXXXXXXXXXX"
            mt="md"
            allowDecimal={false}
            min={0}
            max={99999999}
            {...form.getInputProps<InputProps>("cardNumber")}
            onChange={undefined}
            onValueChange={(values) => {
              form.values.cardNumber = values.value;
            }}
          />
          {props.deviceList != null &&
            props.snList != null &&
            props.setSnList != null && (
              <>
                <InputLabel className="mt-4">{t("addToDevice")}</InputLabel>
                <DeviceDropDown
                  className="mt-[1px]"
                  selected={props.snList}
                  setSelected={props.setSnList}
                  deviceList={props.deviceList ?? []}
                  displayCount
                  comboboxProps={{}}
                />
              </>
            )}
          <Button fullWidth mt="xl" type="submit">
            {t("add")}
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default AddManagedUser;
