import FailableResponse, {
  FailedResponse,
  createFailedResponse,
  createSuccessResponse,
} from "src/repositories/model/FailableResponse";
import { reduxStore } from "../../redux/ReduxStore";
import { managedUserApi } from "src/repositories/api/ManagedUser.api";
import { GeneralApiResponse } from "src/repositories/api/Utils/ResponseUtility";
import groupService from "../group/Group.service";
import ManagedUser from "./models/ManagedUser";
import {
  AddManagedUserDto,
  AddManagedUserOutput,
  PatchManagedUserDto,
  PatchManagedUserOutput,
  UpdateManagedUserDto,
} from "./models/ManagedUserDto";
import { DeviceResponseReport } from "../device/models/DeviceResponseReport";
import { handleGeneralErrorResponse } from "src/services/utils/Utils.service";
import {
  activeManagedUserSlice,
  inactiveManagedUserSlice,
} from "src/services/redux/slices/ManagedUserListSlice";
import ManagedUserOnDevice from "../device/models/ManagedUserOnDevice";

async function loadManagedUserListInitial(): Promise<
  GeneralApiResponse<ManagedUser[]> | FailedResponse<"no-selected-groups">
> {
  const groupResult = await groupService.getSelectedGroup();
  if (!groupResult.success) {
    return groupResult;
  }

  if (groupResult.value == null) {
    return createFailedResponse("no-selected-groups");
  }

  const result = await managedUserApi.getManagedUsers(groupResult.value.id);
  if (result.success) {
    reduxStore.dispatch(activeManagedUserSlice.actions.update(result.value));
  } else {
    handleGeneralErrorResponse(result);
  }
  return result;
}

/**
 *
 * @param checkCache whether to check the managedUserList cache in redux before retrieve from server
 * @param updateCache whether to update the managedUserList cache in redux with the value from the server
 * @returns
 */
async function getManagedUserList(
  groupId: number,
  checkCache: boolean = true,
  updateCache: boolean = true,
  activeState: boolean | null = true
): Promise<GeneralApiResponse<ManagedUser[]>> {
  // both
  if (activeState == null) {
    const activeResult = await getManagedUserList(
      groupId,
      checkCache,
      updateCache,
      true
    );
    const inactiveResult = await getManagedUserList(
      groupId,
      checkCache,
      updateCache,
      false
    );
    if (activeResult.success && inactiveResult.success) {
      return createSuccessResponse(
        activeResult.value.concat(inactiveResult.value)
      );
    }

    return activeResult.success ? inactiveResult : activeResult;
  }

  // either
  let managedUserList: ManagedUser[] | null = null;

  if (checkCache) {
    managedUserList =
      activeState == true
        ? reduxStore.getState().activeManagedUserList
        : reduxStore.getState().inactiveManagedUserList;
  }

  if (managedUserList == null) {
    const result = await managedUserApi.getManagedUsers(groupId, activeState);

    if (!result.success) {
      handleGeneralErrorResponse(result);
      return result;
    }

    managedUserList = result.value;
    if (updateCache) {
      reduxStore.dispatch(
        activeState
          ? activeManagedUserSlice.actions.update(managedUserList)
          : inactiveManagedUserSlice.actions.update(managedUserList)
      );
    }
  }

  return createSuccessResponse(managedUserList);
}

async function deleteManagedUsers(
  managedUserIdList: number[],
  deviceSnList: string[]
): Promise<
  | GeneralApiResponse<DeviceResponseReport | null>
  | FailedResponse<"managed-users-not-found", number[]>
> {
  const result = await managedUserApi.deleteManagedUsers(
    managedUserIdList,
    deviceSnList
  );
  if (result.success) {
    reduxStore.dispatch(
      activeManagedUserSlice.actions.remove(managedUserIdList)
    );
  } else {
    handleGeneralErrorResponse(result);
  }
  return result;
}

async function addManagedUsers(
  managedUserList: AddManagedUserDto[],
  deviceSnList: string[],
  groupId?: number
): Promise<
  | GeneralApiResponse<AddManagedUserOutput>
  | FailedResponse<"no-groups-selected">
  | FailedResponse<"devices-not-connected", string[]>
  | FailedResponse<"id-conflict", number[]>
> {
  if (groupId == null) {
    const selectedGroupResult = await groupService.getSelectedGroup();

    if (!selectedGroupResult.success) {
      handleGeneralErrorResponse(selectedGroupResult);
      return selectedGroupResult;
    }

    if (selectedGroupResult.value == null) {
      return createFailedResponse("no-groups-selected");
    }
    groupId = selectedGroupResult.value.id;
  }

  const result = await managedUserApi.addManagedUsers(
    groupId,
    managedUserList,
    deviceSnList
  );
  if (result.success) {
    reduxStore.dispatch(
      activeManagedUserSlice.actions.add(result.value.managedUserList)
    );
  } else {
    handleGeneralErrorResponse(result);
  }
  return result;
}

async function patchManagedUser(
  managedUserDto: PatchManagedUserDto,
  deviceSnList: string[]
): Promise<
  | GeneralApiResponse<PatchManagedUserOutput>
  | FailedResponse<"devices-not-connected", string[]>
  | FailedResponse<"id-conflict", number[]>
> {
  const result = await managedUserApi.patchManagedUser(
    managedUserDto,
    deviceSnList
  );
  if (result.success) {
    reduxStore.dispatch(
      activeManagedUserSlice.actions.updateItem(result.value.managedUser)
    );
  } else {
    handleGeneralErrorResponse(result);
  }
  return result;
}

async function addUpdateManagedUser(
  groupId: number,
  added: AddManagedUserDto[],
  updated: UpdateManagedUserDto[]
): Promise<GeneralApiResponse> {
  const result = await managedUserApi.addUpdateManagedUser(
    groupId,
    added,
    updated
  );
  if (!result.success) {
    handleGeneralErrorResponse(result);
  }
  return result;
}

const managedUserService = {
  loadManagedUserListInitial,
  getManagedUserList,
  deleteManagedUsers,
  addManagedUsers,
  patchManagedUser,
  addUpdateManagedUser,
};

export function getSmallestUnusedNumber(
  list: number[],
  startingPoint: number = 1
): number {
  list.sort((a, b) => a - b);
  let lowest = startingPoint;
  for (const n of list) {
    if (n > lowest) {
      return lowest;
    }
    if (n == lowest) {
      lowest++;
    }
  }
  return lowest;
}

export default managedUserService;
