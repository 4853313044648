import { createSlice } from "@reduxjs/toolkit";

export const selectedGroupSlice = createSlice({
  name: "group",
  initialState: null as Group | null,
  reducers: {
    update: (_list, action: { payload: Group | null }) => action.payload,
  },
});

// add and remove doesn't work if the list is null
export const groupListSlice = createSlice({
  name: "group-list",
  initialState: null as Group[] | null,
  reducers: {
    update: (_list, action: { payload: Group[] | null }) => action.payload,
    add: (list, action: { payload: Group[] }) => {
      if (list == null) {
        return;
      }
      list.push(...action.payload);
    },
    remove: (list, action: { payload: number[] }) => {
      if (list == null) {
        return;
      }
      return list.filter(
        (group) => action.payload.find((id) => group.id == id) == null
      );
    },
    updateItem: (list, action: { payload: Group }) => {
      if (list == null) {
        return;
      }
      const newGroup = action.payload;
      const index = list.findIndex((g) => g.id == newGroup.id);
      if (index == null) {
        return;
      }

      list[index] = newGroup;
    },
  },
});

export const { update, add, remove } = groupListSlice.actions;
