import { createSlice } from "@reduxjs/toolkit";
import UserInfo from "../../../repositories/model/UserInfo";
import TokenInfo from "src/repositories/api/auth/model/TokenInfo";

const tokenSlice = createSlice({
  name: "user",
  initialState: null as TokenInfo | null,
  reducers: {
    update: (_userInfo, action: { payload: TokenInfo | null }) =>
      action.payload,
  },
});

export default tokenSlice;
