import { AxiosInstance } from "axios";

const initApi = () => {
  // globalAxiosAction.add(addLogInterceptor);
};

const actionList: ((axios: AxiosInstance) => void)[] = [];

const axisoInstance: AxiosInstance[] = [];

const add = (action: (axios: AxiosInstance) => void) => {
  actionList.push(action);
  axisoInstance.forEach((i) => action(i));
};

const subscribe = (axios: AxiosInstance) => {
  actionList.forEach((a) => a(axios));
  axisoInstance.push(axios);
};

export const globalAxiosAction = {
  add,
  subscribe,
};

export default initApi;