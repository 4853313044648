import { jwtDecode, JwtPayload } from "jwt-decode";
import TokenPayload, { readPayload } from "./TokenPayload";

type TokenInfo = {
  payload: TokenPayload;
  token: string;
};

export function readTokenInfo(token: string) {
  return {
    payload: readPayload(token),
    token: token,
  };
}

export default TokenInfo;
