import { ScrollArea } from "@mantine/core";
import {
  IconCalculatorFilled,
  IconUsersGroup,
  IconHomeFilled,
  IconFileStack,
} from "@tabler/icons-react";
import LinkGroup, { LinksGroupProps } from "./LinksGroup/LinkGroup";
import { Outlet, useLocation } from "react-router-dom";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { inAppPaths } from "src/App";

type Props = { onItemClick?: () => void };

function SideBar({ onItemClick }: Props) {
  const location = useLocation();
  const { t } = useTranslation(undefined, {
    keyPrefix: "nav.sideBar",
  });

  const links = useMemo((): LinksGroupProps[] => {
    const links: LinksGroupProps[] = [
      {
        label: t("tabs.devices"),
        icon: IconCalculatorFilled,
        path: inAppPaths.devices,
      },
      {
        label: t("tabs.members"),
        icon: IconUsersGroup,
        path: inAppPaths.members,
      },
      { label: t("tabs.groups"), icon: IconHomeFilled, path: inAppPaths.groups },
      {
        label: t("tabs.logs.label"),
        icon: IconFileStack,
        path: [
          { label: t("tabs.logs.sub.activity"), path: inAppPaths.logs.activity },
          { label: t("tabs.logs.sub.sensor"), path: inAppPaths.logs.sensor },
          { label: t("tabs.logs.sub.system"), path: inAppPaths.logs.system },
        ],
      },
    ];
    links.forEach((i) => {
      if (Array.isArray(i.path)) {
        i.path.forEach(
          (si) => (si.isSelected = location.pathname.startsWith(si.path))
        );
      } else if (i.path != null) {
        i.isSelected = location.pathname.startsWith(i.path);
      }
    });
    return links;
  }, [t, location]);

  const linkItems = links.map((item) => (
    <LinkGroup {...item} key={item.label} onClick={onItemClick} />
  ));

  // return <Outlet/>;

  return (
    <nav className="h-full w-60 pb-0 flex flex-shrink-0 flex-col border-r-2 bg-white">
      <ScrollArea className="flex-1">
        <div>{linkItems}</div>
      </ScrollArea>
    </nav>
  );
}

export default memo(SideBar);
