import { List } from "@mantine/core";
import { toast } from "react-toastify";
import initService from "src/services/core/Init.service";
import { reduxStore } from "src/services/redux/ReduxStore";
import tokenUnauthorizedSlice from "src/services/redux/slices/miscellaneous/TokenUnauthorizedSlice";

function initUserInterface() {
  initService();
  reduxStore.subscribe(listen);
}

function listen() {
  const tokenUnauthorized = reduxStore.getState().tokenUnauthorizedSlice;
  if (tokenUnauthorized) {
    toast.info("Your session has expired, please login again.", {
      autoClose: 7000,
    });
    reduxStore.dispatch(tokenUnauthorizedSlice.actions.update(false));
  }
}

export default initUserInterface;
