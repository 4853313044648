import {
  Button,
  Checkbox,
  Container,
  InputLabel,
  NumberInput,
  Paper,
  Switch,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { isAction } from "@reduxjs/toolkit";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DeviceDropDown from "src/pages/components/DeviceDropDown";
import {
  nullToastUpdateOptions,
  toastCommonError,
  toastDeviceResponseReport,
} from "src/pages/utils/ToastUtils";
import { AddUserToDeviceDto as AddManagedUserToDeviceDto } from "src/repositories/api/physical-device/dto/AddUserToDeviceDto";
import deviceService, {
  getActualPassword,
  getDisplayPassword,
  getDisplayPasswordNumber,
} from "src/services/core/device/Device.service";
import Device, {
  getDeviceDisplayName,
} from "src/services/core/device/models/Device";
import physicalDeviceService from "src/services/core/device/PhysicalDevice.service";
import managedUserService from "src/services/core/managed-user/ManagedUser.service";
import { isString } from "src/utils/Utils";

type FormType = Omit<
  AddManagedUserToDeviceDto,
  "password" | "cardNumber" | "deviceId"
> & {
  password: string | null;
  cardNumber: string | null;
};

export default function AddManagedUserToDevice(props: {
  dto: AddManagedUserToDeviceDto;
  device: Device;
  onSuccess?: (dto: AddManagedUserToDeviceDto) => void;
}): JSX.Element {
  const { t } = useTranslation(undefined, {
    keyPrefix: "inApp.deviceDetail.addManagedUserToDevice",
  });

  const form = useForm<FormType>({
    initialValues: {
      ...props.dto,
      password: getDisplayPassword(props.dto.password),
      cardNumber:
        props.dto.cardNumber == 0 ? null : props.dto.cardNumber.toString(),
    },
  });

  type InputProps = keyof FormType;

  const formSubmit = useCallback(
    async (values: typeof form.values) => {
      const dto = {
        ...values,
        idOnDevice: props.dto.idOnDevice,
        password: getActualPassword(values.password) ?? 0xffff_ffff,
        cardNumber:
          values.cardNumber == null || values.cardNumber == ""
            ? 0
            : parseInt(values.cardNumber),
      };

      const addPromise = physicalDeviceService.addUsersToDevice(
        props.device.serialNumber,
        [dto]
      );
      const addToastId = toast.loading(t("adding"), {
        closeButton: true,
        closeOnClick: true,
      });
      const result = await addPromise;

      if (!result.success) {
        toastCommonError(result.code, addToastId);
        return;
      }

      if (result.value?.okSnList?.length == 1) {
        toast.update(addToastId, {
          ...nullToastUpdateOptions,
          render: t("addSuccess"),
          type: "success",
        });
        props.onSuccess?.(dto);
      } else {
        toastDeviceResponseReport([props.device], result.value);
      }
    },
    [props.onSuccess, props.device]
  );

  return (
    <Container className="p-10 pt-0">
      <Title ta="center">
        {t("title", { name: getDeviceDisplayName(props.device) })}
      </Title>

      <Paper p={10} mt={10} radius="md">
        <form onSubmit={form.onSubmit((values) => formSubmit(values))}>
          <TextInput
            label={t("idLabel")}
            classNames={{
              input: "!text-black",
            }}
            mt="md"
            disabled
            value={props.dto.idOnDevice}
          />
          <TextInput
            label={t("nameLabel")}
            required
            mt="md"
            {...form.getInputProps<InputProps>("name")}
          />
          {/* <TextInput
              label="Image url"
              placeholder=""
              mt="md"
              {...form.getInputProps<InputProps>("imageUrl")}
            /> */}
          <Switch
            mt="lg"
            label={t("isAdminLabel")}
            {...form.getInputProps<InputProps>("isAdmin", { type: "checkbox" })}
          />
          <NumberInput
            allowLeadingZeros
            isAllowed={(values) => {
              return values.value.length <= 8;
            }}
            label={
              <span>
                {t("passwordLablel")}{" "}
                <span className="text-[#999999]">{t("blankIfNoPassword")}</span>
              </span>
            }
            placeholder="XXXXXXXX"
            mt="md"
            allowDecimal={false}
            min={0}
            max={99999999}
            {...form.getInputProps<InputProps>("password")}
            onChange={undefined}
            onValueChange={(values) => {
              form.values.password = values.value;
            }}
          />
          <NumberInput
            allowLeadingZeros
            label={
              <span>
                {t("cardNumberLablel")}{" "}
                <span className="text-[#999999]">
                  {t("blankIfNoCardNumber")}
                </span>
              </span>
            }
            placeholder="XXXXXXXXXXXXXXXX"
            mt="md"
            allowDecimal={false}
            min={0}
            {...form.getInputProps<InputProps>("cardNumber")}
            onChange={undefined}
            onValueChange={(values) => {
              form.values.cardNumber = values.value;
            }}
          />
          <Button fullWidth mt="xl" type="submit">
            {t("add")}
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
