import {
  Button,
  Container,
  InputLabel,
  NumberInput,
  Paper,
  Switch,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconCheck, IconX } from "@tabler/icons-react";
import React, { Dispatch, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DeviceDropDown from "src/pages/components/DeviceDropDown";
import {
  nullToastUpdateOptions,
  toastCommonError,
  toastDeviceResponseReport,
} from "src/pages/utils/ToastUtils";
import {
  getActualPassword,
  getDisplayPassword,
} from "src/services/core/device/Device.service";
import Device from "src/services/core/device/models/Device";
import managedUserService from "src/services/core/managed-user/ManagedUser.service";
import ManagedUser from "src/services/core/managed-user/models/ManagedUser";
import { PatchManagedUserDto } from "src/services/core/managed-user/models/ManagedUserDto";

type FormType = Omit<PatchManagedUserDto, "password" | "cardNumber"> & {
  password: string | null;
  cardNumber: string | null;
};

const EditManagedUser: (props: {
  managedUser: ManagedUser;
  onSuccess?: (dto: PatchManagedUserDto) => void;
  snList?: string[];
  setSnList?: Dispatch<SetStateAction<string[]>>;
  deviceList: Device[];
  addOnDevice?: boolean;
}) => JSX.Element = (props) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "inApp.managedUserList.editManagedUserPage",
  });

  const form = useForm<FormType>({
    initialValues: {
      id: props.managedUser.id,
      deviceId: props.managedUser.deviceId,
      name: props.managedUser.name,
      imageUrl: props.managedUser.imageUrl,
      internalId: props.managedUser.internalId,
      password: getDisplayPassword(props.managedUser.password),
      isAdmin: props.managedUser.isAdmin,
      cardNumber:
        props.managedUser.cardNumber == 0
          ? ""
          : props.managedUser.cardNumber.toString(),
      // isAdmin: false,
    },
  });

  type InputProps = keyof PatchManagedUserDto;

  const formSubmit = useCallback(
    async (values: typeof form.values, deviceSnList: string[]) => {
      const dto = {
        ...values,
        password: getActualPassword(values.password) ?? 0xffff_ffff,
        cardNumber:
          values.cardNumber == null || values.cardNumber == ""
            ? 0
            : parseInt(values.cardNumber),
      };
      const editPromise = managedUserService.patchManagedUser(
        dto,
        deviceSnList
      );
      const editToastId = toast.loading(t("editing"), {
        closeButton: true,
        closeOnClick: true,
      });
      const result = await editPromise;

      if (!result.success) {
        if (result.code == "id-conflict") {
          form.setFieldError("deviceId", t("deviceIdConflict"));
          toast.update(editToastId, {
            ...nullToastUpdateOptions,
            autoClose: 7000,
            render: t("deviceIdConflictToast"),
            type: "error",
          });
          return;
        }
        toastCommonError(result.code, editToastId);
        return;
      }

      toast.update(editToastId, {
        ...nullToastUpdateOptions,
        render: t("editSuccess"),
        type: "success",
      });
      toastDeviceResponseReport(
        props.deviceList,
        result.value.deviceResponseReport
      );
      props.onSuccess?.(dto);
    },
    [props.onSuccess]
  );

  return (
    <Container className="p-10 pt-0">
      <Title ta="center">{t("title")}</Title>

      <Paper p={10} mt={10} radius="md">
        <form
          onSubmit={form.onSubmit((values) =>
            formSubmit(values, props.snList ?? [])
          )}
        >
          <NumberInput
            min={1}
            label={t("deviceIdLabel")}
            required
            mt="md"
            {...form.getInputProps<InputProps>("deviceId")}
          />
          <TextInput
            label={t("nameLabel")}
            required
            mt="md"
            {...form.getInputProps<InputProps>("name")}
          />
          <TextInput
            label={t("internalIdLabel")}
            description={t("internalIdDescription")}
            placeholder={t("internalIdPlaceholder")}
            mt="md"
            {...form.getInputProps<InputProps>("internalId")}
          />
          {/* <TextInput
            label="Image url"
            placeholder=""
            mt="md"
            {...form.getInputProps<InputProps>("imageUrl")}
          /> */}
          <Switch
            mt="lg"
            thumbIcon={
              form.values.isAdmin == true ? (
                <IconCheck
                  style={{ width: 12, height: 12 }}
                  color="var(--mantine-color-blue-7)"
                  stroke={3}
                />
              ) : (
                <IconX
                  style={{ width: 12, height: 12 }}
                  color="var(--mantine-color-gray-3)"
                  stroke={3}
                />
              )
            }
            size="sm"
            label={<span className="font-medium">{t("isAdminLabel")}</span>}
            {...form.getInputProps<InputProps>("isAdmin", { type: "checkbox" })}
          />
          <NumberInput
            allowLeadingZeros
            isAllowed={(values) => {
              return values.value.length <= 8;
            }}
            label={
              <span>
                {t("passwordLablel")}{" "}
                <span className="text-[#999999]">{t("blankIfNoPassword")}</span>
              </span>
            }
            placeholder="XXXXXXXX"
            mt="md"
            allowDecimal={false}
            min={0}
            max={99999999}
            {...form.getInputProps<InputProps>("password")}
            onChange={undefined}
            onValueChange={(values) => {
              form.values.password = values.value;
            }}
          />
          <NumberInput
            allowLeadingZeros
            label={
              <span>
                {t("cardNumberLablel")}{" "}
                <span className="text-[#999999]">
                  {t("blankIfNoCardNumber")}
                </span>
              </span>
            }
            placeholder="XXXXXXXXXXXXXXXX"
            mt="md"
            allowDecimal={false}
            min={0}
            max={99999999}
            {...form.getInputProps<InputProps>("cardNumber")}
            onChange={undefined}
            onValueChange={(values) => {
              form.values.cardNumber = values.value;
            }}
          />
          {props.deviceList != null &&
            props.snList != null &&
            props.setSnList != null && (
              <>
                <InputLabel className="mt-4">{t("addToDevice")}</InputLabel>
                <DeviceDropDown
                  className="mt-[1px]"
                  selected={props.snList}
                  setSelected={props.setSnList}
                  deviceList={props.deviceList ?? []}
                  displayCount
                  comboboxProps={{}}
                />
              </>
            )}
          <Button fullWidth mt="xl" type="submit">
            {t("edit")}
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default EditManagedUser;
