type Device = {
  id: number;
  serialNumber: string;
  password: number | null;
  name: string;
  groupId: number;
  isOnline?: boolean;
};

export const getDeviceDisplayName = (device: Device) =>
  device.name != "" ? device.name : device.serialNumber;

export default Device;
