import {
  ActionIcon,
  Button,
  Divider,
  Modal,
  Pagination,
  ScrollArea,
  Table,
} from "@mantine/core";
import { useDisclosure, useListState } from "@mantine/hooks";
import {
  IconArrowLeft,
  IconArrowRight,
  IconCalculator,
  IconCloudUpload,
  IconDownload,
  IconServer2,
  IconUserPlus,
  IconX,
} from "@tabler/icons-react";
import classNames from "classnames";
import {
  Dispatch,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  nullToastUpdateOptions,
  toastCommonError,
  toastDeviceResponseReport,
} from "src/pages/utils/ToastUtils";
import { getDisplayPassword } from "src/services/core/device/Device.service";
import Device, {
  getDeviceDisplayName,
} from "src/services/core/device/models/Device";
import CombinedManagedUser from "src/services/core/device/models/dto/CombinedManagedUser";
import physicalDeviceService, {
  getNameOnDevice,
} from "src/services/core/device/PhysicalDevice.service";
import AddManagedUserToDevice from "./AddManagedUserToDevice";
import AddManagedUser from "src/pages/in-app/managed-user-list/AddManagedUser";
import PatchManagedUser from "src/pages/in-app/managed-user-list/PatchManagedUser";
import { reduxStore, useAppSelector } from "src/services/redux/ReduxStore";
import ManagedUserOnDevice from "src/services/core/device/models/ManagedUserOnDevice";
import managedUserService from "src/services/core/managed-user/ManagedUser.service";
import {
  AddManagedUserDto,
  UpdateManagedUserDto,
} from "src/services/core/managed-user/models/ManagedUserDto";
import Popup from "src/pages/components/Popup";
import useIsMobile from "src/pages/utils/isMobileHook";
import { activeManagedUserSlice } from "src/services/redux/slices/ManagedUserListSlice";

const className = "!border-[var(--mantine-color-gray-5)]";
const headerClassName = classNames(
  "bg-[var(--mantine-color-gray-1)]",
  className
);
const secondRowHeaderClassName = classNames(
  headerClassName,
  "sticky top-[0px]"
);
const pageSize = 10;

type Props = {
  device: Device;
};

export function CombinedTable({ device }: Props): JSX.Element {
  const { t } = useTranslation(undefined, {
    keyPrefix: "inApp.deviceDetail",
  });
  const [userOnDeviceList, userOnDeviceListHandler] =
    useListState<ManagedUserOnDevice>();
  const [page, setPage] = useState(1);
  const activeManagedUserList = useAppSelector(
    (state) => state.activeManagedUserList
  );
  const combinedList = useMemo(
    () =>
      activeManagedUserList != null && userOnDeviceList != null
        ? physicalDeviceService.combineUsers(
            activeManagedUserList,
            userOnDeviceList
          )
        : undefined,
    [activeManagedUserList, userOnDeviceList]
  );
  const [isMobile] = useIsMobile();

  const loadUsersOnDevice = useCallback(async () => {
    const serverPromise = managedUserService.getManagedUserList(
      device.groupId,
      true,
      true,
      true
    );
    const result = await physicalDeviceService.getManagedUsersOnDevice(
      device.serialNumber
    );
    await serverPromise;
    if (!result.success) {
      if (result.code == "report") {
        toastDeviceResponseReport([device], result.data, undefined, {
          wrongPasswordSnList: (_) => t("toast.wrongPassword"),
          deviceNotConnectedSnList: (_) => t("toast.notConnected"),
        });
      } else {
        toastCommonError(result.code);
      }
    } else {
      userOnDeviceListHandler.setState(result.value);
    }
  }, [device.groupId, device.serialNumber, t]);

  useEffect(() => {
    loadUsersOnDevice();
  }, []);

  const existingIdList = useMemo(
    () =>
      combinedList?.flatMap(
        (u) => u.managedUser?.deviceId ?? u.managedUserOnDevice?.id ?? []
      ) ?? [],
    [combinedList]
  );

  return (
    <>
      <Buttons
        device={device}
        combinedList={combinedList}
        loadUsersOnDevice={loadUsersOnDevice}
        existingIdOnDeviceList={existingIdList}
      />
      {combinedList == null ? (
        <div></div>
      ) : (
        <>
          <Divider mt="sm" size={10} color="#e5e7eb" className="w-[300%]" />
          <ScrollArea
            // className="w-svw sm:w-[calc(100svw-260px)] h-[calc(100svh-164px)]"
            className="w-full h-auto min-h-40"
            scrollbars="x"
          >
            <div className="flex justify-center p-2">
              <Table
                className={classNames(
                  "rounded-md relative !border-separate border-spacing-0",
                  // thead
                  "[&>thead>tr:nth-child(2)>*]:border-l",
                  "[&>thead>tr:nth-child(2)>*:not(.no-y-border)]:border-y",
                  "[&>thead>tr>*:has(+.no-y-border)]:border-r",
                  // tbody
                  "[&>tbody>tr>*:not(.no-y-border)]:border-l",
                  "[&>tbody>tr>*:not(.no-y-border)]:border-b",
                  "[&>tbody>tr>*:has(+.no-y-border)]:border-r",
                  // border-radius bototm
                  "[&>tbody>tr:last-child>td:first-child]:rounded-bl-md",
                  "[&>tbody>tr:last-child>td:has(+.no-y-border)]:rounded-br-md",
                  "[&>tbody>tr:last-child>.device-first-col]:rounded-bl-md"
                )}
                withRowBorders={false}
              >
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th></Table.Th>
                    <Table.Th
                      className={classNames(
                        headerClassName,
                        "!text-center text-[var(--mantine-color-blue-light-color)] bg-[var(--mantine-color-gray-1)] rounded-t-3xl"
                      )}
                      colSpan={5}
                    >
                      <div className="flex flex-row justify-center">
                        {t("table.server")}
                        <IconServer2 className="ml-2" />
                      </div>
                    </Table.Th>
                    <Table.Th></Table.Th>
                    <Table.Th
                      className={classNames(
                        headerClassName,
                        "!text-center text-[var(--mantine-color-blue-light-color)] bg-[var(--mantine-color-gray-1)] rounded-t-3xl"
                      )}
                      colSpan={5}
                    >
                      <div className="flex flex-row justify-center">
                        {t("table.device", {
                          name: getDeviceDisplayName(device),
                        })}
                        <IconCalculator className="ml-2" />
                      </div>
                    </Table.Th>
                  </Table.Tr>
                  <Table.Tr className="[&>th:first-child]:rounded-tl-md">
                    <Table.Th className={secondRowHeaderClassName}>
                      {t("table.id")}
                    </Table.Th>
                    <Table.Th className={secondRowHeaderClassName}>
                      {t("table.internalId")}
                    </Table.Th>
                    <Table.Th className={secondRowHeaderClassName}>
                      {t("table.name")}
                    </Table.Th>
                    <Table.Th className={secondRowHeaderClassName}>
                      {t("table.password")}
                    </Table.Th>
                    <Table.Th className={secondRowHeaderClassName}>
                      {t("table.cardNumber")}
                    </Table.Th>
                    <Table.Th className={secondRowHeaderClassName}>
                      {t("table.role")}
                    </Table.Th>
                    <Table.Th className="no-y-border"></Table.Th>
                    <Table.Th className={secondRowHeaderClassName}>
                      {t("table.nameOnDevice")}
                    </Table.Th>
                    <Table.Th className={secondRowHeaderClassName}>
                      {t("table.password")}
                    </Table.Th>
                    <Table.Th className={secondRowHeaderClassName}>
                      {t("table.cardNumber")}
                    </Table.Th>
                    <Table.Th className={secondRowHeaderClassName}>
                      {t("table.role")}
                    </Table.Th>
                    <Table.Th className={secondRowHeaderClassName}>
                      {t("table.fingerprintCount")}
                    </Table.Th>
                    <Table.Th className="no-y-border"></Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {combinedList
                    .slice((page - 1) * pageSize, page * pageSize)
                    .map((user) => (
                      <Row
                        device={device}
                        user={user}
                        key={
                          user.managedUser?.deviceId ??
                          user.managedUserOnDevice?.id
                        }
                        addUpdateUserOnDevice={(value) => {
                          userOnDeviceListHandler.filter(
                            (v) => v.id != value.id
                          );
                          userOnDeviceListHandler.append(value);
                        }}
                        deleteUserOnDevice={(id) => {
                          userOnDeviceListHandler.filter((v) => v.id != id);
                        }}
                      ></Row>
                    ))}
                </Table.Tbody>
              </Table>
            </div>
          </ScrollArea>
          {combinedList.length > pageSize && (
            <div className="flex justify-center my-4">
              <Pagination
                total={Math.ceil(combinedList.length / pageSize)}
                value={page}
                onChange={setPage}
                size={isMobile ? "sm" : "md"}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

type RowProps = {
  device: Device;
  user: CombinedManagedUser;
  addUpdateUserOnDevice: Dispatch<ManagedUserOnDevice>;
  deleteUserOnDevice: Dispatch<number>;
};
const Row = memo(
  ({
    device,
    user,
    addUpdateUserOnDevice,
    deleteUserOnDevice,
  }: RowProps): JSX.Element => {
    const { t } = useTranslation(undefined, {
      keyPrefix: "inApp.deviceDetail",
    });
    const [toDeviceOpened, { open: openToDevice, close: closeToDevice }] =
      useDisclosure(false);
    const [
      addManagedUserOpened,
      { open: openAddManagedUser, close: closeAddManagedUser },
    ] = useDisclosure(false);
    const [
      updateManagedUserOpened,
      { open: openUpdateManagedUser, close: closeUpdateManagedUser },
    ] = useDisclosure(false);
    const id = useRef(
      user.managedUser?.deviceId ?? user.managedUserOnDevice?.id ?? -1
    );
    const [isMobile] = useIsMobile();

    const [hoveringDelete, { open: hoverDelete, close: stopHoveringDelete }] =
      useDisclosure(false);

    const deleteOnDevice = useCallback(async () => {
      stopHoveringDelete();
      const promise = physicalDeviceService.deleteUsersFromDevice(
        device.serialNumber,
        [id.current]
      );
      const deleteToastId = toast.loading(t("toast.deleting"), {
        closeButton: true,
        closeOnClick: true,
      });

      const result = await promise;
      if (!result.success) {
        toastCommonError(result.code, deleteToastId);
        return;
      }

      if (result.value?.okSnList?.length == 1) {
        toast.update(deleteToastId, {
          ...nullToastUpdateOptions,
          render: t("toast.deleteSuccess"),
          type: "success",
        });
        deleteUserOnDevice(user.managedUserOnDevice?.id ?? -1);
        return;
      }

      toastDeviceResponseReport([device], result.value, deleteToastId);
    }, [
      device.serialNumber,
      device,
      physicalDeviceService,
      user,
      user.managedUserOnDevice,
    ]);

    return (
      <Table.Tr
        className={classNames({
          "[&>td:not(.no-color):not(:last-child)]:!bg-[var(--mantine-color-red-1)]":
            user.status == "device-only" || user.status == "server-only",
          "[&>td:not(.no-color):not(:last-child)]:!bg-[var(--mantine-color-yellow-1)]":
            user.status == "different",
        })}
        style={{
          transitionProperty: "background-color",
          transitionDuration: "700ms",
        }}
      >
        <Table.Td className={classNames(className, "!h-[60px]")}>
          {id.current}
        </Table.Td>
        {user.managedUser == null ? (
          <Table.Td colSpan={5} className={className}></Table.Td>
        ) : (
          <>
            <Table.Td className={className}>
              {user.managedUser.internalId}
            </Table.Td>
            <Table.Td className={className}>{user.managedUser.name}</Table.Td>
            <Table.Td className={className}>
              {getDisplayPassword(user.managedUser.password)}
            </Table.Td>
            <Table.Td className={className}>
              {user.managedUser.cardNumber == 0
                ? ""
                : user.managedUser.cardNumber}
            </Table.Td>
            <Table.Td className={classNames(className)}>
              {user.managedUser.isAdmin ? (
                <span className="text-[var(--mantine-color-red-7)]">
                  {t("table.admin")}
                </span>
              ) : (
                t("table.member")
              )}
            </Table.Td>
          </>
        )}
        <Table.Td className="!p-0 !px-1 no-color no-y-border">
          <div className="flex flex-col">
            {user.status != "matching" && user.managedUser != null && (
              <ActionIcon variant="transparent" aria-label="add to device">
                <IconArrowRight
                  onClick={openToDevice}
                  color={
                    user.status == "different"
                      ? "var(--mantine-color-yellow-5)"
                      : "var(--mantine-color-red-5)"
                  }
                />
              </ActionIcon>
            )}
            {user.status != "matching" && user.managedUserOnDevice != null && (
              <ActionIcon variant="transparent" aria-label="add to system">
                <IconArrowLeft
                  onClick={
                    user.status == "different"
                      ? openUpdateManagedUser
                      : openAddManagedUser
                  }
                  color={
                    user.status == "different"
                      ? "var(--mantine-color-yellow-5)"
                      : "var(--mantine-color-red-5)"
                  }
                />
              </ActionIcon>
            )}
          </div>
        </Table.Td>
        {user.managedUserOnDevice == null ? (
          <>
            <Table.Td
              colSpan={5}
              className={className + " device-first-col"}
            ></Table.Td>
            <Table.Td className="no-color no-y-border"></Table.Td>
          </>
        ) : (
          <>
            <Table.Td
              className={classNames(className, "device-first-col", {
                "bg-[var(--mantine-color-gray-2)]": hoveringDelete,
              })}
            >
              {user.managedUserOnDevice.name}
            </Table.Td>
            <Table.Td
              className={classNames(className, {
                "bg-[var(--mantine-color-gray-2)]": hoveringDelete,
              })}
            >
              {getDisplayPassword(user.managedUserOnDevice.password)}
            </Table.Td>
            <Table.Td
              className={classNames(className, {
                "bg-[var(--mantine-color-gray-2)]": hoveringDelete,
              })}
            >
              {user.managedUserOnDevice.cardNumber == 0
                ? ""
                : user.managedUserOnDevice.cardNumber}
            </Table.Td>
            <Table.Td
              className={classNames(className, {
                "bg-[var(--mantine-color-gray-2)]": hoveringDelete,
              })}
            >
              {user.managedUserOnDevice.isAdmin ? (
                <span className="text-[var(--mantine-color-red-7)]">
                  {t("table.admin")}
                </span>
              ) : (
                t("table.member")
              )}
            </Table.Td>
            <Table.Td
              className={classNames(className, {
                "bg-[var(--mantine-color-gray-2)]": hoveringDelete,
              })}
            >
              {user.managedUserOnDevice.fingerprintCount}
            </Table.Td>
            <Table.Td className="no-color no-y-border">
              <ActionIcon
                onMouseEnter={hoverDelete}
                onMouseLeave={stopHoveringDelete}
                onFocus={hoverDelete}
                onBlur={stopHoveringDelete}
                className=" hover:!bg-[var(--mantine-color-red-1)]"
                variant="transparent"
                aria-label="delete from device"
                title="delete from device"
                onClick={deleteOnDevice}
              >
                <IconX color={"var(--mantine-color-red-5)"} />
              </ActionIcon>
            </Table.Td>
          </>
        )}
        {user.managedUser != null && (
          <Modal
            opened={toDeviceOpened}
            onClose={closeToDevice}
            fullScreen={isMobile}
          >
            <AddManagedUserToDevice
              dto={{
                ...user.managedUser,
                idOnDevice: user.managedUser.deviceId,
              }}
              device={device}
              onSuccess={(dto) => {
                closeToDevice();
                const userOnDevice = {
                  ...dto,
                  id: dto.idOnDevice,
                  fingerprintCount:
                    user.managedUserOnDevice?.fingerprintCount ?? 0,
                  name: getNameOnDevice(dto.name),
                };
                addUpdateUserOnDevice(userOnDevice);
              }}
            />
          </Modal>
        )}
        {user.managedUserOnDevice != null && user.managedUser == null && (
          <Modal
            opened={addManagedUserOpened}
            onClose={closeAddManagedUser}
            fullScreen={isMobile}
          >
            <AddManagedUser
              managedUser={{
                ...user.managedUserOnDevice,
                isAdmin: false,
                groupId: device.groupId,
                deviceId: user.managedUserOnDevice.id,
              }}
              existingIdList={[]}
              groupId={device.groupId}
              onSuccess={(dto) => {
                closeAddManagedUser();
                const managedUser = {
                  ...dto,
                  id: user.managedUserOnDevice?.id ?? -1,
                  deviceId: user.managedUserOnDevice?.id ?? -1,
                  groupId: device.groupId,
                };
                reduxStore.dispatch(
                  activeManagedUserSlice.actions.add([managedUser])
                );
              }}
            />
          </Modal>
        )}
        {user.managedUserOnDevice != null && user.managedUser != null && (
          <Modal
            opened={updateManagedUserOpened}
            onClose={closeUpdateManagedUser}
            fullScreen={isMobile}
          >
            <PatchManagedUser
              managedUser={{
                ...user.managedUserOnDevice,
                id: user.managedUser.id,
                isAdmin: user.managedUserOnDevice.isAdmin,
                internalId: "",
                imageUrl: "",
                groupId: device.groupId,
                deviceId: user.managedUserOnDevice.id,
              }}
              deviceList={[]}
              onSuccess={(dto) => {
                closeUpdateManagedUser();
                const managedUser = {
                  id: dto.id,
                  deviceId: user.managedUser?.deviceId ?? -1,
                  internalId:
                    dto.internalId ?? user.managedUser?.internalId ?? "-1",
                  name: dto.name ?? user.managedUser?.name ?? "-1",
                  imageUrl: dto.imageUrl ?? user.managedUser?.imageUrl ?? "-1",
                  isAdmin: dto.isAdmin ?? user.managedUser?.isAdmin ?? false,
                  password: dto.password ?? user.managedUser?.password ?? -1,
                  cardNumber:
                    dto.cardNumber ?? user.managedUser?.cardNumber ?? -1,
                  groupId: device.groupId,
                };
                reduxStore.dispatch(
                  activeManagedUserSlice.actions.updateItem(managedUser)
                );
              }}
            />
          </Modal>
        )}
      </Table.Tr>
    );
  }
);
Row.displayName = "Row";

const buttonClassName = "p-0 mx-2 mb-2";

type ButtonsProps = {
  device: Device;
  combinedList?: CombinedManagedUser[];
  loadUsersOnDevice: () => Promise<void>;
  existingIdOnDeviceList: number[];
};
function Buttons({
  device,
  combinedList,
  loadUsersOnDevice,
  existingIdOnDeviceList,
}: ButtonsProps): ReactNode {
  const [isMobile] = useIsMobile();
  const [t] = useTranslation(undefined, { keyPrefix: "inApp.deviceDetail" });
  const [uploadModalOpen, { open: openUploadModal, close: closeUploadModal }] =
    useDisclosure(false);
  const [
    downloadModalOpen,
    { open: openDownloadModal, close: closeDownloadModal },
  ] = useDisclosure(false);
  const [addModalOpened, { open: openAddModal, close: closeAddModal }] =
    useDisclosure(false);

  const getDownloadList = useCallback(() => {
    const conflictList: number[] = [];
    const matchingList: number[] = [];
    const serverOnlyList: number[] = [];
    combinedList?.forEach((u) => {
      if (u.managedUser != null) {
        if (u.status == "matching") {
          matchingList.push(u.managedUser.id);
        } else if (u.status == "different") {
          conflictList.push(u.managedUser.id);
        } else if (u.status == "server-only") {
          serverOnlyList.push(u.managedUser.id);
        }
      }
    });
    return { conflictList, matchingList, serverOnlyList };
  }, [combinedList]);

  const downloadToDevice = useCallback(
    async ({
      conflictList,
      matchingList,
      serverOnlyList,
    }: {
      conflictList: number[];
      matchingList: number[];
      serverOnlyList: number[];
    }) => {
      closeDownloadModal();
      const toastId = toast.loading(t("toast.sendingCommand"), {
        closeButton: true,
        closeOnClick: true,
      });
      if (conflictList.length == 0 && serverOnlyList.length == 0) {
        toast.update(toastId, {
          ...nullToastUpdateOptions,
          render: t("deviceUpToDate"),
          autoClose: 4000,
          type: "info",
        });
        return;
      }
      const result = await physicalDeviceService.addAllUsersToDevice(
        device.serialNumber,
        matchingList
      );
      if (!result.success) {
        toastCommonError(result.code, toastId);
        return;
      }
      await loadUsersOnDevice();
      if (result.value.okSnList?.length == 1) {
        toast.update(toastId, {
          ...nullToastUpdateOptions,
          render: t("uploadSuccess"),
          type: "success",
        });
      } else {
        toastDeviceResponseReport([device], result.value, toastId);
      }
    },
    [loadUsersOnDevice, t]
  );

  const getUploadList = useCallback(() => {
    const added: AddManagedUserDto[] = [];
    const updated: UpdateManagedUserDto[] = [];
    combinedList?.forEach((u) => {
      if (u.managedUserOnDevice != null) {
        if (u.status == "different") {
          updated.push({
            ...u.managedUserOnDevice,
            id: u.managedUser?.id ?? -1,
            deviceId: u.managedUserOnDevice.id,
            internalId: "",
            imageUrl: "",
          });
        } else if (u.status == "device-only") {
          added.push({
            ...u.managedUserOnDevice,
            deviceId: u.managedUserOnDevice.id,
            internalId: "",
            imageUrl: "",
          });
        }
      }
    });
    return { added, updated };
  }, [loadUsersOnDevice, combinedList]);

  const uploadToServer = useCallback(
    async ({
      added,
      updated,
    }: {
      added: AddManagedUserDto[];
      updated: UpdateManagedUserDto[];
    }) => {
      closeUploadModal();
      const toastId = toast.loading(t("toast.sendingCommand"), {
        closeButton: true,
        closeOnClick: true,
      });
      const hasData = added.length != 0 || updated.length != 0;
      if (!hasData) {
        toast.update(toastId, {
          ...nullToastUpdateOptions,
          render: t("serverUpToDate"),
          autoClose: 4000,
          type: "info",
        });
        return;
      }
      const result = await managedUserService.addUpdateManagedUser(
        device.groupId,
        added,
        updated
      );
      await managedUserService.getManagedUserList(
        device.groupId,
        false,
        true,
        true
      );
      if (!result.success) {
        toastCommonError(result.code, toastId);
      } else {
        toast.update(toastId, {
          ...nullToastUpdateOptions,
          render: t("uploadSuccess"),
          type: "success",
        });
      }
    },
    [t]
  );
  return (
    <div className="mx-1 my-5 flex justify-center w-full flex-wrap">
      <Button
        color="green"
        onClick={openAddModal}
        leftSection={<IconUserPlus size={20} stroke={2.5} />}
        className={buttonClassName}
        disabled={combinedList == null}
      >
        {t("addManagedUser")}
      </Button>
      <Button
        color="blue"
        leftSection={<IconDownload size={20} />}
        className={buttonClassName}
        onClick={openDownloadModal}
        disabled={combinedList == null}
      >
        {t("download")}
      </Button>
      <Button
        color="blue"
        onClick={openUploadModal}
        leftSection={<IconCloudUpload size={20} stroke={2.5} />}
        className={buttonClassName}
        disabled={combinedList == null}
      >
        {t("upload")}
      </Button>
      <Popup
        prepare={getDownloadList}
        onMainAction={downloadToDevice}
        titleFactory={(value) =>
          t("downloadTitle", {
            count: Math.min(
              value.conflictList.length + value.serverOnlyList.length,
              1000 - value.matchingList.length
            ),
          })
        }
        childrenFactory={(value) =>
          t("downloadWarning", { count: value.conflictList.length })
        }
        acceptNode={t("confirmSync")}
        acceptColor={"blue"}
        onClose={closeDownloadModal}
        opened={downloadModalOpen}
      />
      <Popup
        prepare={getUploadList}
        onMainAction={uploadToServer}
        titleFactory={(value) =>
          t("uploadTitle", {
            count: value.added.length + value.updated.length,
          })
        }
        childrenFactory={(value) =>
          t("uploadWarning", { count: value.updated.length })
        }
        acceptNode={t("confirmSync")}
        acceptColor={"blue"}
        onClose={closeUploadModal}
        opened={uploadModalOpen}
      />
      <Modal
        opened={addModalOpened}
        onClose={closeAddModal}
        fullScreen={isMobile}
      >
        <AddManagedUser
          snList={[]}
          onSuccess={closeAddModal}
          existingIdList={existingIdOnDeviceList}
        />
      </Modal>
    </div>
  );
}
