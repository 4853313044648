import { useTranslation } from "react-i18next";

const notFound = (): JSX.Element => {
  const { t } = useTranslation(undefined, { keyPrefix: "nav.notFound" });

  return (
    <div className="h-full w-full flex items-center justify-center ">
      <div className="flex flex-col items-center mb-40">
        <div className="text-[10em] text-gray-400 leading-tight">404</div>
        <div className="text-4xl text-gray-800 font-semibold">{t("pageNotFound")}</div>
      </div>
    </div>
  );
};

export default notFound;
