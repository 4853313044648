import { Button, Modal, ModalProps, Title } from "@mantine/core";
import { memo, ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type Props<T> = {
  prepare: T extends undefined ? undefined : () => T;
  onMainAction: (value: T) => void;
  childrenFactory: (value: T) => React.ReactNode;
  titleFactory?: (value: T) => React.ReactNode;
  acceptNode: React.ReactNode;
  cancelNode?: React.ReactNode;
  acceptColor?: string;
} & Omit<ModalProps, "children">;

function Popup<T = undefined>({
  prepare,
  onMainAction,
  childrenFactory,
  titleFactory,
  acceptNode,
  cancelNode,
  acceptColor,
  ...modalProps
}: Props<T>): ReactNode {
  const [t] = useTranslation(undefined, { keyPrefix: "components.popup" });
  const [state, setState] = useState<T>();
  useMemo(() => {
    if (modalProps.opened) {
      setState(prepare?.() ?? (undefined as any));
    }
  }, [modalProps.opened, prepare]);

  const isTUndefined = prepare == undefined;

  return (
    <Modal {...modalProps} centered={modalProps.centered ?? true}>
      <Title ta="center" mx={2}>
        {!isTUndefined && state == undefined
          ? ""
          : titleFactory?.(state as any)}
      </Title>
      <div className="mt-10 mx-2">
        {!isTUndefined && state == undefined
          ? ""
          : childrenFactory(state as any)}
      </div>
      <div className="flex justify-end space-x-2 mt-6">
        <Button
          onClick={modalProps.onClose}
          color="var(--mantine-color-gray-5)"
        >
          {cancelNode == null ? t("cancel") : cancelNode}
        </Button>
        <Button
          color={acceptColor ?? "blue"}
          onClick={() => {
            if (isTUndefined || state != undefined) {
              onMainAction(state as any);
            }
          }}
        >
          {acceptNode}
        </Button>
      </div>
    </Modal>
  );
}

export default memo(Popup) as typeof Popup;
