import { useRef } from "react";

export type PropertyWrapper<T> = {
  get: () => T;
  set: (value: T) => void;
};

export default function useRefCallback<T = undefined>(
  onSet?: (oldValue: T | undefined, value: T | undefined) => void
): PropertyWrapper<T | undefined>;

export default function useRefCallback<T>(
  initalValue: T,
  onSet?: (oldValue: T, value: T) => void
): PropertyWrapper<T>;

export default function useRefCallback<T>(
  initalValue: T,
  onSet?: (oldValue: T, value: T) => void
): PropertyWrapper<T> {
  const holder = useRef(initalValue);
  const wrapper: PropertyWrapper<T> = {
    get: () => holder.current,
    set: (value) => {
      onSet?.(holder.current, value);
      holder.current = value;
    },
  };
  return wrapper;
}
