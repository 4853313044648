import i18next from "i18next";
import {
  Id,
  ToastContent,
  ToastOptions,
  TypeOptions,
  toast,
} from "react-toastify";
import { BaseFailureCodes } from "src/repositories/api/Utils/ResponseUtility";
import Device, {
  getDeviceDisplayName,
} from "src/services/core/device/models/Device";
import { DeviceResponseReport } from "src/services/core/device/models/DeviceResponseReport";
import { isString, isStringArray, Nullable } from "src/utils/Utils";

const prefix = "toastUtils.";

export const nullToastUpdateOptions: Nullable<
  Omit<ToastOptions<unknown>, "render" | "data">
> = {
  autoClose: null,
  isLoading: null,
  draggable: null,
};

type ToastType = "info" | "success" | "warning" | "error";

const baseErrorCodeKeyMap: Record<
  BaseFailureCodes,
  null | string | { key: string; type: ToastType }
> = {
  "server-error": "error.serverError",
  "server-connection-error": "error.serverConnectionError",
  timeout: "error.timeout",
  error: "error.error",
  unauthorized: null,
};

const errorCodeMessageMap: Record<
  string,
  null | string | { key: string; type: ToastType }
> = {
  ...baseErrorCodeKeyMap,
};

export const toastCommonError = (
  code: string,
  toastId: Id | undefined = undefined
) => {
  const record = errorCodeMessageMap[code];

  if (record == null && record != undefined) {
    if (toastId != undefined) {
      toast.dismiss(toastId);
    }
    return;
  }

  let toastType: ToastType = "error";
  let key: string = "error.error";
  if (record != undefined) {
    if (!isString(record)) {
      toastType = record.type;
      key = record.key;
    } else {
      key = record;
    }
  }

  const message = i18next.t(prefix + key);

  if (toastId == undefined) {
    toast[toastType](message);
    return;
  }

  toast.update(toastId, {
    ...nullToastUpdateOptions,
    render: message,
    type: toastType,
  });
};

const toastProperties: Record<
  keyof DeviceResponseReport,
  { key: string; type: ToastType } | undefined
> = {
  okSnList: {
    key: "deviceReport.ok",
    type: "success",
  },
  wrongPasswordSnList: {
    key: "deviceReport.wrongPassword",
    type: "error",
  },
  deviceNotConnectedSnList: {
    key: "deviceReport.notConnected",
    type: "warning",
  },
  canceledSnList: {
    key: "deviceReport.canceled",
    type: "error",
  },
  timeoutSnList: { key: "deviceReport.timeout", type: "error" },
  otherList: {
    key: "deviceReport.other",
    type: "error",
  },
};

export const toastDeviceResponseReport = (
  deviceList: Device[],
  report: DeviceResponseReport | null,
  toastId?: Id,
  customMessages?: {
    [id in keyof DeviceResponseReport]?: (snList: string[]) => string;
  },
  customMessageDuration?: {
    [id in keyof DeviceResponseReport]?: (snList: string[]) => number;
  }
) => {
  if (report == null) {
    return;
  }
  (Object.keys(toastProperties) as (keyof DeviceResponseReport)[]).map(
    (key: keyof DeviceResponseReport) => {
      const snList = report[key];
      const toastConfig = toastProperties[key];
      if (snList != null && snList.length > 0 && toastConfig != null) {
        const firstPartOfMessage = i18next.t(prefix + toastConfig.key, {
          count: snList.length,
        });

        const customMessage = customMessages?.[key]?.(snList);
        const customDuration = customMessageDuration?.[key]?.(snList);

        const message =
          customMessage ??
          firstPartOfMessage +
            snList
              .flatMap((sn) => {
                const device = deviceList.find((d) => d.serialNumber == sn);
                if (device == null) {
                  return sn;
                }
                return getDeviceDisplayName(device);
              })
              .join(", ");
        if (toastId != null) {
          toast.update(toastId, {
            ...nullToastUpdateOptions,
            render: message,
            type: toastConfig.type,
            autoClose: customDuration ?? 7000,
          });
          toastId = undefined;
        } else {
          toast[toastConfig.type](message, {
            autoClose: customDuration ?? 7000,
          });
        }
      }
    }
  );
};
