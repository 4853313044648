import { createSlice } from "@reduxjs/toolkit";
import Device from "src/services/core/device/models/Device";

const deviceListSlice = createSlice({
  name: "device-list",
  initialState: null as Device[] | null,
  reducers: {
    update: (_list, action: { payload: Device[] | null }) => action.payload,
    add: (list, action: { payload: Device[] }) => {
      if (list == null) {
        return;
      }
      list.push(...action.payload);
    },
    remove: (list, action: { payload: string[] }) => {
      const removedSns = action.payload;
      if (list == null) {
        return;
      }
      return list.filter(
        (device) => removedSns.find((sn) => device.serialNumber == sn) == null
      );
    },
    updateItem: (list, action: { payload: Device }) => {
      if (list == null) {
        return;
      }
      const newDevice = action.payload;
      const index = list.findIndex((g) => g.id == newDevice.id);
      if (index == null) {
        return;
      }

      list[index] = newDevice;
    },
  },
});

export default deviceListSlice;

