import { deviceApi } from "src/repositories/api/Device.api";
import FailableResponse, {
  FailedResponse,
  createFailedResponse,
} from "src/repositories/model/FailableResponse";
import { GeneralApiResponse } from "src/repositories/api/Utils/ResponseUtility";
import Device from "./models/Device";
import groupService from "../group/Group.service";
import { reduxStore } from "src/services/redux/ReduxStore";
import deviceListSlice from "src/services/redux/slices/DeviceListSlice";
import { handleGeneralErrorResponse } from "src/services/utils/Utils.service";
import { isString } from "src/utils/Utils";

const deviceService = {
  loadDeviceListInitial: async (): Promise<
    GeneralApiResponse<Device[]> | FailedResponse<"no-selected-groups">
  > => {
    const groupResult = await groupService.getSelectedGroup();
    if (!groupResult.success) {
      handleGeneralErrorResponse(groupResult);
      return groupResult;
    }

    if (groupResult.value == null) {
      return createFailedResponse("no-selected-groups");
    }

    const result = await deviceApi.getDevices(groupResult.value.id);
    if (result.success) {
      reduxStore.dispatch(deviceListSlice.actions.update(result.value));
    } else {
      handleGeneralErrorResponse(result);
    }
    return result;
  },

  getDevices: async (
    groupId: number,
    updateRedux: boolean = true
  ): Promise<
    GeneralApiResponse<Device[]> | FailedResponse<"no-selected-groups">
  > => {
    const result = await deviceApi.getDevices(groupId);
    if (result.success && updateRedux) {
      reduxStore.dispatch(deviceListSlice.actions.update(result.value));
    }
    if (!result.success) {
      handleGeneralErrorResponse(result);
    }
    return result;
  },

  addDevices: async (
    deviceList: DeviceDto[],
    groupId: number | undefined = undefined
  ): Promise<
    | GeneralApiResponse<Device[]>
    | FailedResponse<"no-selected-groups">
    | FailedResponse<"devices-exist", string[]>
  > => {
    if (groupId == null) {
      const groupResult = await groupService.getSelectedGroup();
      if (!groupResult.success) {
        handleGeneralErrorResponse(groupResult);
        return groupResult;
      }

      if (groupResult.value == null) {
        return createFailedResponse("no-selected-groups");
      }

      groupId = groupResult.value.id;
    }

    const result = await deviceApi.addDevices({
      groupId,
      deviceInfoList: deviceList,
    });

    if (result.success) {
      reduxStore.dispatch(deviceListSlice.actions.add(result.value));
    } else {
      handleGeneralErrorResponse(result);
    }

    return result;
  },

  editDevice: async (device: Device): Promise<GeneralApiResponse> => {
    const result = await deviceApi.editDevice({
      serialNumber: device.serialNumber,
      name: device.name,
      password: device.password,
    });

    if (result.success) {
      reduxStore.dispatch(deviceListSlice.actions.updateItem(device));
    } else {
      handleGeneralErrorResponse(result);
    }

    return result;
  },

  deleteDevices: async (snList: string[]): Promise<GeneralApiResponse> => {
    const result = await deviceApi.deleteDevices(snList);
    if (result.success) {
      reduxStore.dispatch(deviceListSlice.actions.remove(snList));
    } else {
      handleGeneralErrorResponse(result);
    }

    return result;
  },
};

export const getDisplayPassword = (password: number | null): string => {
  return (
    password
      ?.toString(16)
      .padStart(8, "0")
      .replace(/[Ff]*$/, "") ?? ""
  );
};

export const getDisplayPasswordNumber = (
  password: number | null
): number | null => {
  const str = getDisplayPassword(password);
  return str == null ? null : parseInt(str);
};

export const getActualPassword = (
  password: number | string | null
): number | null => {
  if (
    password == null ||
    password == "" ||
    (!isString(password) && isNaN(password))
  ) {
    return null;
  }
  let passwordString = password.toString();
  while (passwordString.length < 8) {
    passwordString += "F";
  }

  return parseInt(passwordString, 16);
};

export const isDefaultNumber = (
  num: number | string | null | undefined
): boolean => {
  return num == null || isString(num) || isNaN(num);
};

// const getPassword = (passwordString: string | null): number | null => {
//   if (passwordString == null || passwordString == "") {
//     return null;
//   }
//   let finalString = passwordString;
//   while (finalString.length < 8) {
//     finalString += "F";
//   }
//   return parseInt(finalString, 16);
// };

// /**
//  * Transform (the last 8 digits of) a decimal number to a hexadecimal number
//  * while keeping the digits (e.g. from 0d1234 to 0x1234)
//  */
// const transformPassword = (
//   initialNumber: number,
//   initialBase: number = 10,
//   transformedBase: number = 16
// ): number => {
//   let transformedNumber = 0;
//   let multiplier = 1;
//   for (let i = 0; i < 8; i++) {
//     transformedNumber += (initialNumber % initialBase) * multiplier;
//     initialNumber = Math.floor(initialNumber / initialBase);
//     multiplier *= transformedBase;
//   }
//   return transformedNumber;
// };

// const transformDevice = <T extends { password: number | null }>(
//   deviceObject: T,
//   initialBase: number = 10,
//   transformedBase: number = 16
// ): T => {
//   return {
//     ...deviceObject,
//     password:
//       deviceObject.password != null
//         ? transformPassword(deviceObject.password, initialBase, transformedBase)
//         : null,
//   };
// };

// const transformDeviceList = <T extends { password: number | null }>(
//   objectList: T[],
//   initialBase: number = 10,
//   transformedBase: number = 16
// ): T[] => {
//   return objectList.map((o) => transformDevice(o, initialBase));
// };

export default deviceService;
