import { memo, useCallback, useState } from "react";
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  Text,
  UnstyledButton,
  rem,
} from "@mantine/core";
import { IconCalendarStats, IconChevronRight } from "@tabler/icons-react";
import { NavLink, useNavigate } from "react-router-dom";
import classNames from "classnames";

export type LinksGroupProps = {
  icon: React.FC<any>;
  label: string;
  isSelected?: boolean;
  initiallyOpened?: boolean;
  path?: { label: string; path: string; isSelected?: boolean }[] | string;
  onClick?: () => void;
};

function LinkGroup({
  icon: ItemIcon,
  label,
  isSelected,
  initiallyOpened,
  path,
  onClick,
}: LinksGroupProps) {
  const navigate = useNavigate();
  const hasSubLinks = Array.isArray(path);
  const [opened, setOpened] = useState(
    initiallyOpened ?? (hasSubLinks && path.some((sp) => sp.isSelected == true))
  );
  const items = (hasSubLinks ? path : []).map((link) => (
    <NavLink
      onClick={onClick}
      className={classNames([
        `
        font-medium block 
        px-[var(--mantine-spacing-md)]
        py-[var(--mantine-spacing-xs)] 
        ml-[var(--mantine-spacing-xl)] 
        text-sm 
        hover:bg-[var(--mantine-color-gray-3)]
        `,
        { "bg-[var(--mantine-color-gray-2)]": link.isSelected },
      ])}
      style={{
        borderLeft:
          "1px solid light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-4))",
        color: "var(--mantine-color-gray-7)",
      }}
      // className={classes.link}
      to={link.path}
      key={link.label}
    >
      {link.label}
    </NavLink>
  ));

  const handleClick = useCallback(() => {
    if (hasSubLinks) {
      setOpened((o) => !o);
    } else if (path != null) {
      onClick?.();
      navigate(path);
    }
  }, [hasSubLinks, path, setOpened]);

  const iconSize = 25;

  return (
    <>
      <UnstyledButton
        onClick={handleClick}
        // className={classes.control}
        className={classNames([
          "font-medium block w-full text-[var(--mantine-color-text)] hover:!bg-[var(--mantine-color-gray-3)] ",
          { "!bg-[var(--mantine-color-gray-2)]": isSelected },
        ])}
      >
        <Group
          justify="space-between"
          gap={0}
          className="p-[var(--mantine-spacing-md)]"
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <ThemeIcon variant="light" size={30}>
              <ItemIcon
                style={{ width: rem(iconSize), height: rem(iconSize) }}
              />
            </ThemeIcon>
            <Box ml="md">{label}</Box>
          </Box>
          {hasSubLinks && (
            <IconChevronRight
              stroke={1.5}
              style={{
                width: rem(16),
                height: rem(16),
                transform: opened ? "rotate(-90deg)" : "none",
                transition: "transform 200ms ease",
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasSubLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
}

export default memo(LinkGroup);
