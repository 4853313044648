import i18next from "i18next";
import { initReactI18next, Translation } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import viTranslation from "./languages/vi/viTranslation";
import enTranslation from "./languages/en/enTranslation";

export const defaultLng = "vi";

i18next
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    lng: defaultLng,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      vi: {
        translation: viTranslation,
      },
      en: {
        translation: enTranslation,
      },
    },
  });
