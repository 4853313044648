import { Container, LoadingOverlay, ScrollArea, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PathParam, useParams } from "react-router-dom";
import { paths } from "src/App";
import { toastCommonError } from "src/pages/utils/ToastUtils";
import Device, {
  getDeviceDisplayName,
} from "src/services/core/device/models/Device";
import physicalDeviceService from "src/services/core/device/PhysicalDevice.service";
import { delay } from "src/utils/Utils";
import OpenDoorButton from "./components/OpenDoorButton";
import { CombinedTable } from "./components/CombinedTable";
import classNames from "classnames";
import useIsMobile from "src/pages/utils/isMobileHook";

export default function DeviceDetail(): JSX.Element {
  type paramType = PathParam<typeof paths.inAppPaths.deviceDetail>;
  const { deviceSn } = useParams<paramType>();
  const [device, setDevice] = useState<Device>();
  const [isLoading, { close: turnOffLoading }] = useDisclosure(true);
  const { t } = useTranslation(undefined, {
    keyPrefix: "inApp.deviceDetail",
  });
  const [isMobile] = useIsMobile();

  if (deviceSn == null) {
    return <div />;
  }

  useEffect(() => {
    (async () => {
      const delayPromise = delay(300);
      const result = await physicalDeviceService.getDevice(deviceSn);
      await delayPromise;
      if (!result.success) {
        toastCommonError(result.code);
      } else {
        setDevice(result.value);
      }
    })().finally(turnOffLoading);
  }, []);

  return (
    <div className="w-full h-full">
      <ScrollArea
        className="w-full h-full relative bg-gray-200 [&>div>div:first-child]:!block"
        scrollbars="y"
      >
        {isLoading && (
          <div className="h-svh w-full">
            <LoadingOverlay
              className=""
              visible={true}
              zIndex={1000}
              // overlayProps={{ radius: "sm", blur: 2 }}
            />
          </div>
        )}
        <Container p={0} className="!max-w-[min(var(--container-size),97%)]">
          {device == null ? (
            <div className="flex items-center justify-center my-10">
              {t("loadDeviceFailed", { sn: deviceSn })}
            </div>
          ) : (
            <div className="flex flex-col w-full overflow-hidden items-center bg-white my-6 rounded-3xl ">
              <>
                <div
                  className={classNames(
                    "!font-mono font-bold uppercase mt-10 mx-2 text-center",
                    {
                      "text-lg": isMobile,
                      "text-3xl": !isMobile,
                    }
                  )}
                >
                  {getDeviceDisplayName(device)}
                </div>
              </>
              {device.name != "" && (
                <span
                  className={classNames("!font-sans", {
                    "text-sm": isMobile,
                    "text-lg": !isMobile,
                  })}
                >
                  {device.serialNumber}
                </span>
              )}
              <div className="py-10">
                <OpenDoorButton
                  device={device}
                  deviceConnected={device.isOnline == true}
                />
              </div>
              {device.isOnline == true && <CombinedTable device={device} />}
            </div>
          )}
        </Container>
      </ScrollArea>
    </div>
  );
}
