import { AxiosError, AxiosInstance } from "axios";
import localStorageService from "src/repositories/local-storage/LocalStorageService";

export const getBrearerTokenHeader = () => {
  const tokenInfo = localStorageService.getData("tokenInfo");
  if (tokenInfo == null) {
    throw new Error("Token not found");
  }

  return {
    headers: { Authorization: `Bearer ${tokenInfo.token}` },
  };
};

export const addLogInterceptor = (axios: AxiosInstance) => {
  axios.interceptors.request.use((request) => {
    console.log("Starting Request", JSON.stringify(request));
    return request;
  });

  axios.interceptors.response.use(
    (response) => {
      console.log("Response:", JSON.stringify(response));
      return response;
    },
    (error) => {
      console.log("Response error:", JSON.stringify(error));
      throw error;
    }
  );
};

export const addUnauthorizedInterceptor = (
  axios: AxiosInstance,
  callback: () => void
) => {
  axios.interceptors.response.use(null, (error: AxiosError) => {
    if (error.response?.status == 401) {
      callback();
    }
    throw error;
  });
};
