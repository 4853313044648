import { jwtDecode } from "jwt-decode";

type TokenPayload = {
  email: string;
  name: string;
  isAccountActivated: boolean;
};

export function readPayload(token: string) {
  const payload = jwtDecode(token) as any;
  return {
    email: payload.email,
    name: payload.name,
    isAccountActivated: payload["is-account-activated"] == "True",
  };
}

export default TokenPayload;
