import {
  FailedResponse,
  createFailedResponse,
  createSuccessResponse,
} from "../model/FailableResponse";
import { globalAxiosAction } from "./Init.api";
import { getBrearerTokenHeader } from "./Utils/Utils.api";
import {
  GeneralApiResponse,
  handleGeneralApiError,
} from "./Utils/ResponseUtility";
import { isStringArray } from "src/utils/Utils";
import Device from "src/services/core/device/models/Device";
import { createDefaultAxios } from "./Utils/AxiosUtility";

const axiosInstance = createDefaultAxios("/api/device/");
globalAxiosAction.subscribe(axiosInstance);

async function getDevices(
  groupId: number
): Promise<GeneralApiResponse<Device[]>> {
  try {
    const response = await axiosInstance.get<Device[]>(
      "getDevices/" + groupId,
      getBrearerTokenHeader()
    );
    return createSuccessResponse(response.data);
  } catch (e) {
    return handleGeneralApiError(e);
  }
}

async function getDevice(
  deviceSn: string
): Promise<GeneralApiResponse<Device>> {
  try {
    const response = await axiosInstance.get<Device>(
      "getDevice/" + deviceSn,
      getBrearerTokenHeader()
    );
    return createSuccessResponse(response.data);
  } catch (e) {
    return handleGeneralApiError(e);
  }
}

async function deleteDevices(
  deviceSnList: string[]
): Promise<GeneralApiResponse> {
  try {
    const response = await axiosInstance.delete<null>("deleteDevices", {
      params: {
        snList: deviceSnList,
      },
      ...getBrearerTokenHeader(),
    });

    return createSuccessResponse(response.data);
  } catch (e) {
    return handleGeneralApiError(e);
  }
}

async function addDevices(
  dto: AddDeviceDto
): Promise<
  GeneralApiResponse<Device[]> | FailedResponse<"devices-exist", string[]>
> {
  try {
    const response = await axiosInstance.post<{ devices: Device[] }>(
      "addDevices",
      dto,
      getBrearerTokenHeader()
    );
    return createSuccessResponse(response.data.devices);
  } catch (e) {
    return handleGeneralApiError(e, (error, status, data) => {
      const existingDevices = data.existingDevices;
      if (status == 409 && isStringArray(existingDevices)) {
        return createFailedResponse("devices-exist", existingDevices);
      }
      return null;
    });
  }
}
async function editDevice(dto: EditDeviceDto): Promise<GeneralApiResponse> {
  try {
    await axiosInstance.post<{ devices: Device[] }>(
      "editDevice",
      {
        password: dto.password,
        name: dto.name,
      },
      {
        params: {
          deviceSn: dto.serialNumber,
        },
        ...getBrearerTokenHeader(),
      }
    );
    return createSuccessResponse();
  } catch (e) {
    return handleGeneralApiError(e);
  }
}
export const deviceApi = {
  getDevice,
  getDevices,
  addDevices,
  editDevice,
  deleteDevices,
};
