import axios, { AxiosError } from "axios";
import FailableResponse, {
  FailedResponse,
  createFailedResponse,
  createSuccessResponse,
} from "../model/FailableResponse";
import { globalAxiosAction } from "./Init.api";
import { getBrearerTokenHeader } from "./Utils/Utils.api";
import { BASE_URL } from "./Common.api";
import {
  GeneralApiResponse,
  handleGeneralApiError,
} from "./Utils/ResponseUtility";
import {
  AddManagedUserDto,
  AddManagedUserOutput,
  PatchManagedUserDto as PatchManagedUserDto,
  PatchManagedUserOutput,
  UpdateManagedUserDto,
} from "src/services/core/managed-user/models/ManagedUserDto";
import ManagedUser from "src/services/core/managed-user/models/ManagedUser";
import { isNumberArray, isStringArray } from "src/utils/Utils";
import { DeviceResponseReport } from "src/services/core/device/models/DeviceResponseReport";
import { createDefaultAxios } from "./Utils/AxiosUtility";

const axiosInstance = createDefaultAxios("/api/manageduser/");
globalAxiosAction.subscribe(axiosInstance);

/**
 *
 * @param activeState parameter to filter the managedUser by based on the value of managedUser.isActive,
 * null means that both managedUser's with isActive == true and isActive == false are returned.
 * The default value is true.
 */
async function getManagedUsers(
  groupId: number,
  activeState: boolean | null = true
): Promise<GeneralApiResponse<ManagedUser[]>> {
  try {
    const response = await axiosInstance.get<ManagedUser[]>(
      "getManagedUsers/" + groupId,
      {
        ...getBrearerTokenHeader(),
        params: { activeState: activeState ?? "both" },
      }
    );
    return createSuccessResponse(response.data);
  } catch (e) {
    return handleGeneralApiError(e);
  }
}

async function deleteManagedUsers(
  managedUserIdList: number[],
  deviceSnList: string[]
): Promise<GeneralApiResponse<DeviceResponseReport | null>> {
  try {
    const response = await axiosInstance.delete<DeviceResponseReport | null>(
      "deleteManagedUsers",
      {
        params: {
          managedUserIdList,
          deviceSnList,
        },
        ...getBrearerTokenHeader(),
      }
    );
    return createSuccessResponse(response.data);
  } catch (e) {
    return handleGeneralApiError(e);
  }
}

async function addManagedUsers(
  groupId: number,
  managedUserList: AddManagedUserDto[],
  deviceSnList: string[]
): Promise<
  | GeneralApiResponse<AddManagedUserOutput>
  | FailedResponse<"devices-not-connected", string[]>
  | FailedResponse<"id-conflict", number[]>
> {
  try {
    const response = await axiosInstance.post<AddManagedUserOutput>(
      "addManagedUsers/" + groupId,
      {
        managedUsers: managedUserList,
        deviceSnList,
        forceRewriteUsersOnDevices: true,
      },
      getBrearerTokenHeader()
    );
    return createSuccessResponse(response.data);
  } catch (e) {
    return handleGeneralApiError(e, (error, status, data) => {
      if (status == 404 && isStringArray(data.devicesNotConnected)) {
        return createFailedResponse(
          "devices-not-connected",
          data.devicesNotConnected
        );
      } else if (status == 409 && isNumberArray(data.conflictedIds)) {
        return createFailedResponse("id-conflict", data.conflictedIds);
      }
      return null;
    });
  }
}

async function patchManagedUser(
  managedUserDto: PatchManagedUserDto,
  deviceSnList: string[]
): Promise<
  | GeneralApiResponse<PatchManagedUserOutput>
  | FailedResponse<"id-conflict", number[]>
> {
  try {
    const response = await axiosInstance.patch<PatchManagedUserOutput>(
      "patchManagedUser",
      {
        managedUser: managedUserDto,
        deviceSnList,
      },
      getBrearerTokenHeader()
    );
    return createSuccessResponse(response.data);
  } catch (e) {
    return handleGeneralApiError(e, (error, status, data) => {
      if (status == 409 && isNumberArray(data.conflictedIds)) {
        return createFailedResponse("id-conflict", data.conflictedIds);
      }
      return null;
    });
  }
}

async function addUpdateManagedUser(
  groupId: number,
  added: AddManagedUserDto[],
  updated: UpdateManagedUserDto[]
): Promise<GeneralApiResponse> {
  try {
    const response = await axiosInstance.post(
      `addUpdateManagedUsers/${groupId}`,
      {
        added,
        updated,
      },
      getBrearerTokenHeader()
    );
    return createSuccessResponse(response.data);
  } catch (e) {
    return handleGeneralApiError(e);
  }
}

export const managedUserApi = {
  getManagedUsers,
  deleteManagedUsers,
  patchManagedUser,
  addManagedUsers,
  addUpdateManagedUser
};
