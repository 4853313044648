import { Table } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { SystemLog } from "src/services/core/log/models/Log";

type Props = { log: SystemLog };

const LogRow = ({ log }: Props) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "inApp.logs.system",
  });

  return (
    <Table.Tr>
      <Table.Td>{log.time.toLocaleTimeString()}</Table.Td>
      <Table.Td>{log.deviceName}</Table.Td>
      <Table.Td>{t("status." + log.status.toString())}</Table.Td>
    </Table.Tr>
  );
};

export default LogRow;
