import { deviceApi } from "src/repositories/api/Device.api";
import FailableResponse, {
  FailedResponse,
  createFailedResponse,
  createSuccessResponse,
} from "src/repositories/model/FailableResponse";
import { GeneralApiResponse } from "src/repositories/api/Utils/ResponseUtility";
import Device from "./models/Device";
import groupService from "../group/Group.service";
import { reduxStore } from "src/services/redux/ReduxStore";
import deviceListSlice from "src/services/redux/slices/DeviceListSlice";
import { DeviceResponseReport } from "./models/DeviceResponseReport";
import { handleGeneralErrorResponse } from "src/services/utils/Utils.service";
import physicalDeviceApi, {
  DownloadUserToDeviceReport,
} from "src/repositories/api/physical-device/PhysicalDevice.api";
import ManagedUserOnDevice from "./models/ManagedUserOnDevice";
import managedUserService from "../managed-user/ManagedUser.service";
import CombinedManagedUser, {
  ManagedUserMatchingStatus,
} from "./models/dto/CombinedManagedUser";
import ManagedUser from "../managed-user/models/ManagedUser";
import { AddUserToDeviceDto } from "src/repositories/api/physical-device/dto/AddUserToDeviceDto";
import { groupApi } from "src/repositories/api/Group.api";
import localStorageService from "src/repositories/local-storage/LocalStorageService";

async function openDoors(
  snList: string[]
): Promise<GeneralApiResponse<DeviceResponseReport | null>> {
  const result = await physicalDeviceApi.openDoors(snList);

  if (!result.success) {
    handleGeneralErrorResponse(result);
  }

  return result;
}

async function lockDoors(
  snList: string[]
): Promise<GeneralApiResponse<DeviceResponseReport | null>> {
  const result = await physicalDeviceApi.lockDoors(snList);

  if (!result.success) {
    handleGeneralErrorResponse(result);
  }

  return result;
}

async function getDevice(
  deviceSn: string
): Promise<GeneralApiResponse<Device>> {
  let device = reduxStore
    .getState()
    .deviceList?.find((d) => d.serialNumber == deviceSn);

  if (device == null) {
    const deviceResult = await deviceApi.getDevice(deviceSn);
    if (!deviceResult.success) {
      handleGeneralErrorResponse(deviceResult);
      return deviceResult;
    }
    device = deviceResult.value;
  }
  return createSuccessResponse(device);
}

async function getManagedUsersOnDevice(
  deviceSn: string
): Promise<
  | GeneralApiResponse<ManagedUserOnDevice[]>
  | FailedResponse<"report", DeviceResponseReport>
> {
  const result = await physicalDeviceApi.getUsersOnDevice(deviceSn);
  if (!result.success) {
    handleGeneralErrorResponse(result);
    return result;
  }

  if (result.value.report != null) {
    return createFailedResponse("report", result.value.report);
  }

  if (result.value.managedUsers == null) {
    return createFailedResponse("server-error");
  }
  return createSuccessResponse(result.value.managedUsers);
}

function combineUsers(
  managedUsers: ManagedUser[],
  managedUsersOnDevice: ManagedUserOnDevice[]
): CombinedManagedUser[] {
  const users = [...managedUsers].sort((a, b) => a.deviceId - b.deviceId);
  const deviceUsers = [...managedUsersOnDevice].sort((a, b) => a.id - b.id);
  // const users = managedUsers;
  // const deviceUsers = managedUsersOnDevice;
  const combinedUsers: CombinedManagedUser[] = [];

  let ui = 0;
  let dui = 0;
  let u: ManagedUser | undefined;
  let du: ManagedUserOnDevice | undefined;
  do {
    u = users[ui];
    du = deviceUsers[dui];
    if (u == undefined && du == undefined) {
      break;
    } else if (u == undefined) {
      combinedUsers.push({ managedUserOnDevice: du, status: "device-only" });
      dui++;
    } else if (du == undefined) {
      combinedUsers.push({ managedUser: u, status: "server-only" });
      ui++;
    } else if (du.id < u.deviceId) {
      combinedUsers.push({ managedUserOnDevice: du, status: "device-only" });
      dui++;
    } else if (u.deviceId < du.id) {
      combinedUsers.push({ managedUser: u, status: "server-only" });
      ui++;
    } else {
      combinedUsers.push({
        managedUser: u,
        managedUserOnDevice: du,
        status: compareManagedUsers(u, du),
      });
      dui++;
      ui++;
    }
    // eslint-disable-next-line no-constant-condition
  } while (true);
  return combinedUsers;
}

async function addUsersToDevice(
  deviceSn: string,
  dtoList: AddUserToDeviceDto[]
): Promise<GeneralApiResponse<DeviceResponseReport | null>> {
  const result = await physicalDeviceApi.addUserToDevices(deviceSn, dtoList);

  if (!result.success) {
    handleGeneralErrorResponse(result);
  }

  return result;
}

async function deleteUsersFromDevice(
  deviceSn: string,
  ids: number[]
): Promise<GeneralApiResponse<DeviceResponseReport | null>> {
  const result = await physicalDeviceApi.deleteUsersFromDevice(deviceSn, ids);

  if (!result.success) {
    handleGeneralErrorResponse(result);
  }

  return result;
}

async function addAllUsersToDevice(
  deviceSn: string,
  skippedIdList: number[]
): Promise<
  GeneralApiResponse<DeviceResponseReport> | FailedResponse<"no-managed-users">
> {
  const result = await physicalDeviceApi.addAllUsersToDevice(
    deviceSn,
    skippedIdList
  );

  if (!result.success) {
    handleGeneralErrorResponse(result);
    return result;
  }

  if (result.value.length == 0) {
    return createFailedResponse("no-managed-users");
  }

  const report =
    result.value.find((v) => v.deviceResponseReport.okSnList != null)
      ?.deviceResponseReport ?? result.value[0].deviceResponseReport;

  return createSuccessResponse(report);
}

function compareManagedUsers(
  managedUser?: ManagedUser,
  managedUserOnDevice?: ManagedUserOnDevice
): ManagedUserMatchingStatus {
  return managedUser == null && managedUserOnDevice == null
    ? "matching"
    : managedUser == null
    ? "device-only"
    : managedUserOnDevice == null
    ? "server-only"
    : managedUser.cardNumber != managedUserOnDevice.cardNumber ||
      managedUser.password != managedUserOnDevice.password ||
      getNameOnDevice(managedUser.name) != managedUserOnDevice.name ||
      managedUser.isAdmin != managedUserOnDevice.isAdmin
    ? "different"
    : "matching";
}

export function getNameOnDevice(name: string) {
  return (
    /(([^\s]{1,15})|(?<=\s|^).{1,15})$/.exec(
      name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace("Đ", "D")
        .replace("đ", "d")
    )?.[1] ?? "undefined"
  );
}

const physicalDeviceService = {
  openDoors,
  lockDoors,
  addUsersToDevice,
  deleteUsersFromDevice,
  addAllUsersToDevice,
  getDevice,
  getManagedUsersOnDevice,
  combineUsers,
  compareManagedUsers,
};

export default physicalDeviceService;
