import { createSlice } from "@reduxjs/toolkit";
import Device from "src/services/core/device/models/Device";

const tokenUnauthorizedSlice = createSlice({
  name: "token-unauthorized",
  initialState: false,
  reducers: {
    update: (_list, action: { payload: boolean }) => action.payload,
  },
});

export default tokenUnauthorizedSlice;
