/* eslint-disable quotes */
import React, { ReactNode } from "react";
import "./css/user-policy.css";

const ChildrensPrivacyStatement = (): ReactNode => {
  return (
    <>
      <h1> KT Lock Children’s Privacy Statement</h1>
      <div className="content">Effective: March 11, 2024</div>
      <div className="content">
        KT Lock Inc. And its affiliates {`(`}collectively, “we”, “us”, “our”, or
        “KT Lock”{`)`} are committed to protecting the privacy of children {`(`}
        i.e., minors under 16 years of age or such other age provided by
        applicable law in your country/region of residence{`)`} who use our
        products and services. This Children’s Privacy Statement {`(`}including
        subsequent updates, collectively, this “Statement”{`)`} and KT Lock
        Privacy Policy are intended to explain to children’s parents or other
        guardians
      </div>
      <div className="content">
        {`(`}“guardians” or “you”{`)`} how we collect and use children’s
        personal information in connection with our products and services, the
        rules applicable to processing such personal information, and the ways
        in which guardians may access, correct, delete, and protect such
        personal information.
      </div>
      <h2>Special Instructions for a Guardian:</h2>
      <div className="content">
        We protect the personal information and privacy of children in
        accordance with this Statement. You are expected to help us protect the
        personal information and privacy of children under your custody, to
        require them to read and accept this Statement together with you, and to
        ensure that they use our products and services with your consent and
        guidance. Please note that if you choose to agree to this Statement, or
        your children begin, or continue, to use our products or services, or
        submit their personal information, you acknowledge that you agree to our
        collection, use, storage, sharing, transfer, and disclosure of your
        children’s personal information in accordance with this Statement.
      </div>
      <h2>Special Instructions for Children:</h2>
      <div className="content">
        We recommend that any child use our products or services with the prior
        consent of his/her guardian. If you are a child, please be sure to
        carefully read this Statement with your guardian, and use our products
        or services or provide information to us with your guardian’s consent.
      </div>
      <div className="content">
        We hereby remind you that you shall carefully read and fully understand
        this Statement and KT Lock Privacy Policy {`(`}especially the terms in
        bold{`)`} before choosing whether to agree to the terms and whether to
        agree to your children’s use of our products or services. If you do not
        agree to any of the terms of the KT Lock Privacy Policy and/or the
        content of this Statement, or to provide relevant necessary information
        in the use of a specific feature or scenario of our products and
        services, please understand that you and your child may not be able to
        normally use our products or services or the specific feature or
        scenario. If there is any inconsistency
      </div>
      <div className="content">
        Between this Statement and the KT Lock Privacy Policy in terms of the
        protection of children’s privacy, this Statement shall apply. Matters
        not covered herein shall be subject to the KT Lock Privacy Policy.
      </div>
      <div className="content">
        This Statement applies to {`(`}1{`)`} our products or services that
        require a user to enter birth date, age, or any other information that
        can identify the user’s age, and {`(`}2{`)`} specific smart devices and
        their features and scenarios designed and developed for children that
        are controlled by our software {`(`}including mobile applications and
        applets/mini-programs{`)`}. The products and services that we provide
        may include products or services provided by third parties, and you may
        log in and use such third-party products and services through our
        software or products {`(`}e.g., App{`)`}. Such third parties may
        collect, use, or process information related to children. We recommend
        that you ask your children to seek your consent before authorizing a
        third party to collect or process any of their personal information.
      </div>
      <div className="content">
        If you have any questions about any term of this Statement, or you and
        your children have any requests related to personal information during
        the use of our products or services, please contact us through the
        special channels available to you, which are shown in the “Contact Us”
        section below. We will handle and respond to your questions or requests
        as soon as possible upon receipt thereof.
      </div>
      <h2>I. What Information Do We Collect?</h2>
      <div className="content">
        We will, in strict accordance with the obligations and responsibilities
        for the protection of children’s personal information prescribed by
        applicable laws and regulations, as well as the provisions of relevant
        privacy policies and this Statement, and based on the specific needs of
        relevant products and services, collect and use children’s personal
      </div>
      <div className="content">
        Information after obtaining the consent of their guardians.
      </div>
      <div className="content">
        When providing certain child-oriented products or services to you and
        your children, we may require the user to fill in their birth date
        information for the purpose of providing such products or services to
        you so as to identify whether the user is a child and, in accordance
        with applicable laws and regulations, allow the child to use relevant
        products or services after obtaining the consent of his/her guardian.
      </div>
      <div className="content">
        When you and your children use a specific smart device controlled by our
        software, we may collect and use your children’s personal information
        necessary for certain features or scenarios, including information on
        years/months of age, movement, and geographic location. Specific types
        and scope of information involved will depend on the features and
        intelligent scenarios of the smart device{`(`}s{`)`} used.
      </div>
      <div className="content">
        When a child uses our products or services, we may collect other
        relevant information from the child. Please refer to the “What Personal
        Information Do We Collect” section of the KT Lock Privacy Policy to
        learn more about other personal information we may collect.
      </div>
      <div className="content">
        If we need to collect personal information about you or your children
        beyond the above-mentioned scope of collection, we will notify you again
        and obtain your consent.
      </div>
      <div className="content">
        Please understand that you may choose whether to fill in or provide us
        with certain information of your children, but if you do not fill in or
        provide certain information, it may cause our products or services to
        fail to function properly or to achieve the desired service effect, and
        you and your children may not be able to normally use our products or
        services or related specific business functions.
      </div>
      <h2>II. How Do We Use the Information?</h2>
      <div className="content">
        We will, in strict accordance with laws and regulations and the
        agreements concluded with users, use the information collected from you
        and your children for the following purposes:
      </div>
      <div className="content">
        a{`)`} To provide necessary products or services or the specific
        functions thereof; and
      </div>
      <div className="content">
        b{`)`} To optimize products or services, provide security assurance, or
        conduct service survey.
      </div>
      <div className="content">
        If we need to use personal information about you or your children beyond
        the above-mentioned purposes, we will notify you again and obtain your
        consent.
      </div>
      <h2>
        III. Sharing, Transfer, and Disclosure of Children’s Personal
        Information
      </h2>
      <div className="content">
        We strictly restrict the sharing, transfer, and disclosure of the
        personal information of children in accordance with laws and
        regulations. In general, we do not share hildren’s personal information
        with any third party. We may share with third parties the personal
        information of a child only after obtaining the prior consent of their
        guardian.
      </div>
      <h3>a{`)`} Sharing with Guardians</h3>
      <div className="content">
        We will share certain children’s personal information {`(`}such as the
        children’s user names, account numbers, and passwords, and transaction
        information for some of our products and services{`)`} with their
        guardians so that the guardians may access and manage relevant personal
        information.
      </div>
      <h3>b{`)`} Sharing with Third Parties</h3>
      <div className="content">
        For the purpose of external processing only, we may share a child’s
        personal information with third-party partners, including service
        providers, contractors, agents, and application developers {`(`}e.g.,
        communications service providers that send emails or push notifications
        on our behalf, and map service providers that provide us with location
        services{`)`} {`(`}they may not be located in your jurisdiction{`)`} and
        have them process such information for us in accordance with our
        statements and privacy policies and relevant confidentiality and
        security policies.
      </div>
      <div className="content">
        If we entrust a third party with processing of children’s personal
        information, we will conduct security assessment on the third party and
        the entrustment, and sign an entrustment agreement to require the third
        party to process such personal information within the scope of our
        authorization. The children’s personal information is used by such third
        party mainly for the following purposes:
      </div>
      <div className="content">i. To provide our services to the children;</div>
      <div className="content">
        ii. To achieve the purposes as set forth in the “How Do We Use the
        Information” section hereof;
      </div>
      <div className="content">
        iii. To fulfill our obligations under the KT Lock SmartPrivacy Policy
        and this Statement and exercise our rights thereunder; and
      </div>
      <div className="content">
        iv. To understand, maintain, and improve our services.
      </div>
      <div className="content">
        If we share your and your child’s information with the above-mentioned
        third parties, we will conduct security assessment and adopt encryption,
        anonymization, de-identification, and other measures to protect the
        security of your and your children’s information.
      </div>
      <div className="content">
        With the continuous development of our business, we may engage in a
        merger, acquisition, asset transfer, or other transaction that results
        in the sharing of your and your children’s personal information with a
        third party, in which case we will inform you by push notification,
        announcement, or other means, and continue to protect or require the new
        manager to continue to protect your and your children’s personal
        information in accordance with applicable laws and regulations and the
        security standards no less stringent than those required in this
        Statement.
      </div>
      <div className="content">
        We may anonymize the collected information of children for the purpose
        of big data analysis, for example, generating a report for the services
        that you use.
      </div>
      <h3>c{`)`} Other Disclosures</h3>
      <div className="content">
        In general, we prohibit the disclosure of children’s personal
        information, but we may disclose children’s personal information for the
        following purposes:
      </div>
      <div className="content">
        i. To comply with applicable laws and regulations, legal proceedings, or
        requests from public institutions and government authorities;
      </div>
      <div className="content">
        ii. To perform our terms of use and other agreements, policies, or
        standards, including investigating any potential violations;
      </div>
      <div className="content">
        iii. To protect the rights, privacy, security, or property of us and/or
        other users, including you; and
      </div>
      <div className="content">
        iv. To seek available detection, prevention, or remedial measures, limit
        potential damages payable by us, or resolve security, fraud, or
        technical issues.
      </div>
      <h2>IV. Storage of Children’s Personal Information</h2>
      <div className="content">
        We will take encryption and other technical measures to store children’s
        personal information to ensure information security. We will store
        children’s personal information only for a period necessary to fulfill
        the purposes for which we collect and use such information. For more
        information about how we store and protect children’s personal
        information, please refer to the “Storage Location and Period of
        Information” and “Information Security” sections of the KT Lock Privacy
        Policy.
      </div>
      <div className="content">
        If our products or services cease operating, or the storage period of
        your and your children’s personal information expires as required by
        this Statement or the KT Lock Privacy Policy, we will notify you and
        your children by means such as push notification, announcement, and
        email, and delete or anonymize your and your children’s personal
        information within a reasonable period of time.
      </div>
      <h2>V. Management of Children’sPersonal Information</h2>
      <div className="content">
        To make it easier for you and your children to access, correct, or
        delete relevant personal information while using our products or
        services, we provide you with the corresponding operation settings in
        the product design, and you also may provide your feedback or inquiries
        through the contact information we provide. We will contact you in a
        timely manner upon verifying your feedback. Please understand that
        during the verification process, we may require you to provide the
        children’s personal information such as account number, birth date, and
        contact information, so that we can continue to respond to your needs
        after completing authentication and verifying the issues.
      </div>
      <h3>a{`)`} Accessing Children’s PersonalInformation</h3>
      <div className="content">
        You and your children may view the personal information provided or
        generated by the child user in the use of our products or services,
        including but not limited to user name, nickname, gender {`(`}if
        applicable{`)`}, and voluntarily posted contents.
      </div>
      <h3>b{`)`} Correcting Children’s Personal Information</h3>
      <div className="content">
        When you and your children find errors in the children’s personal
        information that we collect, use, and process, you may correct such
        personal information by choosing Me {`>`} Personal Information {`>`}
        Nickname/Time Zone, or by contacting us. We will take measures in a
        timely manner to correct the errors after completing the authentication
        and verification.
      </div>
      <h3>c{`)`} Deleting Children’s Personal Information</h3>
      <div className="content">
        You and your children may request us to delete the children’s personal
        information that we collect, use, and process, and we will take measures
        in a timely manner to delete such personal information after completing
        the authentication and verification if:
      </div>
      <div className="content">
        i. We collect, store, use, transfer, or disclose the child’s personal
        information in violation of applicable laws and regulations or relevant
        agreement with you;
      </div>
      <div className="content">
        ii. We collect, store, use, transfer, or disclose the children’s
        personal information beyond the intended scope or necessary period;
      </div>
      <div className="content">iii. You withdraw your consent; or</div>
      <div className="content">
        iv. You or your children terminates the use of our products or services
        by cancelling the user account.
      </div>
      <div className="content">
        For your request, we will perform authentication and verification, and
        take measures in a timely manner to delete relevant personal information
        after completing the authentication and verification. However, please
        note that if you and your child require us to delete the specific
        personal information of the child, it may cause the child user to be
        unable to continue to use our products and services or certain specific
        business functions thereof. If you find that any personal information of
        your children has been collected without your prior consent, please
        contact us immediately, and we will endeavor to delete relevant
        information as soon as possible.
      </div>
      <div className="content">Contact Us</div>
      <div className="content">
        If you have any questions about our practices or this Statement, please
        contact us in the following ways:
      </div>
      <div className="content">
        VKEN.,JSC {`(`} KT Lock {`)`}
      </div>
      <div className="content">
        Address: No. 22A Alley 62/24 Van Phu Street, Group 2, Phu La Ward, Ha
        Dong District, Hanoi City, Vietnam
      </div>
      <div className="content">Email: contact.ktlock@gmail.com</div>
    </>
  );
};

export default ChildrensPrivacyStatement;
