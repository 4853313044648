import { Button } from "@mantine/core";
import { DateInput, DateTimePicker, DateValue } from "@mantine/dates";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  onSelect: (date: Date) => void;
  defaultDate?: Date;
};

const DatePicker = ({ onSelect, defaultDate }: Props) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "inApp.logs.shared",
  });

  const [date, setDate] = useState<Date | null>(null);

  return (
    <div className="flex flex-row justify-end space-x-2 items-center">
      <span>{t("date")}</span>
      <DateInput
        valueFormat="D / M / YYYY"
        highlightToday
        onChange={(value) => onSelect(value ?? new Date())}
        className="w-32"
        defaultValue={defaultDate}
      />
    </div>
  );
};

export default DatePicker;
