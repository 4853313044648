import activityStatusRecords from "src/services/core/log/status-records/ActivityStatusRecords";
import sensorStatusRecord from "src/services/core/log/status-records/SensorStatusRecords";
import systemStatusRecord from "src/services/core/log/status-records/SystemStatusRecords";

const viTranslation = {
  nav: {
    headerRoot: {
      language: "Ngôn ngữ",
      login: "Đăng nhập",
      deleteAccount: "Xoá tài khoản",
      logOut: "Đăng xuất",
      deleteAccountPage: {
        warning:
          "Bạn có chắc chắn bạn muốn xóa tài khoản? Tất cả dữ liệu liên quan cũng sẽ bị xóa khỏi hệ thống!",
        irreversible: "Tài khoản sẽ không thể được khôi phục!",
        enterEmail1: 'Nhập "',
        enterEmail2: '" để xóa tài khoản',
        deleteAccount: "Xóa tài khoản",
      },
    },
    sideBar: {
      tabs: {
        devices: "Thiết bị",
        members: "Thành viên",
        groups: "Nhóm",
        logs: {
          label: "Bản ghi",
          sub: {
            activity: "Bản ghi ra vào",
            sensor: "Bản ghi cảm biến",
            system: "Bản ghi hệ thống",
          },
        },
      },
    },
    notFound: {
      pageNotFound: "Trang không tồn tại",
    },
  },
  auth: {
    login: {
      loggingIn: "Đăng nhập...",
      loginSuccessful: "Đăng nhập thành công",
      accountNotActivated:
        "Tài khoản của bạn chưa được kích hoạt. Vui lòng kiểm tra email để nhận mã kích hoạt.",
      wrongCredentials: "Sai email hoặc mật khẩu",
      greeting: "Chào mừng bạn quay trở lại!",
      password: "Mật khẩu",
      signIn: "Đăng nhập",
      noAccount: "Bạn không có tài khoản?",
      signUp: "Đăng ký",
    },
    signUp: {
      registerAnAccount: "Đăng ký tài khoản",
      emailError: {
        used: "Email đã được sử dụng bởi 1 tài khoản khác!",
        invalid: "Email không hợp lệ!",
      },
      passwordError: {
        noNumber: "Mật khẩu phải chứa ít nhất 1 chữ số",
        noLowercase: "Mật khẩu phải chứa ít nhất 1 chữ viết hoa",
        noUppercase: "Mật khẩu phải chứa ít nhất 1 chữ viết thường",
        linebreak: "Mật khẩu không được chữa ký tự xuống dòng",
      },
      passwordReenterNotMatch: "Mật khẩu nhập lại không giống với mật khẩu!",
      nameTooLong: "Tên không được dài quá 100 ký tự!",
      signingUp: "Đăng ký...",
      signUpSuccess: "Đăng ký thành công",
      invalidEmailToast:
        "Email không hợp lệ hoặc không được chấp nhận bới máy chủ. Vui lòng thử lại với một email khác.",
      unacceptedEmail: "Email không được chấp nhận!",
      password: "Mật khẩu",
      yourPassword: "Mật khẩu của bạn",
      passwordCriteria: {
        title: "Mật khẩu phải đảm bảo các yêu cầu sau:",
        length8: "- Dài ít nhất 8 ký tự",
        containsNumber: "- Chứa ít nhất 1 chữ số",
        containsUppercase: "- Chứa ít nhất 1 chữ viết hoa",
        containsLowercase: "- Chứa ít nhất 1 chữ viết thường",
      },
      reenterPassword: "Nhập lại mật khẩu",
      reenterPasswordPlaceholder: "Nhập lại mật khẩu",
      name: "Tên đầy đủ",
      namePlaceholder: "Tên đầy đủ của bạn",
      tacAgreement: "Tôi đồng ý với ",
      userAgreement: "Thoả thuận người dùng",
      privacyPolicy: "Chính sách bảo mật",
      and: "và ",
      childrenPrivacyStatement: "Tuyên bố bảo mật trẻ em",
      signUp: "Đăng ký",
      aggreeToSignUp: "Đăng ký",
      hasAnAccount: "Bạn đã có tài khoản? ",
      login: "Đăng nhập",
    },
    activateAccount: {
      error: {
        wrongCode: "Mã kích hoạt sai!",
        codeNotEntered: "Bạn chưa điền đầy đủ mã kích hoạt!",
      },
      activatingAccount: "Kích hoạt tài khoản...",
      accountActivationSuccess: "Tài khoản kích hoạt thành công",
      emailSent:
        "Mã kích hoạt đã được gửi đến email của bạn, vui lòng nhập mã để hoàn tất quá trình tạo tài khoản.",
      activationCodeLabel: "Nhập mã kích hoạt",
      submit: "Hoàn thành",
    },
  },
  inApp: {
    groupList: {
      deleting: "Xoá nhóm...",
      groupDeleted: "Đã xoá nhóm {{name}}",
      selectGroup: "Chọn nhóm:",
      enableEdit: "Bật chỉnh sửa",
      disableEdit: "Tắt chỉnh sửa",
      delete: "Xóa",
      deleteTitle: "Xóa nhóm",
      deleteWarning:
        'Bạn có đồng ý xóa nhóm "{{name}}" không? Tất cả các thiết bị, thành viên và bản ghi thuộc nhóm này sẽ bị xóa. Dữ liệu trên các thiết bị sẽ không bị ảnh hưởng.',
      addGroup: {
        title: "Thêm nhóm",
        noEmptyName: "Tên nhóm không được để trống",
        adding: "Thêm nhóm...",
        additionSuccess: "Thêm nhóm thành công",
        groupName: "Tên nhóm",
        groupNamePlaceholder: "Nhà hàng A",
        add: "Thêm",
      },
      editGroup: {
        title: "Cập nhật nhóm",
        noEmptyName: "Tên nhóm không được để trống",
        editing: "Cập nhật nhóm...",
        editSuccess: "Cập nhật thành công",
        noChange: "Chi tiết nhóm không thay đổi",
        groupName: "Tên nhóm",
        groupNamePlaceholder: "Nhà hàng A",
        edit: "Cập nhật",
      },
    },
    deviceList: {
      deleting: "Xoá thiết bị...",
      deleteSuccess: "Xoá thành công",
      sendingCommand: "Gửi lệnh...",
      doorOpened_one: "Cửa được mở: {{devices}}",
      doorOpened_other: "{{count}} cửa được mở: {{devices}}",
      deviceNotConnected_one: "Thiết bị ngoại tuyến: ",
      deviceNotConnected_other: "{{count}} thiết bị ngoại tuyến: ",
      addDevice: "Thêm",
      delete: "Xoá",
      openDoor: "Mở cửa",
      getDevicesFail: "Không thể tải danh sách thiết bị.",
      noDevices:
        'Bạn đang không quản lý thiết bị nào trong nhóm này. Bấm "Thêm" để thêm thiết bị.',
      deleteTitle: "Xóa thiết bị",
      deleteWarning_one:
        "Bạn có đồng ý xóa {{count}} thiết bị? Tất cả các bản ghi được thu thập bởi thiết bị này cũng sẽ bị xóa.",
      deleteWarning_other:
        "Bạn có đồng ý xóa {{count}} thiết bị? Tất cả các bản ghi được thu thập bởi các thiết bị này cũng sẽ bị xóa.",
      table: {
        no: "Stt",
        serialNumber: "Số serial",
        name: "Tên",
        status: "Trạng thái",
        online: "Trực tuyến",
        offline: "Ngoại tuyến",
        action: "Hành động",
      },
      addDevicePage: {
        title: "Thêm thiết bị",
        noEmptySerialNumber: "Số serial không được để trống",
        adding: "Thêm thiết bị...",
        addSuccess: "Thêm thiết bị thành công",
        deviceExist: "Thiết bị đã tồn tại trong hệ thống: ",
        serialNumberlabel: "Số serial",
        passwordLablel: "Mật khẩu ",
        blankIfNoPassword: "(để trống nếu thiết bị không dùng mật khẩu)",
        nameLabel: "Tên thiết bị",
        namePlaceholder: "Cửa nhân viên cửa hàng A",
        add: "Thêm",
      },
      editDevicePage: {
        title: "Cập nhật thiết bị",
        noEmptySerialNumber: "Số serial không được để trống",
        editing: "Cập nhật thiết bị...",
        editSuccess: "Cập nhật thiết bị thành công",
        serialNumberlabel: "Số serial",
        passwordLablel: "Mật khẩu ",
        blankIfNoPassword: "(Để trống nếu thiết bị không dùng mật khẩu)",
        nameLabel: "Tên thiết bị",
        namePlaceholder: "Cửa nhân viên cửa hàng A",
        edit: "Cập nhật",
      },
    },
    managedUserList: {
      deleting: "Xoá thành viên...",
      deleteSuccess: "Xoá thành viên thành công",
      addManagedUser: "Thêm",
      deleteManagedUser: "Xoá",
      deleteTitle: "Xoá thành viên",
      deleteWarning_one:
        "Bạn có đồng ý xóa {{count}} thành viên không? Các thành viên này cũng sẽ được xóa trên các thiết bị:",
      deleteWarning_other:
        "Bạn có đồng ý xóa {{count}} thành viên không? Các thành viên này cũng sẽ được xóa trên các thiết bị:",
      defaultDevices: "Thiết bị mặc định:",
      getManagedUsersFailed: "Không thể tải danh sách thành viên.",
      noManagedUsers:
        'Bạn đang không quản lý thành viên nào trong nhóm này. Bấm "Thêm" để thêm thành viên.',
      deviceId: "ID trên thiết bị",
      internalId: "ID nội bộ",
      name: "Tên thành viên",
      password: "Mật khẩu",
      cardNumber: "Thẻ",
      role: "Chức danh",
      member: "Thành viên",
      admin: "Quản trị viên",
      actions: "Hành động",
      showHideColumns_one: "Ẩn/hiện cột (Đang ẩn {{count}} cột)",
      showHideColumns_other: "Ẩn/hiện cột (Đang ẩn {{count}} cột)",
      addManagedUserPage: {
        title: "Thêm thành viên",
        adding: "Thêm thành viên...",
        addSuccess: "Thêm thành viên thành công",
        deviceIdConflict:
          "ID này đã được sử dụng bởi 1 thành viên khác trong nhóm",
        deviceIdConflictToast:
          "ID đã được sử dụng bởi 1 thành viên khác trong nhóm",
        deviceIdLabel: "ID trên thiết bị",
        isAdminLabel: "Là quản trị viên",
        nameLabel: "Tên thành viên",
        internalIdLabel: "ID nội bộ",
        internalIdDescription:
          "Ví dụ: mã số học sinh/ mã số nhân viên/ số căn cước công dân, ...",
        internalIdPlaceholder: "STF-001923",
        passwordLablel: "Mật khẩu",
        blankIfNoPassword: "(để trống nếu thành viên không sử dụng mật khẩu)",
        cardNumberLablel: "Số thẻ từ",
        blankIfNoCardNumber: "(để trống nếu thành viên không sử dụng thẻ từ)",
        addToDevice: "Thêm vào các thiết bị:",
        add: "Thêm",
      },
      editManagedUserPage: {
        title: "Cập nhật thành viên",
        editing: "Cập nhật thành viên...",
        editSuccess: "Cập nhật thành viên thành công",
        deviceIdConflict:
          "ID này đã được sử dụng bởi 1 thành viên khác trong nhóm",
        deviceIdConflictToast:
          "ID đã được sử dụng bởi 1 thành viên khác trong nhóm",
        deviceIdLabel: "ID trên thiết bị",
        isAdminLabel: "Là quản trị viên",
        nameLabel: "Tên thành viên",
        internalIdLabel: "ID nội bộ",
        internalIdDescription: "Ví dụ: mã số học sinh/nhân viên,...",
        internalIdPlaceholder: "STF-001923",
        passwordLablel: "Mật khẩu",
        blankIfNoPassword: "(để trống nếu thành viên không sử dụng mật khẩu)",
        cardNumberLablel: "Số thẻ từ",
        blankIfNoCardNumber: "(để trống nếu thành viên không sử dụng thẻ từ)",
        addToDevice: "Cập nhật trên các thiết bị:",
        edit: "Cập nhật",
      },
    },
    logs: {
      shared: {
        getLogFail: "Đã có lỗi xảy ra khi tải bản ghi.",
        noLogs: "Không có bản ghi nào cho khoảng thời gian này.",
        from: "Từ",
        to: "Đến",
        date: "Ngày:",
        filter: "Lọc",
      },
      activity: {
        status: Object.fromEntries(
          Object.entries(activityStatusRecords).map(([i, v]) => [i, v.vi])
        ),
        table: {
          time: "Thời gian",
          deviceName: "Thiết bị",
          memberName: "Thành viên",
          status: "Phương thức xác thực",
          enterExit: "Vào/Ra",
          enter: "Vào",
          exit: "Ra",
        },
      },
      sensor: {
        status: Object.fromEntries(
          Object.entries(sensorStatusRecord).map(([i, v]) => [i, v.vi])
        ),
        table: {
          time: "Thời gian",
          deviceName: "Thiết bị",
          status: "Sự kiện cảm biến",
        },
      },
      system: {
        status: Object.fromEntries(
          Object.entries(systemStatusRecord).map(([i, v]) => [i, v.vi])
        ),
        table: {
          time: "Thời gian",
          deviceName: "Thiết bị",
          status: "Sự kiện hệ thống",
        },
      },
    },
    deviceDetail: {
      loadDeviceFailed: 'Đã có lỗi khi tải thông tin thiết bị "{{sn}}".',
      openDoor: "Mở khóa",
      lockDoor: "Khóa",
      deviceOffline: "Thiết bị ngoại tuyến",
      addManagedUser: "Thêm thành viên",
      download: "Đồng bộ xuống thiết bị",
      upload: "Đồng bộ lên máy chủ",
      uploadSuccess: "Đồng bộ thành công",
      downloadSuccess: "Đồng bộ thành công",
      noConflictingManagedUsers:
        "Không tìm thấy thành viên mâu thuẫn để đồng bộ",
      deviceUpToDate: "Các thành viên trên thiết bị đã là phiên bản mới nhất",
      serverUpToDate: "Các thành viên trên máy chủ đã là phiên bản mới nhất",
      confirmSync: "Đồng bộ",
      downloadTitle_one: "",
      downloadTitle_other: "Đồng bộ {{count}} thành viên xuống thiết bị",
      uploadTitle_one: "",
      uploadTitle_other: "Đồng bộ {{count}} thành viên lên máy chủ",
      downloadWarning_one:
        "                                                                                                    ",
      downloadWarning_other:
        "Hành động này sẽ ghi đè lên các thành viên mâu thuẫn đã tồn tại trên thiết bị (tìm thấy {{count}} thành viên mâu thuẫn).",
      uploadWarning_one:
        "                                                                                                    ",
      uploadWarning_other:
        "Hành động này sẽ ghi đè lên các thành viên mâu thuẫn đã tồn tại trên máy chủ (tìm thấy {{count}} thành viên mâu thuẫn).",
      cancel: "Cancel",
      table: {
        server: "Máy chủ",
        device: "Thiết bị ({{name}})",
        id: "ID",
        internalId: "ID nội bộ",
        name: "Tên",
        nameOnDevice: "Tên trên thiết bị",
        password: "Mật khẩu",
        cardNumber: "Thẻ",
        role: "Chức danh",
        member: "Thành viên",
        admin: "Quản trị viên",
        fingerprintCount: "Vân tay",
      },
      toast: {
        sendingCommand: "Gửi lệnh...",
        deleting: "Xóa thành viên...",
        doorOpened: "Mở khóa thành công",
        doorLocked: "Khóa thành công",
        deleteSuccess: "Xóa thành công",
        wrongPassword: "Sai mật khẩu",
        notConnected: "Thiết bị ngoại tuyến",
      },
      addManagedUserToDevice: {
        title: 'Thêm thành viên vào thiết bị "{{name}}"',
        adding: "Thêm thành viên...",
        addSuccess: "Thêm thành công",
        idLabel: "ID",
        nameLabel: "Tên thành viên",
        isAdminLabel: "Là quản trị viên",
        passwordLablel: "Mật khẩu",
        blankIfNoPassword: "(để trống nếu thiết bị không dùng mật khẩu)",
        cardNumberLablel: "Số thẻ",
        blankIfNoCardNumber: "(để trống nếu thiết bị không dùng thẻ từ)",
        add: "Thêm",
      },
    },
    component: {
      deviceDropDown: {
        device_one: "{{count}} thiết bị",
        device_other: "{{count}} thiết bị",
        noDevices: "Không tìm thấy thiết bị nào...",
      },
    },
  },
  toastUtils: {
    error: {
      serverError: "Đã có lỗi xảy ra với máy chủ",
      serverConnectionError: "Đã có lỗi xảy ra khi kết nối tới máy chủ",
      timeout: "Yêu cầu hết hạn",
      error: "Đã có lỗi xảy ra",
    },
    deviceReport: {
      ok_one: "Yêu cầu gửi thành công tới thiết bị: ",
      ok_other: "Yêu cầu gửi thành công tới {{count}} thiết bị: ",
      wrongPassword_one: "Mật khẩu sai cho thiết bị: ",
      wrongPassword_other: "Mật khẩu sai cho {{count}} thiết bị: ",
      notConnected_one: "Thiết bị ngoại tuyến: ",
      notConnected_other: "{{count}} thiết bị ngoại tuyến: ",
      canceled_one: "Thao tác bị huỷ bỏ cho thiết bị: ",
      canceled_other: "Thao tác bị huỷ bỏ cho {{count}} thiết bị: ",
      timeout_one: "Yêu cầu hết hạn cho thiết bị: ",
      timeout_other: "Yêu cầu hết hạn cho {{count}} thiết bị: ",
      other_one: "Đã có lỗi xảy ra với thiết bị: ",
      other_other: "Đã có lỗi xảy ra với {{count}} thiết bị: ",
    },
  },
  components: {
    popup: {
      cancel: "Hủy",
    },
  },
};

export default viTranslation;
