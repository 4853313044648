/* eslint-disable quotes */
import React, { ReactNode } from "react";
import "./css/user-policy.css";

const UserAgreement = (): ReactNode => {
  return (
    <>
      <h1>Service Agreement of KT Lock Platform</h1>
      <div className="content">Welcome to KT Lock Platform!</div>
      <div className="content">
        Before accepting the Agreement, you are supposed to thoroughly read all
        contents of the Agreement, and fully know its terms, especially
        restrictive clauses or exceptions. Restrictive clauses or exceptions are
        bold or highlighted in other forms to catch your attention. In case of
        any doubt for the terms of the Agreement, please contact relevant
        business department of KT Lock. You are not allowed to use the service
        before you have read and accepted all terms of the Agreement, relevant
        agreements and rules, etc. Once you select {`"`}agree and submit the
        Agreement{`"`} {`(`}see registration page for detailed wordings{`)`} and
        complete the registration procedure, or you use the service in any form,
        it will be deemed that you have read and agreed with the restriction of
        the Agreement and rules above. In case of any breach of the Agreement,
        KT Lock has the right to unilaterally restrict, suspend or terminate the
        service for you, and has the right to investigate your relevant
        responsibilities.
      </div>
      <div className="content">
        The Service Agreement {`(`}hereinafter referred to as {`"`}the Agreement
        {`"`}
        {`)`} is signed by you and VKEN.,JSC. {`(`}hereinafter referred to as{" "}
        {`"`}KT Lock{`"`}
        {`)`}. It includes {`(`}but not limited to{`)`} Privacy Policy of KT
        Lock Platform and other contents. In case of change, KT Lock will
        announce such change on its official website without further
        notification. Upon announcement, the changed agreement and rules become
        a part to the Agreement automatically, without further notification to
        you for your consent. In case of objection to such relevant changes,
        stop use KT Lock{`'`}s service; if you keep using it, it will deemed as
        that you hold no objections against the changed rules and agree to abide
        by them.
      </div>
      <h2>I. Definitions of Terms</h2>
      <div className="content">
        <b>1.1</b> Licensed software refers to software system developed by KT
        Lock, downloaded from the platform, and installed and applied in
        specified system mobile terminals.
      </div>
      <div className="content">
        <b>1.2</b> Services refer to services provided for you by VKEN.,JSC. You
        can use such services on the mobile terminal with the licensed software.
      </div>
      <h2>II. Service</h2>
      <div className="content">
        KT Lock offers you intelligent life equipment management services, based
        on which you can access to the intelligent terminal on KT Lock Cloud
        Platform through the KT Lock Control, and realize interlinkage among
        intelligent equipment. Service contents include intelligent equipment
        management, scene interlinkage and analysis report, etc. Such functions
        may be optimized or modified according to changes of users{`'`} demands
        or judgment of service supplier, and service supply may be suspended due
        to regular and irregular maintenance.
      </div>
      <h2>III. Scope of Service</h2>
      <div className="content">
        <b>3.1</b> KT Lock grants you with the right to use the product based on
        this software.
      </div>
      <div className="content">
        <b>3.2</b> You are prohibited to license, sell, lease, transfer, issue
        the product in any form, or use the product for other commercial
        purpose. Due to limitations on software adaptation platform and
        terminals, you can only use the licensed software in the authorized
        system platform and terminal; if you install the licensed software on
        other terminal equipment, it may damage your hardware or software
        function.
      </div>
      <div className="content">
        <b>3.3</b> You acknowledge that the licensed software can only be used
        for non-commercial purpose and installation, application and running of
        the licensed software is prohibited. If such commercial operation is
        necessary, prior written authorization and permission from KT Lock shall
        be obtained.
      </div>
      <div className="content">
        <b>3.4</b> KT Lock may change, upgrade or transfer the licensed software
        or relevant functions from time to time, and may add new functions or
        services in the licensed software system. If no separate agreements are
        accompanied with the aforesaid new functions or services, you are
        entitled to the corresponding functions and services, which is also
        subject to the Agreement.
      </div>
      <div className="content">
        <b>3.5</b> You shall be responsible for the accuracy, reliability,
        integrity and legality of input data and legality of the way in which
        you obtain the data, and shall back up data and information from time to
        time. You shall bear all risks for damage and loss of such information.
      </div>
      <div className="content">
        <b>3.6</b> You shall properly keep your account number and password. In
        case of any safety loophole for your account{`(`}including but not
        limited to divulgence of user password{`)`}, you shall notify KT Lock in
        time, and KT Lock will assist you in taking relevant measures.
        Otherwise, all behaviors related to your account shall be assumed by you
        and you will bear all responsibilities.**
      </div>
      <h2>IV. A Third Party</h2>
      <div className="content">
        <b>4.1</b> You acknowledge that certain service of KT Lock is based on
        software or services provided by a third party. Such service is set to
        facilitate your application and necessary legal authorization is
        obtained from the third party.
      </div>
      <div className="content">
        <b>4.2</b> The product includes certain information and services of the
        third party. KT Lock neither controls nor bears responsibility for
        information and services of the third party.
      </div>
      <div className="content">
        <b>4.3</b> You acknowledge that KT Lock cannot guarantee that the
        licensed software always uses or contains such services, or that other
        software provided by the same third party will be used in future.
        Likewise, it may use similar services supplied by another third party.
        Upon application, the aforesaid corresponding software or services are
        subject to this Agreement.**
      </div>
      <h2>V. Service Application Standard</h2>
      <div className="content">
        <b>5.1</b> You shall use the licensed software in a normal manner. The
        following ways are in breach of the application standard:
      </div>
      <div className="content">
        1{`)`} Issue or share computer virus, worms, malicious codes, or
        software that deliberately damages or changes computer system or data;
      </div>
      <div className="content">
        2{`)`} Collect information or data of other users without authorization,
        for example, email address and the like;
      </div>
      <div className="content">
        3{`)`} Maliciously use the product in an automatic way, causing over
        load to the server, or interfere with or damage web server and network
        links in other forms.
      </div>
      <div className="content">
        4{`)`} Attempt to visit server data or communication data of the product
        without authorization;
      </div>
      <div className="content">
        5{`)`} Interfere with or damage the production application by other
        users.
      </div>
      <div className="content">
        <b>5.2</b> You understand and agree that:
      </div>
      <div className="content">
        1{`)`} KT Lock will determine whether or not you are involved in
        violation of standards above, and suspend or terminate your application
        license according to determination results or take other restrictions
        according to agreements.
      </div>
      <div className="content">
        2{`)`} KT Lock will directly delete information in breach of laws, or
        infringing others{`'`} legal rights, or in breach of the Agreement
        issued by you when using the licensed software.
      </div>
      <div className="content">
        3{`)`} If a third party suffers from damage due to your breach of
        application standards, you shall independently bear legal responsibility
        in your name, and protect and indemnify KT Lock from losses or
        additional expenses generated therefrom. Otherwise, KT Lock has the
        right to claim compensation.
      </div>
      <div className="content">
        4{`)`} If KT Lock suffers from any loss due to your breach of relevant
        laws or the Agreement, you shall compensate KT Lock for losses and {`(`}
        or{`)`}
        expenses generated therefrom.
      </div>
      <h2>VI. Information Content Standard</h2>
      <div className="content">
        <b>6.1</b> You promise that you will not conduct any act in breach of
        laws or improper behaviors by using the service, such act and behavior
        include
        {`(`}but not limited to{`)`}:
      </div>
      <div className="content">
        <b>6.1.1</b> Uploading, transferring or sharing information containing
        one of the following contents:
      </div>
      <div className="content">
        1{`)`} Opposing the basic principles determined in the Constitution;
      </div>
      <div className="content">
        2{`)`} Endangering state safety, disclosing state secret, subverting
        state power and sabotaging state unity;
      </div>
      <div className="content">3{`)`} Damaging state honor and benefit;</div>
      <div className="content">
        4{`)`} Inciting national hatred and discrimination and sabotaging
        national unity;
      </div>
      <div className="content">
        5{`)`} Destroying religious policy of the state and advocating heresy
        and feudalistic superstition;
      </div>
      <div className="content">
        6{`)`} Spreading rumors, disturbing social order and destroying social
        stability;
      </div>
      <div className="content">
        7{`)`} Spreading obscenity, porn, gambling , violence , murder and
        terror or abetting a crime;
      </div>
      <div className="content">
        8{`)`} Insulting or slandering others and infringing on the legal rights
        and interests of others;
      </div>
      <div className="content">
        9{`)`} Containing contents of sham, defraudation, harm, threat,
        infringement to others{`'`} privacy, harassment, infringement, slander,
        coarseness, indecency, or morally repulsive contents;
      </div>
      <div className="content">
        10{`)`} Containing other contents restricted or forbidden by applicable
        laws, regulations, rules, provisions and other legal standards.
      </div>
      <h2>VII. Private Policy and Data</h2>
      <div className="content">
        It is crucial for KT Lock to protect your personal information. KT Lock
        prepares Privacy Policy of KT Lock Platform, in which contents related
        to ownership and protection of intellectual property, collection, use,
        share, storage and protection, etc. of your information are disclosed.
        You are suggested to thoroughly read Privacy Policy of KT Lock Platform.
      </div>
      <div className="content">VIII. Exception Clauses</div>
      <div className="content">
        <b>8.1</b> Unless otherwise specified in laws and regulations, KT Lock
        will do its best to ensure the security, validity, accuracy and
        reliability of the licensed software and technologies and information
        involved, but KT Lock is unable to guarantee the same due to restriction
        by available technologies nowadays.
      </div>
      <div className="content">
        <b>8.2</b> You understand that KT Lock will not assume responsibility
        for direct or indirect losses caused by force majeure and default of a
        third party.
      </div>
      <div className="content">
        <b>8.3</b> You shall be responsible for personnel injury or incidental
        or indirectly injury caused by or related to one of the following
        accidents:
      </div>
      <div className="content">
        1{`)`} A third party uses the licensed software or changes your data
        without permission;
      </div>
      <div className="content">
        2{`)`} Expenses and losses produced by using the licensed software;
      </div>
      <div className="content">
        3{`)`} Your misunderstanding of the licensed software;{" "}
      </div>
      <div className="content">
        4{`)`} Other losses related to licensed software caused by reasons not
        attributable to KT Lock.
      </div>
      <div className="content">
        <b>8.4</b> Any other licensed software-derived software not developed
        and released by KT Lock or the development and release are not granted
        by KT Lock is illegal software. Downloading, installing and using such
        software may cause unpredictable risks. KT Lock shall be free from legal
        responsibilities and disputes generated therein and KT Lock shall have
        the right to suspend or terminate application license and/or other all
        services.
      </div>
      <div className="content">
        <b>8.5</b> You have been informed of that the usage of KT Lock Platform
        involves in Internet service, which may be affected by unstable factors
        in all links. Although KT Lock has taken safeguard measures, the service
        may be suspended, terminated, delayed, suffered from application
        restriction or application failure due to inherent defects of Internet
        and e-communication as well as factors beyond reasonable control of any
        party to the Agreement {`(`}including but not limited to fire, flood,
        terrorist attack, pestilence, natural disasters, riot, terminal virus,
        hacker attack, network fault and terminal fault{`)`}. You hereby agree
        to bear foregoing risks and agree that KT Lock is free from any
        responsibility when normal running of services are influenced by the
        occurrence of foregoing risks.
      </div>
      <h2>IX. Agreement Termination and Breach of Agreement</h2>
      <div className="content">
        <b>9.1</b> You should understand that you shall use the licensed
        software according to authorization scope, respect intellectual property
        of software and contents contained in the software, and perform
        obligations according to the Agreement when using KT Lock{`'`}s
        services. KT Lock will terminate the application license if you are in
        material breach of the Agreement.
      </div>
      <div className="content">
        <b>9.2</b> Your application of the software relies on supporting
        services supplied by KT Lock{`'`}s related companies. Breach of terms,
        agreements, rules, annunciation and other relevant regulations of KT
        Lock and its related companies may cause failure in normal usage of
        licensed software, in which case, KT Lock shall be entitled to terminate
        the application license, or take measures to restrain your application
        license or other rights and interests controlled by KT Lock as agreed in
        the Agreement, including suspension or termination of your application
        license.
      </div>
      <div className="content">
        <b>9.3</b> In case of your breach of the Agreement or other agreements
        signed with KT Lock, KT Lock shall have the right to notify the related
        companies, requiring them to take restrictive measures to your rights
        and interests, including requiring related companies to suspend or
        terminate supplying part or whole services for you, and legally announce
        your breach of agreement on websites run or actually controlled by them.
      </div>
      <div className="content">
        <b>9.4</b> The licensed software is downloaded from the downloading
        platform and you shall abide by stipulations of the download platform,
        system platform and terminal manufacture on application ways and
        restrictions of the licensed software. If the above mentioned third
        party confirms that you are in breach of the agreement and KT Lock{`'`}s
        treatment is required, KT Lock may terminate your application license at
        the third party{`'`}s request.
      </div>
      <div className="content">
        <b>9.5</b> When the application license terminates, you shall stop using
        the licensed software and destroy all copies.
      </div>
      <div className="content">
        <b>9.6</b> You must bear all compensation responsibilities if KT Lock
        and other users suffer from losses caused by your breach of terms in the
        Agreement.
      </div>
      <h2>X. Governing Laws and Severability</h2>
      <div className="content">
        <b>10.1</b> Effectiveness, explanation, change, execution and dispute
        settlement of the Agreement are subject to laws of the Socialist
        Republic of Viet Nam. If no relevant laws and regulations are available,
        reference to general international business practices and {`(`}or{`)`}
        industrial practices shall be made.
      </div>
      <div className="content">
        <b>10.2</b> Dispute arising from or in connection with the Agreement may
        be settled by you and KT Lock through friendly negotiation or submitted
        to People{`'`}s Court of Hanoi City where the Agreement is signed for
        adjudication.
      </div>
      <div className="content">
        <b>10.3</b> When any term of the Agreement is judged to be invalid by
        the People{`'`}s Court, it will not influence the effectiveness of other
        terms or any part thereof, and you and KT Lock shall perform the valid
        terms in good faith.
      </div>
      <div className="content">
        <b>10.4</b> The Agreement is signed in Hadong District, Hanoi City,
        Vietnam
      </div>
    </>
  );
};

export default UserAgreement;
