type FailableResponse<SuccessData = null, FailureCode = null, FailureData = null> =
  | SuccessResponse<SuccessData>
  | FailedResponse<FailureCode, FailureData>;

export type SuccessResponse<Data = null> = {
  success: true;
  value: Data;
};

export type FailedResponse<Code = null, Data = null> = {
  success: false;
  code: Code;
  data: Data;
};

export function createSuccessResponse(): SuccessResponse;
export function createSuccessResponse<T>(value: T): SuccessResponse<T>;
export function createSuccessResponse<T = null>(
  value?: T
): SuccessResponse<T> | SuccessResponse {
  if (value != undefined) {
    return {
      success: true,
      value: value,
    };
  }

  return {
    success: true,
    value: null,
  };
}

export function createFailedResponse(): FailedResponse;
export function createFailedResponse<C>(code: C): FailedResponse<C>;
export function createFailedResponse<C, D>(
  code: C,
  data: D
): FailedResponse<C, D>;
export function createFailedResponse<C = null, D = null>(
  code?: C,
  data?: D
): FailedResponse | FailedResponse<C> | FailedResponse<C, D> {
  if (code == undefined) {
    if (data == undefined) {
      return { success: false, code: null, data: null };
    }

    throw new Error("Un handled");
  }

  if (data == undefined) {
    return {
      success: false,
      code: code,
      data: null,
    };
  }

  return {
    success: false,
    code,
    data,
  };
}

export type FailedResponseData<T = null> = {
  code?: number;
  specific?: T;
};

export default FailableResponse;
