import {
  TextInput,
  PasswordInput,
  Paper,
  Title,
  Container,
  Button,
  PinInput,
  InputLabel,
  Text,
  InputError,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useCallback, useRef } from "react";
import React from "react";
import { useAppSelector } from "src/services/redux/ReduxStore";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { nullToastUpdateOptions, toastCommonError } from "../utils/ToastUtils";
import authService from "src/services/core/auth/Auth.service";
import { useTranslation } from "react-i18next";

export function ActivateAccount() {
  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: "auth.activateAccount",
  });
  const wrongActivationCode = useRef<string>();
  const form = useForm({
    initialValues: {
      code: "",
    },
    validate: {
      code: (value) =>
        wrongActivationCode.current != null &&
        wrongActivationCode.current == value
          ? t("error.wrongCode")
          : value.length == 8
          ? null
          : t("error.codeNotEntered"),
    },
    clearInputErrorOnChange: true,
  });

  const formSubmit = useCallback(
    async (values: typeof form.values) => {
      if (form.errors.code != null) {
        return;
      }

      const promise = authService.activateAccount(parseInt(values.code));
      const signUpId = toast.loading(t("activatingAccount"), {
        autoClose: false,
        closeButton: true,
        closeOnClick: true,
      });
      const result = await promise;
      if (result.success) {
        toast.update(signUpId, {
          ...nullToastUpdateOptions,
          render: t("accountActivationSuccess"),
          type: "success",
        });
        return;
      }

      if (result.code == "incorrect-activation-code") {
        wrongActivationCode.current = values.code;
        form.setFieldError("code", t("error.wrongCode"));
        toast.update(signUpId, {
          ...nullToastUpdateOptions,
          render: t("error.wrongCode"),
          type: "error",
        });
        return;
      }

      toastCommonError(result.code, signUpId);
    },
    [form, i18n.language]
  );

  return (
    <Container size={420} my={40}>
      <Title ta="center">{t("activationCodeLabel")}</Title>

      <Paper withBorder shadow="md" p={30} mt={60} radius="md">
        <span>{t("emailSent")}</span>
        <form onSubmit={form.onSubmit((values) => formSubmit(values))}>
          <div className="mt-3 mb-2 text-base font-semibold">
            {t("activationCodeLabel")}
          </div>
          <PinInput
            ariaLabel="Account activation code"
            length={8}
            {...form.getInputProps("code")}
            inputMode="numeric"
          />
          <div className="flex h-3">
            <p className="text-[var(--mantine-color-error)] text-xs mt-2">
              {form.errors.code}
            </p>
          </div>
          <Button fullWidth mt="xl" type="submit">
            {t("submit")}
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default ActivateAccount;
