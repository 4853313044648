import { authApi } from "src/repositories/api/auth/Auth.api";
import localStorageService from "src/repositories/local-storage/LocalStorageService";
import tokenSlice from "../../redux/slices/UserSlice";
import { reduxStore } from "../../redux/ReduxStore";
import FailableResponse, {
  createFailedResponse,
  createSuccessResponse,
  FailedResponse,
} from "src/repositories/model/FailableResponse";
import UserInfo from "src/repositories/model/UserInfo";
import deviceListSlice from "../../redux/slices/DeviceListSlice";
import {
  groupListSlice,
  selectedGroupSlice,
} from "../../redux/slices/GroupSlice";
import groupService from "../group/Group.service";
import {
  GeneralApiResponse,
  handleGeneralApiError,
} from "src/repositories/api/Utils/ResponseUtility";
import TokenInfo, {
  readTokenInfo,
} from "src/repositories/api/auth/model/TokenInfo";
import { handleGeneralErrorResponse } from "src/services/utils/Utils.service";
import tokenUnauthorizedSlice from "src/services/redux/slices/miscellaneous/TokenUnauthorizedSlice";
import { activeManagedUserSlice, inactiveManagedUserSlice } from "src/services/redux/slices/ManagedUserListSlice";

const authService = {
  login: async (
    email: string,
    password: string
  ): Promise<
    GeneralApiResponse<TokenInfo> | FailedResponse<"invalid-credentials">
  > => {
    const result = await authApi.login(email, password);
    if (result.success) {
      const tokenInfo: TokenInfo = readTokenInfo(result.value.token);
      localStorageService.storeData("tokenInfo", tokenInfo);
      reduxStore.dispatch(tokenSlice.actions.update(tokenInfo));
      // //fire and forget
      // groupService.getGroupList();
      return createSuccessResponse(tokenInfo);
    }

    return result;
  },

  logout: () => {
    logoutInternal();
  },

  async signUp(
    email: string,
    name: string,
    password: string
  ): Promise<
    | GeneralApiResponse
    | FailedResponse<"email-exists" | "invalid-email" | "invalid-password">
  > {
    const result = await authApi.signUp(email, name, password);
    if (result.success) {
      const tokenInfo: TokenInfo = readTokenInfo(result.value.token);
      localStorageService.storeData("tokenInfo", tokenInfo);
      reduxStore.dispatch(tokenSlice.actions.update(tokenInfo));
      return createSuccessResponse();
    }
    return result;
  },

  async activateAccount(
    activationCode: number
  ): Promise<GeneralApiResponse | FailedResponse<"incorrect-activation-code">> {
    const tokenInfo = reduxStore.getState().token;
    if (tokenInfo?.payload.isAccountActivated != false) {
      return createFailedResponse("error");
    }

    const result = await authApi.activateAccount(
      activationCode,
      tokenInfo.token
    );
    if (result.success) {
      const newTokenInfo = readTokenInfo(result.value.token);
      localStorageService.storeData("tokenInfo", newTokenInfo);
      reduxStore.dispatch(tokenSlice.actions.update(newTokenInfo));
      return createSuccessResponse();
    }
    if (result.code == "unauthorized") {
      if (reduxStore.getState().token != null) {
        reduxStore.dispatch(tokenSlice.actions.update(null));
        reduxStore.dispatch(tokenUnauthorizedSlice.actions.update(true));
      }
    }
    return result;
  },

  async deleteAccount(): Promise<GeneralApiResponse> {
    const result = await authApi.deleteAccount();
    if (result.success) {
      logoutInternal();
      return createSuccessResponse();
    }
    handleGeneralErrorResponse(result);
    return result;
  },
};

const logoutInternal = () => {
  localStorageService.removeData("tokenInfo");
  reduxStore.dispatch(tokenSlice.actions.update(null));
  reduxStore.dispatch(selectedGroupSlice.actions.update(null));
  reduxStore.dispatch(groupListSlice.actions.update(null));
  reduxStore.dispatch(activeManagedUserSlice.actions.update(null));
  reduxStore.dispatch(inactiveManagedUserSlice.actions.update(null));
  reduxStore.dispatch(deviceListSlice.actions.update(null));
};

export default authService;
