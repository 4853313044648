import {
  Button,
  Container,
  NumberInput,
  Paper,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  nullToastUpdateOptions,
  toastCommonError,
} from "src/pages/utils/ToastUtils";
import deviceService, {
  getActualPassword,
  getDisplayPassword,
  getDisplayPasswordNumber,
} from "src/services/core/device/Device.service";
import Device from "src/services/core/device/models/Device";

const EditDevice: (props: {
  device: Device;
  onFormSubmitSuccess?: () => void;
}) => JSX.Element = (props) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "inApp.deviceList.editDevicePage",
  });

  const form = useForm({
    initialValues: {
      serialNumber: props.device.serialNumber,
      password: getDisplayPassword(props.device.password),
      name: props.device.name,
    },
    validate: {
      serialNumber: (value) =>
        value.trim().length > 0 ? null : t("noEmptySerialNumber"),
    },
  });

  const formSubmit = useCallback(
    async (values: typeof form.values) => {
      const editId = toast.loading(t("editing"), {
        closeButton: true,
        closeOnClick: true,
      });

      const editPromise = deviceService.editDevice({
        ...props.device,
        password: getActualPassword(values.password),
        name: values.name ?? "",
      });

      const result = await editPromise;
      if (result.success) {
        toast.update(editId, {
          ...nullToastUpdateOptions,
          render: t("editSuccess"),
          type: "success",
        });

        props.onFormSubmitSuccess?.();
        return;
      }

      toastCommonError(result.code, editId);
    },
    [props.onFormSubmitSuccess]
  );

  return (
    <Container size={420} my={"3vh"}>
      <Title ta="center">{t("title")}</Title>

      <Paper mt={"5vh"} radius="md">
        <form onSubmit={form.onSubmit((values) => formSubmit(values))}>
          <TextInput
            disabled={true}
            label={t("serialNumberlabel")}
            placeholder="VK-XXXXXXXXXXXXX"
            mt="md"
            spellCheck={false}
            {...form.getInputProps("serialNumber")}
          />
          <NumberInput
            allowLeadingZeros
            isAllowed={(values) => {
              return values.value.length <= 8;
            }}
            label={
              <span>
                {t("passwordLablel")}
                <span className="text-[#999999]">{t("blankIfNoPassword")}</span>
              </span>
            }
            placeholder="XXXXXXXX"
            mt="md"
            allowDecimal={false}
            {...form.getInputProps("password")}
            onChange={undefined}
            onValueChange={(values) => {
              form.values.password = values.value;
            }}
          />
          <TextInput
            label={t("nameLabel")}
            placeholder={t("namePlaceholder")}
            mt="md"
            spellCheck={false}
            {...form.getInputProps("name")}
          />
          <Button fullWidth mt="xl" type="submit">
            {t("edit")}
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default EditDevice;
