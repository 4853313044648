import { createSlice } from "@reduxjs/toolkit";
import ManagedUser from "src/services/core/managed-user/models/ManagedUser";

// add and remove doesn't work if the list is null
const createManagedUserSlice = (name: string) =>
  createSlice({
    name,
    initialState: null as ManagedUser[] | null,
    reducers: {
      update: (_list, action: { payload: ManagedUser[] | null }) =>
        action.payload?.sort((a, b) => a.deviceId - b.deviceId),
      add: (list, action: { payload: ManagedUser[] }) => {
        if (list == null) {
          return;
        }
        list.push(...action.payload);
        list.sort((a, b) => a.deviceId - b.deviceId);
      },
      remove: (list, action: { payload: number[] }) => {
        if (list == null) {
          return;
        }
        return list.filter(
          (managedUser) =>
            action.payload.find((id) => managedUser.id == id) == null
        );
      },
      updateItem: (list, action: { payload: ManagedUser }) => {
        if (list == null) {
          return;
        }
        const newManagedUser = action.payload;
        const index = list.findIndex((g) => g.id == newManagedUser.id);
        if (index == null) {
          return;
        }

        list[index] = newManagedUser;
        list.sort((a, b) => a.deviceId - b.deviceId);
      },
    },
  });

export const inactiveManagedUserSlice = createManagedUserSlice(
  "inactive-managed-user"
);
export const activeManagedUserSlice = createManagedUserSlice(
  "active-managed-user"
);
