import {
  Log,
  LogType,
  SensorLog,
  SystemLog,
} from "src/services/core/log/models/Log";
import { ActivityLogDto, SensorLogDto, SystemLogDto } from "../model/LogDto";
import { globalAxiosAction } from "./Init.api";
import { createDefaultAxios } from "./Utils/AxiosUtility";
import {
  GeneralApiResponse,
  handleGeneralApiError,
} from "./Utils/ResponseUtility";
import { getBrearerTokenHeader } from "./Utils/Utils.api";
import { createSuccessResponse } from "../model/FailableResponse";

const axiosInstance = createDefaultAxios("/api/log/");
globalAxiosAction.subscribe(axiosInstance);

const getDateString = (date: Date) => "1-1-2001";
// `${date.getDay()}-${date.getMonth()}-${date.getFullYear()}`;

async function getLogs(
  type: LogType,
  groupId: number,
  from: Date | null,
  to: Date | null
): Promise<GeneralApiResponse<any[]>> {
  try {
    const response = await axiosInstance.get(`getLogs/${groupId}/${type}`, {
      params: {
        from,
        to,
      },
      ...getBrearerTokenHeader(),
    });
    return createSuccessResponse(response.data);
  } catch (e) {
    return handleGeneralApiError(e);
  }
}

const logApi = {
  getActivityLogs: (
    groupId: number,
    from: Date | null,
    to: Date | null
  ): Promise<GeneralApiResponse<ActivityLogDto[]>> =>
    getLogs("activity", groupId, from, to),
  getSensorLogs: (
    groupId: number,
    from: Date | null,
    to: Date | null
  ): Promise<GeneralApiResponse<SensorLogDto[]>> =>
    getLogs("sensor", groupId, from, to),
  getSystemLogs: (
    groupId: number,
    from: Date | null,
    to: Date | null
  ): Promise<GeneralApiResponse<SystemLogDto[]>> =>
    getLogs("system", groupId, from, to),
};

export default logApi;
