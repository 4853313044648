import { RingProgress, UnstyledButton } from "@mantine/core";
import { useDisclosure, useInterval } from "@mantine/hooks";
import {
  IconLock,
  IconLockOpen,
  IconLockOpenOff,
  IconWifiOff,
} from "@tabler/icons-react";
import classNames from "classnames";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  toastCommonError,
  toastDeviceResponseReport,
} from "src/pages/utils/ToastUtils";
import useAnimationFrame from "src/pages/utils/useAnimationFrame";
import Device from "src/services/core/device/models/Device";
import physicalDeviceService from "src/services/core/device/PhysicalDevice.service";

const OpenDoorButton = ({
  device,
  deviceConnected,
}: {
  deviceConnected: boolean;
  device: Device;
}): JSX.Element => {
  const [msLeft, setMsLeft] = useState(0);
  const canOpen = !deviceConnected ? null : msLeft <= 0;
  const [openDurationMs, setOpenDurationMs] = useState(3500);
  const doorOpened = useRef(false);
  useAnimationFrame((delta) => {
    if (!doorOpened.current) {
      return;
    }
    setMsLeft((current) => {
      const nextMsLeft = current - delta;
      if (nextMsLeft > 0) {
        return nextMsLeft;
      }
      doorOpened.current = false;
      return 0;
    });
  });

  const { t } = useTranslation(undefined, {
    keyPrefix: "inApp.deviceDetail",
  });

  const openDoor = useCallback(async () => {
    const toastId = toast.loading(t("toast.sendingCommand"), {
      closeButton: true,
      closeOnClick: true,
    });

    const result = await physicalDeviceService.openDoors([device.serialNumber]);
    if (result.success) {
      if (result.value?.okSnList?.length == 1) {
        setMsLeft(openDurationMs);
        doorOpened.current = true;
      }
      toastDeviceResponseReport(
        device == null ? [] : [device],
        result.value,
        toastId,
        {
          okSnList: (_) => t("toast.doorOpened"),
          wrongPasswordSnList: (_) => t("toast.wrongPassword"),
          deviceNotConnectedSnList: (_) => t("toast.notConnected"),
        },
        {
          okSnList: (_) => 2000,
        }
      );
      return;
    }

    toastCommonError(result.code, toastId);
  }, [physicalDeviceService, device, t]);

  const lockDoor = useCallback(async () => {
    const toastId = toast.loading(t("toast.sendingCommand"), {
      closeButton: true,
      closeOnClick: true,
    });

    const result = await physicalDeviceService.lockDoors([device.serialNumber]);
    if (!result.success) {
      toastCommonError(result.code, toastId);
      return;
    }
    if (result.value?.okSnList?.length == 1) {
      setMsLeft(0);
      doorOpened.current = false;
    }
    toastDeviceResponseReport(
      device == null ? [] : [device],
      result.value,
      toastId,
      {
        okSnList: (_) => t("toast.doorLocked"),
        wrongPasswordSnList: (_) => t("toast.wrongPassword"),
        deviceNotConnectedSnList: (_) => t("toast.notConnected"),
      },
      {
        okSnList: (_) => 2000,
      }
    );
  }, [physicalDeviceService, device, t]);

  return (
    <RingProgress
      size={300}
      thickness={30}
      rootColor={
        canOpen == null
          ? "var(--mantine-color-gray-2)"
          : canOpen
          ? "var(--mantine-color-green-5)"
          : "var(--mantine-color-gray-2)"
      }
      classNames={{
        root: "relative",
        label:
          "w-full h-full absolute !left-1/2 !-translate-x-1/2 !top-1/2 !-translate-y-1/2",
      }}
      sections={[
        {
          value: (msLeft / openDurationMs) * 100,
          color: "var(--mantine-color-red-5)",
        },
      ]}
      label={
        <>
          <div
            className="w-full h-full flex justify-center items-center p-4
            absolute !left-1/2 !-translate-x-1/2 !top-1/2 !-translate-y-1/2"
          >
            <div
              className={classNames(
                "h-full w-full rounded-full !border-4 !border-solid",
                {
                  // "border-[var(--mantine-color-green-5)]": canOpen,
                  // "border-[var(--mantine-color-red-5)]": !canOpen,
                }
              )}
            />
          </div>
          <div className="w-full h-full flex items-center justify-center p-[80px] relative">
            <UnstyledButton
              disabled={canOpen == null}
              onClick={
                canOpen == null ? undefined : canOpen ? openDoor : lockDoor
              }
              className={classNames(
                "w-full h-full rounded-full !border-2 flex justify-center items-center flex-col !border-solid",
                {
                  "!border-[var(--mantine-color-green-outline)] !bg-[var(--mantine-color-green-outline-hover)]":
                    canOpen,
                  "!border-[var(--mantine-color-red-outline)] !bg-[var(--mantine-color-red-outline-hover)]":
                    canOpen == false,
                  "!border-[var(--mantine-color-gray-outline)] !bg-[var(--mantine-color-gray-outline-hover)]":
                    canOpen == null,
                }
              )}
            >
              {canOpen == null ? (
                <IconWifiOff
                  size={27}
                  color="var(--mantine-color-gray-light-color)"
                />
              ) : canOpen ? (
                <IconLockOpen
                  size={23}
                  color="var(--mantine-color-green-light-color)"
                />
              ) : (
                <IconLock
                  size={23}
                  color="var(--mantine-color-red-light-color)"
                />
              )}
              <div
                className={classNames(" text-lg font-medium text-center", {
                  "text-[var(--mantine-color-green-light-color)]": canOpen,
                  "text-[var(--mantine-color-red-light-color)]":
                    canOpen == false,
                  "text-[var(--mantine-color-gray-light-color)]":
                    canOpen == null,
                })}
              >
                {canOpen == null
                  ? t("deviceOffline")
                  : canOpen
                  ? t("openDoor")
                  : t("lockDoor")}
              </div>
            </UnstyledButton>
          </div>
        </>
      }
    />
  );
};

export default OpenDoorButton;
