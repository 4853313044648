import FailableResponse, {
  FailedResponse,
  createFailedResponse,
  createSuccessResponse,
} from "src/repositories/model/FailableResponse";
import { groupApi } from "src/repositories/api/Group.api";
import { GeneralApiResponse } from "src/repositories/api/Utils/ResponseUtility";
import { reduxStore } from "src/services/redux/ReduxStore";
import {
  groupListSlice,
  selectedGroupSlice,
} from "src/services/redux/slices/GroupSlice";
import deviceListSlice from "src/services/redux/slices/DeviceListSlice";
import { handleGeneralErrorResponse } from "src/services/utils/Utils.service";
import localStorageService from "src/repositories/local-storage/LocalStorageService";
import { activeManagedUserSlice, inactiveManagedUserSlice } from "src/services/redux/slices/ManagedUserListSlice";

const groupService = {
  /**
   *
   * @param checkCache whether to check the groupList cache in redux before retrieve from server
   * @param updateCache whether to update the groupList cache in redux with the value from the server
   * @param updateSelectedGroupIfSingleGroup whether to update selected group if the groupList has only one group.
   * This option is not affected by the updateCache option.
   * @returns
   */
  getGroupList: async (
    checkCache: boolean = true,
    updateCache: boolean = true,
    updateSelectedGroupIfSingleGroup: boolean = true
  ): Promise<GeneralApiResponse<Group[]>> => {
    let groupList: Group[] | null = null;

    if (checkCache) {
      groupList = reduxStore.getState().groupList;
    }

    if (groupList == null) {
      const result = await groupApi.getGroups();

      if (!result.success) {
        handleGeneralErrorResponse(result);
        return result;
      }

      groupList = result.value;
      if (updateCache) {
        reduxStore.dispatch(groupListSlice.actions.update(groupList));
      }
    }

    if (updateSelectedGroupIfSingleGroup && groupList.length == 1) {
      reduxStore.dispatch(selectedGroupSlice.actions.update(groupList[0]));
    }

    return createSuccessResponse(groupList);
  },

  /**
   *
   * @param checkCache whether to check the groupList cache in redux before retrieve from server
   * @param updateCache whether to update the groupList cache in redux with the value from the server
   * @returns
   */
  getSelectedGroup: async (
    checkCache: boolean = true,
    updateCache: boolean = true
  ): Promise<GeneralApiResponse<Group | null>> => {
    // check redux store
    const group = checkCache ? reduxStore.getState().group : null;
    if (group != null) {
      return createSuccessResponse(group);
    }

    const groupsResult = await groupService.getGroupList(true, updateCache);
    if (!groupsResult.success) {
      handleGeneralErrorResponse(groupsResult);
      return groupsResult;
    }

    // check if there's only one group
    if (groupsResult.value.length == 1) {
      const group = groupsResult.value[0];
      localStorageService.storeData("defaultGroup", group);
      reduxStore.dispatch(selectedGroupSlice.actions.update(group));
      return createSuccessResponse(group);
    }

    // check local storage
    const localSelectedGroup = localStorageService.getData("defaultGroup");
    if (localSelectedGroup == null) {
      return createSuccessResponse(null);
    }
    const selectedGroup = groupsResult.value.find(
      (g) => g.id == localSelectedGroup.id
    );
    if (selectedGroup != null) {
      reduxStore.dispatch(selectedGroupSlice.actions.update(selectedGroup));
    }

    return createSuccessResponse(selectedGroup ?? null);
  },

  selectGroup: (group: Group) => {
    if (reduxStore.getState().group == group) {
      return;
    }

    localStorageService.storeData("defaultGroup", group);
    reduxStore.dispatch(selectedGroupSlice.actions.update(group));
    // wipe device and employee list of the old group
    reduxStore.dispatch(deviceListSlice.actions.update(null));
    reduxStore.dispatch(activeManagedUserSlice.actions.update(null));
    reduxStore.dispatch(inactiveManagedUserSlice.actions.update(null));
  },

  deleteGroup: async (
    groupIds: number[]
  ): Promise<
    GeneralApiResponse | FailedResponse<"group-not-found", number[]>
  > => {
    const result = await groupApi.deleteGroups(groupIds);

    if (result.success) {
      reduxStore.dispatch(groupListSlice.actions.remove(groupIds));

      const currentGroup = reduxStore.getState().group;
      if (currentGroup != null) {
        if (groupIds.find((id) => currentGroup?.id == id) != null) {
          reduxStore.dispatch(selectedGroupSlice.actions.update(null));
        }
      }
    } else {
      handleGeneralErrorResponse(result);
    }
    return result;
  },

  addGroup: async (name: string): Promise<GeneralApiResponse<Group>> => {
    const result = await groupApi.addGroup(name);
    if (result.success) {
      reduxStore.dispatch(groupListSlice.actions.add([result.value]));
    } else {
      handleGeneralErrorResponse(result);
    }
    return result;
  },

  editGroup: async (
    oldGroup: Group,
    newGroup: Omit<Group, "id">
  ): Promise<
    GeneralApiResponse | FailedResponse<"group-not-found" | "no-change">
  > => {
    if (oldGroup.name.trim() == newGroup.name.trim()) {
      return createFailedResponse("no-change");
    }

    const finalGroup = { id: oldGroup.id, ...newGroup };

    const result = await groupApi.patchGroup(finalGroup);
    if (result.success) {
      reduxStore.dispatch(groupListSlice.actions.updateItem(finalGroup));
      if (finalGroup.id == reduxStore.getState().group?.id) {
        reduxStore.dispatch(selectedGroupSlice.actions.update(finalGroup));
      }
    } else {
      handleGeneralErrorResponse(result);
    }
    return result;
  },
};

export default groupService;
