import { AxiosError } from "axios";
import FailableResponse, {
  FailedResponse,
  createFailedResponse,
} from "src/repositories/model/FailableResponse";

export type BaseFailureCodes =
  | "timeout"
  | "server-connection-error"
  | "unauthorized"
  | "server-error"
  | "error";

export type GeneralApiResponse<ResponseType = null> = FailableResponse<
  ResponseType,
  BaseFailureCodes
>;

export function handleGeneralApiError(
  e: unknown
): FailedResponse<BaseFailureCodes>;
/**
 *
 * @param e the error received from the Axios call
 * @param onError the callback that handle the case where e is an AxiosError and status is a number
 */
export function handleGeneralApiError<Code, Data>(
  e: unknown,
  onError: (
    error: AxiosError,
    status: number,
    data: any
  ) => FailedResponse<Code, Data> | null
): FailedResponse<BaseFailureCodes> | FailedResponse<Code, Data>;
export function handleGeneralApiError<Code, Data>(
  e: unknown,
  onError:
    | ((
        error: AxiosError,
        status: number,
        data: any
      ) => FailedResponse<Code, Data> | null)
    | undefined = undefined
): FailedResponse<BaseFailureCodes> | FailedResponse<Code, Data> {
  if (e instanceof AxiosError) {
    if (e.response == null) {
      return createFailedResponse("server-connection-error" as const);
    }

    const onErrorResult = onError?.(e, e.response.status, e.response.data);
    if (onErrorResult != null) {
      return onErrorResult;
    }

    if (e.response.status == 500) {
      return createFailedResponse("server-error" as const);
    }

    if (e.response.status == 401) {
      return createFailedResponse("unauthorized" as const);
    }

    if (e.code == "ECONNABORTED") {
      return createFailedResponse("timeout" as const);
    }

    return createFailedResponse("server-connection-error" as const);
  }

  return createFailedResponse("error" as const);
}
