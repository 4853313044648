type Translation = {
  vi: string;
  en: string;
};

const activityStatusRecords: Record<number, Translation> = {
  1: { en: "Swiping card verification", vi: "Thẻ từ" },
  2: { en: "Fingerprint verification", vi: "Vân tay" },
  3: { en: "Face verification", vi: "Khuôn mặt" },
  4: {
    en: "Fingerprint + Swiping card",
    vi: "Vân tay + Thẻ từ",
  },
  5: { en: "Face + Fingerprint", vi: "Khuôn mặt + Vân tay" },
  6: { en: "Face + Swiping card ", vi: "Khuôn mật + Thẻ từ" },
  7: { en: "Swiping card + Password", vi: "Thẻ từ + Mật khẩu" },
  8: { en: "Face + Password", vi: "Khuôn mặt + Mật khẩu" },
  9: { en: "Fingerprint + Password", vi: "Vân tay + Mật khẩu" },
  10: {
    en: "Id and password",
    vi: "Id và mật khẩu",
  },
  11: { en: "Fingerprint + Swiping card + Password", vi: "" },
  12: { en: "Face + Swiping card + Password", vi: "" },
  13: { en: "Face + Fingerprint + Password", vi: "" },
  14: { en: "Face + Fingerprint + Swiping card", vi: "" },
  15: { en: "Repeated verification", vi: "" },
  16: { en: "Expiration date", vi: "" },
  17: { en: "Expiration of opening time zone", vi: "" },
  18: { en: "Can't open the door during holidays", vi: "" },
  19: { en: "Unregistered user", vi: "" },
  20: { en: "Detection lock", vi: "" },
  21: { en: "The effective number has been used up", vi: "" },
  22: { en: "Verify when locked. Do not open the door ", vi: "" },
  23: { en: "Reported loss card ", vi: "" },
  24: { en: "Blacklist card", vi: "" },
  25: {
    en: "Open the door without verification -- the user number is 0 when pressing the fingerprint, and the user number is the card number when swiping the card",
    vi: "",
  },
  26: {
    en: "No card swiping verification -- when card swiping is disabled in 【access authentication mode】",
    vi: "",
  },
  27: {
    en: "No fingerprint verification when fingerprint is disabled in [access authentication mode]",
    vi: "",
  },
  28: { en: "Controller expired", vi: "" },
  29: { en: "Validation passed - expiration date is coming", vi: "" },
  30: { en: "Body temperature abnormal, refused to enter", vi: "" },
};

export default activityStatusRecords;
