import { StorageKey } from "./LocalStorageKey";

const localStorageService = {
  storeData:  <T extends keyof StorageKey>(
    key: T,
    value: StorageKey[T]
  ) => {
    try {
      const jsonValue = JSON.stringify(value);
      // await AsyncStorage.setItem(key, jsonValue);
      localStorage.setItem(key, jsonValue);
    } catch (e) {
      console.error(e);
    }
  },

  getData: <T extends keyof StorageKey>(key: T): StorageKey[T] | null => {
    try {
      const jsonValue = localStorage.getItem(key);
      return jsonValue != null && jsonValue !== ""
        ? JSON.parse(jsonValue)
        : null;
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  removeData: <T extends keyof StorageKey>(key: T): void => {
    try {
      localStorage.removeItem(key);
    } catch {
      // Ignore error
    }
  },
};

export default localStorageService;
