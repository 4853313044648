import { AxiosError } from "axios";
import FailableResponse, {
  createSuccessResponse,
  createFailedResponse,
  FailedResponse,
} from "../../model/FailableResponse";
import UserInfo from "src/repositories/model/UserInfo";
import { globalAxiosAction } from "../Init.api";
import { createDefaultAxios } from "../Utils/AxiosUtility";
import {
  GeneralApiResponse,
  handleGeneralApiError,
} from "../Utils/ResponseUtility";
import TokenHolder from "./model/Token";
import { getBrearerTokenHeader } from "../Utils/Utils.api";

const axiosInstance = createDefaultAxios("/api/auth/", 8000);
globalAxiosAction.subscribe(axiosInstance);

export const authApi = {
  async login(
    email: string,
    password: string
  ): Promise<
    GeneralApiResponse<TokenHolder> | FailedResponse<"invalid-credentials">
  > {
    try {
      const response = await axiosInstance.post<TokenHolder>("login", {
        email,
        password,
      });
      return createSuccessResponse(response.data);
    } catch (e) {
      return handleGeneralApiError(e, (e, status, data) => {
        try {
          if (status == 401 && data.toLowerCase() == "invalid credentials") {
            return createFailedResponse("invalid-credentials" as const);
          }
        } catch (e) {
          undefined;
        }
        return null;
      });
    }
  },
  async signUp(
    email: string,
    name: string,
    password: string
  ): Promise<
    | GeneralApiResponse<TokenHolder>
    | FailedResponse<"email-exists" | "invalid-email" | "invalid-password">
  > {
    try {
      const response = await axiosInstance.post<TokenHolder>("signUp", {
        email,
        name,
        password,
      });
      return createSuccessResponse(response.data);
    } catch (e) {
      return handleGeneralApiError(e, (e, status, data) => {
        if (status == 409 && data.code == "email-exists") {
          return createFailedResponse("email-exists");
        }

        if (status == 400) {
          switch (data.code) {
            case "invalid-password": {
              return createFailedResponse("invalid-password");
            }
            case "invalid-email": {
              return createFailedResponse("invalid-email");
            }
          }
        }
        return null;
      });
    }
  },
  async activateAccount(
    activationCode: number,
    unactivatedToken: string
  ): Promise<
    | GeneralApiResponse<TokenHolder>
    | FailedResponse<"incorrect-activation-code">
  > {
    try {
      const response = await axiosInstance.post<TokenHolder>(
        "activateAccount",
        {
          activationCode,
        },
        { headers: { Authorization: `Bearer ${unactivatedToken}` } }
      );
      return createSuccessResponse(response.data);
    } catch (e) {
      return handleGeneralApiError(e, (e, status, data) => {
        if (status == 400 && data.code == "incorrect-activation-code") {
          return createFailedResponse("incorrect-activation-code");
        }
        return null;
      });
    }
  },
  async deleteAccount(): Promise<GeneralApiResponse> {
    try {
      await axiosInstance.delete("deleteAccount", getBrearerTokenHeader());
      return createSuccessResponse();
    } catch (e) {
      return handleGeneralApiError(e);
    }
  },
};
