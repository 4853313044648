import { BaseFailureCodes } from "src/repositories/api/Utils/ResponseUtility";
import { FailedResponse } from "src/repositories/model/FailableResponse";
import authService from "../core/auth/Auth.service";
import { reduxStore } from "../redux/ReduxStore";
import tokenUnauthorizedSlice from "../redux/slices/miscellaneous/TokenUnauthorizedSlice";

export function handleGeneralErrorResponse(
  failedResponse: FailedResponse<BaseFailureCodes | any, any>
): void {
  if (failedResponse.code == "unauthorized") {
    if (reduxStore.getState().token != null) {
      authService.logout();
      reduxStore.dispatch(tokenUnauthorizedSlice.actions.update(true));
    }
  }
}
